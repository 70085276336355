import styled from 'styled-components'

const AdvantageDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 100px 0;
    justify-content: center;

    max-width: 2010px;

    margin: 0 auto;

    h2 {
        margin: 0;
        padding: 0;
    }

    .advantage-container {
        width: 100%;
        margin-top: 100px;
    }

    h2 {
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 44px;
        line-height: 54px;

        text-align: center;

        color: #404040;

        margin: 30px 0 56px 0;
    }

    .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        margin: 0 auto;
    }

    .content-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 190px;
        min-height: 196px;

        img {
            width: 82px;
        }
    }

    .content-item-title {
        font-family: Fira Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
        line-height: 35px;

        text-align: center;

        color: #5c5c5c;

        padding-top: 20px;
    }

    @media screen and (max-width: 640px) {
        /* width: calc(100vw - 32px); */

        padding-top: 70px;

        .content {
            justify-content: center;
        }

        .advantage-container {
            width: 100%;
        }
        
        h2{
            margin: 0;
            margin-bottom: 16px;

            font-size: 36px;
            line-height: 120%;
        }

        .content-item {
            min-height: 98px;
            width: 140px;

            padding: 16px 0;

            margin: 0 15px;

            img {
                width: 40px;
            }
        }

        .content-item-title {
            font-size: 16px;
            line-height: 130%;

            padding-top: 16px;
        }
    }

    @media screen and (min-width: 640px) and (max-width: 1280px) {
        h3 {
            margin: 0;
            margin-bottom: 40px;

            font-size: 40px;
            line-height: 120%;
        }
        .content {
            justify-content: center;
        }
        .content-item {
            min-height: 130px;
            width: 118px;

            padding: 16px;

            img {
                width: 60px;
            }
        }

        .content-item-title {
            font-size: 16px;
            line-height: 130%;

            padding-top: 16px;
        }
    }

    @media screen and (min-width: 1280px) and (max-width: 1920px) {
        max-width: 1266px;
        margin: 0 auto;
    }
`

export default AdvantageDiv
