import React from 'react'
import { getPaywayName } from 'trustee-swap-assets'

import { strings } from '../../../../i18n'
import store from '../../../../redux'
import Icon from '../../../../components/Icon/Icon'
import Selector from '../Selector/Selector'
import PaywaySelectorDiv from './style.css'

const PaywaySelectorComponent = () => {
    const state = store.getState().exchangeStore

    return (
        <PaywaySelectorDiv>
            <div className='payway-container'>
                <div className='payway-label'>
                    <Icon
                        icon='PAYWAY'
                        color={state.widgetMode ? '#808080' : '#404040'}
                        size={20}
                        style={{ paddingRight: 5 }}
                    />
                    {strings('exchangeForm.payway')}
                </div>
                <div
                    className={`payway-selector-wrap ${
                        state.paywayError && 'payway-selector-error'
                    } ${state.widgetMode ? 'widget' : ''}`}
                >
                    <div className='payway-selectro-wrap-text'>
                        {getPaywayName(state.payway)}
                    </div>
                    <Selector currencyCode={state.payway} side='PAYWAY' />
                </div>
            </div>
            {state.paywayError ? (
                <div className='payway-error'>
                    <div className='payway-error-text'>
                        {strings('exchangeForm.noPayway')}
                    </div>
                </div>
            ) : null}
        </PaywaySelectorDiv>
    )
}

export default PaywaySelectorComponent
