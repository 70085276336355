import React from 'react'
import ModalDiv from './style.css'

const ModalComponent = (props) => {
    const { show } = props

    if (show) {
        document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    } else {
        document.getElementsByTagName('body')[0].style.overflow = 'scroll'
    }

    return (
        <ModalDiv>
            {show ? (
                <div className='widget-modal-container'>
                    <div className='widget-modal-content'>{props.children}</div>
                </div>
            ) : null}
        </ModalDiv>
    )
}

export default ModalComponent
