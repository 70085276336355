import styled from 'styled-components'

const FooterDiv = styled.div `
    z-index: 2;
    .footer {
        width: 100%;
        min-height: 16.6em;
        display: flex;
        background: ${(p) => p.footerBg};
    }

    .links {
        width: 100%;
        max-width: 118.23em;
        margin: 0 auto;
    }

    .links-monitoring {
        padding-bottom: 0.5em 0;
    }
    .other-links-wrapper {
        display: flex;
        margin-top: 50px;
        margin-bottom: 15px;
        justify-content: space-around;
    }
    .other-links-title {
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 1em;
        line-height: 130%;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        text-decoration: none;
        color: ${(p) => p.itemColors};
    }
    .other-links-wrapper-item {
        font-family: Fira Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1em;
        line-height: 150%;
        letter-spacing: 0;
        text-transform: none;

        color: ${(p) => p.reservedColor};

        opacity: 0.65;
    }
    .other-links {
        text-decoration: none;
    }
    .other-wrapper {
        margin: 5px 0;
    }
    .links-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        align-items: center;
        margin-top: 20px;

        &.desktop {
            display: flex;
        }

        &.mobile {
            display: none;
        }
    }

    .links-wrapper-item {
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 1em;
        line-height: 130%;

        letter-spacing: 0.12em;
        text-transform: uppercase;
        padding: 0 32px;

        &.last {
            padding-right: 0;
        }
    }

    .links-wrapper-item a {
        cursor: pointer;
        text-decoration: none;
        color: ${(p) => p.itemColors};
    }

    .links-wrapper-item a:hover {
        text-decoration: underline;
    }

    .links-wrapper-item-blocksoft {
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-weight: bold;
        letter-spacing: 4px;
        margin: 10px 0;
        text-align: left;
    }
    .links-content {
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 1em;
        line-height: 130%;

        letter-spacing: 0.12em;
        text-transform: uppercase;
        text-decoration: none;
        color: ${(p) => p.itemColors};
    }
    .links-wrapper-item-right-reserved {
        font-family: Fira Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1em;
        line-height: 150%;
        letter-spacing: 0;
        text-transform: none;

        color: ${(p) => p.reservedColor};

        opacity: 0.65;
    }
    .monitoring-title {
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 1em;
        line-height: 130%;
        text-align: center;
        margin-bottom: 5px;

        letter-spacing: 0.12em;
        text-transform: uppercase;
        text-decoration: none;
        color: ${(p) => p.itemColors};
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
        .links-wrapper {
            flex-wrap: wrap-reverse;
            justify-content: center;
        }

        .links-wrapper-item {
            padding: 16px;
        }

        .links-wrapper-item-blocksoft {
            justify-content: left;
            text-align: left;
        }
    }

    @media screen and (max-width: 768px) {
        .footer {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding-bottom: 16px;
        }
        .other-links-wrapper {
            flex-wrap: wrap;
        }

        .links-wrapper-item {
            padding: 2em;
        }

        .links-monitoring {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        .links-wrapper {
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
        }

        .links-wrapper-item {
            padding: 16px;
        }

        .links-wrapper-item-blocksoft {
            justify-content: left;
            text-align: left;
        }
    }

    @media screen and (max-width: 640px) {
        .other-links-wrapper {
            flex-wrap: wrap;
            text-align: center;
            flex-direction: column;
        }
        .links-wrapper-item {
            padding: 2, 5em;
        }
        .other-wrapper {
            margin: 7px;
        }
        .links {
            width: calc(100% - 32px);
            margin-left: 16px;
        }
        .links-wrapper {
            text-align: center;
            padding-top: 30px;

            &.desktop {
                display: none;
            }

            &.mobile {
                display: flex;
            }
        }
        .aboutUs {
            margin-bottom: 50px;
        }
        .information {
            margin-bottom: 50px;
        }
        .cryptocurrency-wallet {
            margin-bottom: 50px;
        }
        .links-wrapper-item-blocksoft {
            text-align: center;
        }
    }

    @media screen and (min-width: 640px) and (max-width: 1280px) {
        .links {
            width: calc(100% - 64px);
            margin-left: 32px;
        }
        .links-wrapper-item-blocksoft {
            justify-content: left;
            text-align: left;
        }
    }

    @media screen and (min-width: 1280px) and (max-width: 1920px) {
        .links {
            max-width: 1266px;
            margin: 0 auto;
        }
    }
`

export default FooterDiv