export const darkTheme = {
    header: {
        bg: '#f7f7f7',
        selectorBorder: '#DADADA',
        selectorColor: '#404040'
    },
    footer: {
        bg: '#404040',
        itemColor: '#f7f7f7',
        reservedColor: '#EEEEEE'
    },
    violet: '#864DD9',
    violet2: '#370E74',
    infoMessageBg: '#EFEBF5'
}
