import React from 'react'
import Icon from '../../../../components/Icon/Icon'
import InputDiv from './style.css'

import Loader from '../../../../images/loader.svg'
import statusCard from '../../services/statusCard'
import PhotoUpload from '../PhotoUpload/PhotoUpload'

const cardFormat = (value) => {
    let v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    let matches = v.match(/\d{4,20}/g)
    let match = (matches && matches[0]) || ''
    let parts = []
    for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4))
    }
    if (parts.length) {
        return parts.join(' ')
    } else {
        return value
    }
}

const getRightComponent = (rightType) => {
    if (!rightType) return

    switch (rightType.toLowerCase()) {
        case 'search':
            return (
                <div className='input-container-icon'>
                    <Icon icon='SEARCH' size={20} color='#999' />
                </div>
            )
        case 'success':
            return (
                <div className='input-container-icon success'>
                    <Icon icon='CHECK' size={28} color='#F7F7F7' />
                </div>
            )
        case 'progress':
            return (
                <div className='input-container-icon progress'>
                    <img src={Loader} alt='loader' width={40} />
                </div>
            )
        case 'canceled':
            return (
                <div className='input-container-icon canceled'>
                    <Icon icon='CLOSE' size={16} color='#F7F7F7' />
                </div>
            )
        default:
            return null
    }
}

const Input = (props) => {
    const {
        value,
        onChange,
        placeholder,
        error,
        label,
        typePayment,
        readOnly,
        textError,
        rightType,
        verification,
        reCaptchaRef,
        state,
        onFocus,
        onBlur,
        id,
        inputMode
    } = props

    return (
        <InputDiv>
            {label && (
                <div
                    className={`input-label ${
                        error && !verification && 'error-container'
                    }`}
                >
                    {label}
                </div>
            )}
            {verification ? (
                <div className='verification-container'>
                    <div
                        className={`input-container ${
                            verification && 'verification-block'
                        }`}
                    >
                        <input
                            id={id || 'input-container-'}
                            className='input-container-input'
                            placeholder={placeholder}
                            value={
                                typePayment === 'CARD'
                                    ? cardFormat(value)
                                    : value
                            }
                            onChange={onChange}
                            readOnly={readOnly || false}
                            autoComplete='off'
                            inputMode={inputMode || 'text'}
                        />
                        {getRightComponent(rightType)}
                    </div>
                    <div className='card-verif-container'>
                        <div className='card-status'>
                            {statusCard(state.paymentData.status)}
                        </div>
                        <PhotoUpload
                            state={state}
                            reCaptchaRef={reCaptchaRef}
                        />
                    </div>
                </div>
            ) : (
                <div className='wrapper-container'>
                    <div
                        className={`input-container ${error && 'error-block'}`}
                    >
                        <input
                            id={id || 'input-container-'}
                            className='input-container-input'
                            placeholder={placeholder}
                            value={
                                typePayment === 'CARD'
                                    ? cardFormat(value)
                                    : value
                            }
                            onChange={onChange}
                            readOnly={readOnly || false}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            autoComplete='off'
                            inputMode={inputMode || 'text'}
                        />
                        {getRightComponent(rightType)}
                    </div>
                    {error && !verification ? (
                        <div className='input-error'>
                            <div className='input-error-text'>{textError}</div>
                        </div>
                    ) : null}
                </div>
            )}
        </InputDiv>
    )
}

export default Input
