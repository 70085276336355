import styled from 'styled-components'

const FastExchangeDiv = styled.div`
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-between;

    width: 100%;

    margin: 0 auto;

    position: relative;

    .fast-exchange {
        width: 50%;
        max-width: 45vw;
        position: relative;
        align-self: center;
    }

    .fast-exchange-title {
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 60px;
        line-height: 70px;

        display: flex;
        align-items: center;

        color: #404040;

        margin: 16px 0;
    }

    .fast-exchange-description {
        font-family: Fira Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 25px;

        display: flex;
        align-items: center;

        color: #5c5c5c;

        margin-bottom: 30px;
    }

    @media screen and (max-width: 768px) {
        justify-content: center;

        /* .fast-exchange {
            width: 100%;
            max-width: 100%;
        } */
    }

    @media screen and (max-width: 640px) {
        flex-wrap: wrap;

        .fast-exchange {
            margin: 0 16px;
            width: 100%;
            max-width: 100%;
        }

        .fast-exchange-title {
            font-family: 'Montserrat Medium';
            font-weight: 500;
            font-size: 46px;
            line-height: 120%;

            margin-bottom: 8px;
            margin-top: 0;
        }

        .fast-exchange-description {
            font-size: 16px;
            line-height: 150%;

            margin-bottom: 32px;
        }
    }

    @media screen and (min-width: 640px) and (max-width: 1280px) {
        .fast-exchange-title {
            font-family: 'Montserrat Medium';
            font-size: 50px;
            line-height: 120%;
            font-weight: 500;

            margin: 8px 0;
        }

        .fast-exchange-description {
            font-size: 17px;
            line-height: 150%;
        }
    }
`

export default FastExchangeDiv
