import styled from 'styled-components'

const PhotoExampleDiv = styled.div`
    width: 100%;

    .photo-example-header {
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 130%;
        margin-bottom: 8px;
        text-align: start;
    }

    .photo-example-description {
        font-family: Fira Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 150%;

        display: flex;
        align-items: center;

        color: #000000;

        margin-top: 8px;
    }

    .example-content {
        display: flex;
        justify-content: space-between;

        img {
            border-radius: 30px;
            height: 185px;
            width: 320px;
        }
    }

    .photo-example-label {
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 1.058em;
        line-height: 130%;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        letter-spacing: 0.5px;
        color: #f7f7f7;
        cursor: pointer;
        width: 274px;
        padding: 0 10px;
        height: 50px;
        background: #404040;
        border-radius: 14px;
        /* margin-bottom: 12px; */

        &.notActive {
            color: #999999;
            background: transparent;

            border: 2px solid #999;
        }
    }

    .photo-upload-btn input[type='file'] {
        opacity: 0;
        position: absolute;
        z-index: -1;
    }

    .photo-example-item {
        display: flex;
        flex-direction: column;

        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 130%;

        color: #5c5c5c;

        margin-top: 32px;
        margin-bottom: 10px;
        margin-left: 32px;
    }

    .btns-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 10px 0;
    }

    .btns-items {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 16px;

        &.up {
            display: none;
        }
    }

    .photo-upload-btn {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .photo-loaded-file {
        width: 100%;
        max-width: 294px;
        height: 58px;

        background: rgba(184, 184, 184, 0.3);
        border-radius: 14px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .photo-file-name {
        display: flex;
        justify-content: space-between;
        align-items: center;

        font-family: 'SF UI Display Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;

        color: #404040;

        margin: 12px 12px 12px 20px;
    }

    .upload-check {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 18px;
        height: 18px;

        background: #864dd9;
        border-radius: 50%;

        margin-left: 16px;
    }

    .photo-file-delete {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 34px;
        min-width: 34px;
        height: 34px;

        background: #404040;
        border-radius: 10px;
    }

    .border-file {
        width: calc(100% - 20px);
        margin: 0 auto;
        height: 2px;
        border-radius: 0 0 3px 3px;
        background-color: #864dd9;
    }

    .photo-send-btn {
        /* width: 216px; */
    }

    @media screen and (max-width: 726px) {
        .btns-container {
            flex-direction: column;
            width: 100%;
            max-width: 340px;

            margin: 0 auto;
            align-items: center;
        }

        .example-content {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
                border-radius: 30px;
                height: auto;
            }
        }

        .btns-items {
            margin-left: 0;
            margin-top: 32px;
            min-height: 140px;

            &.up {
                display: flex;
            }

            &.down {
                display: none;
            }
        }

        .photo-example-label {
            margin-bottom: 12px;
        }

        .photo-send-btn {
            margin-top: 12px;
            width: 100%;
            max-width: 340px;
        }

        .photo-example-item {
            font-size: 16px;
            line-height: 130%;

            text-align: center;
        }

        .photo-example-item {
            margin-left: 0;
        }

        .photo-upload-btn {
            justify-content: flex-start;
        }
    }

    @media screen and (max-width: 480px) {
        .photo-example-header {
            font-size: 28px;
            line-height: 130%;
            text-align: start;
            margin-top: 12px;
        }

        .photo-example-description {
            font-size: 16px;
            line-height: 150%;
        }

        .photo-upload-btn {
            margin-top: 32px;
        }

        .btns-container {
            width: 100%;
            padding-bottom: 20px;
        }

        .btns-items {
            /* width: calc(100% - 16px); */
        }

        .example-content {
            display: flex;

            img {
                border-radius: 30px;
                /* height: 185px; */
                width: 100%;
                height: auto;
            }
        }
    }
`

export default PhotoExampleDiv
