import React from 'react'

import AdvantageComponent from '../../components/Advantage/Advantage'
import FastExchange from '../../components/FastExchange/FastExchange'
import HowExchangeComponent from '../../components/HowExchange/HowExchange'
import ExchangeAdvantages from '../../components/ExchangeAdvantages/ExchangeAdvantages'
import store from '../../redux'
import WalletComponent from '../ExchangeForm/elements/Wallet/Wallet'
import InfoSection from '../../components/InfoSection/InfoSection'
import MainPageConponent from './style.css'

import FooterCircle from '../../images/decorationAboveFooter_desktop.svg'
import HeaderCircle from '../../images/decorationMain_desktop.svg'

import BG_desktop from '../../images/bgPicture/bgMain_desktop.svg'
import BG_tablet from '../../images/bgPicture/bgMain_tablet.svg'
import BG_mobile from '../../images/bgPicture/bgMain_mobile.svg'

const MainPage = () => {
    const state = store.getState().exchangeStore

    return (
        <MainPageConponent
            bgImageDesktop={state.widgetMode ? null : BG_desktop}
            bgImageTablet={state.widgetMode ? null : BG_tablet}
            bgImageMobile={state.widgetMode ? null : BG_mobile}
            >
            {state.widgetMode ? null : (
                <div className='fast-exchange-circle'>
                    <img src={HeaderCircle} alt='circle' />
                </div>
            )}
            <div className='main-screen'>
                <div
                    className={`main-wrap ${state.widgetMode ? 'widget' : ''}`}
                    >
                    <FastExchange widgetMode={state.widgetMode} />
                    <div className='advantages-wrapper'>
                        <ExchangeAdvantages />
                    </div>
                </div>
                {!state.widgetMode ? (
                    <>
                        <div className='about-us'>
                            <AdvantageComponent />
                        </div>
                        <div className='howExchange-wrapper'>
                            <HowExchangeComponent />
                        </div>
                        <InfoSection />
                        <div className="wallet-wrapper">
                            <WalletComponent mainPage />
                        </div>
                    </>
                ) : null}
            </div>
            {state.widgetMode ? null : (
                <div className='footer-custom-circle'>
                    <img src={FooterCircle} alt='Circle' />
                </div>
            )}
        </MainPageConponent>
    )
}

export default MainPage
