import axios from 'axios'
import socketIOClient from 'socket.io-client'

import validation from './ValidationService'
import config from '../../../config/config'
import store from '../../../redux'
import {
    cardPhotoAction,
    cardVerifyStatusAction,
    paymenDataAction
} from '../../../redux/stores/exchangeStore/action'

const { dispatch } = store

export const checkCardNumber = async (cardNumber) => {
    const state = store.getState().exchangeStore

    if (!cardNumber) {
        return null
    }

    cardNumber = cardNumber.toString().split(' ').join('')

    if (cardNumber.length !== 16) {
        return { ...state.paymentData, number: cardNumber }
    }
    if (!validation('CARD', cardNumber)) {
        return {
            ...state.paymentData,
            number: cardNumber,
            status: 'INVALID_CARD_NUMBER'
        }
    }

    dispatch(paymenDataAction({ number: cardNumber, status: 'PENDING' }))

    // Check country
    const { countryCode, scheme } = await getCountryCodeAndScheme(
        state,
        cardNumber
    )
    const exchangeWayInfo = state.preRequestExchagneWays.find(
        (way) => way.exchangeWayId === state.equivalentData.exchangeWayId
    )

    if (
        exchangeWayInfo?.supportedCountriesData &&
        !exchangeWayInfo?.skipPaymentDetailsScreen
    ) {
        switch (exchangeWayInfo.supportedCountriesData.type) {
            case 'EQUALS':
                if (
                    !exchangeWayInfo.supportedCountriesData.countryCodes.includes(
                        countryCode
                    )
                ) {
                    return {
                        ...state.paymentData,
                        number: cardNumber,
                        status: 'NOT_SUPPORTED_COUNTRY'
                    }
                }
                break

            case 'NOT_EQUALS':
                if (
                    exchangeWayInfo.supportedCountriesData.countryCodes.includes(
                        countryCode
                    )
                ) {
                    return {
                        ...state.paymentData,
                        number: cardNumber,
                        status: 'NOT_SUPPORTED_COUNTRY'
                    }
                }
                break

            default:
                console.log(
                    'Unpredictable supported countries type: ' +
                        exchangeWayInfo.supportedCountriesData.type
                )
                return
        }
    }

    if (state.inCurrency.type === 'FIAT' && !state.saveUkraine) {
        // Check card verification
        const statusCard = await verifyCard(cardNumber)
        cardSocketOpening(state, cardNumber)

        if (!statusCard) {
            return {
                status: 'NO_INFO',
                number: cardNumber,
                countryCode
            }
        }

        return {
            number: cardNumber,
            countryCode,
            scheme,
            status: statusCard
        }
    } else {
        return {
            number: cardNumber,
            countryCode,
            scheme,
            status: 'SUCCESS'
        }
    }
}

const bin = ['400680', '220038']

const getCountryCodeAndScheme = async (state, cardNumber) => {
    try {
        let response

        cardNumber = cardNumber.split(' ').join('')

        if (state.inCurrency === 'RUB') {
            if (bin.includes(cardNumber.slice(0, 6))) {
                return {
                    countryCode: '643',
                    scheme: ''
                }
            }

            response = await axios.get(
                `https://api.v3.trustee.deals/coin-cat/bin?bin=${cardNumber.slice(
                    0,
                    6
                )}`
            )
            return {
                countryCode: response.data.countryCode,
                scheme: ''
            }
        }

        response = await axios.get('https://lookup.binlist.net/' + cardNumber)

        return {
            countryCode: response.data.country.numeric,
            scheme: response.data.scheme
        }
    } catch (error) {
        console.log(error)
        return false
    }
}

const verifyCard = async (cardNumber) => {
    try {
        const response = await axios.get(
            config.backendUrl +
                '/payment-details/validation-status?cardNumber=' +
                cardNumber
        )
        return response.data.status
    } catch (error) {
        console.log(JSON.stringify(error.response.data))
        return false
    }
}

const cardSocketOpening = (state, cardNumber) => {
    const cardSocket = socketIOClient(
        config.backendUrl +
            '?mode=CARD_VERIFICATION&token=' +
            state.userToken +
            '&cardNumber=' +
            cardNumber,
        { transports: ['websocket'] }
    )

    cardSocket.on('openedCardPhotoPage', () => {
        dispatch(cardPhotoAction())
    })

    cardSocket.on('updateCardVerifyStatus', (data) => {
        const newState = store.getState().exchangeStore
        dispatch(cardVerifyStatusAction(newState.paymentData, data))
    })
}
