import axios from 'axios'
import config from '../../../config/config'

export default async function getMinMaxLimitsObj(
    allExchangeWays,
    inCurrency,
    outCurrency,
    payway
) {
    if (!inCurrency || !outCurrency) {
        return
    }

    let exchangeWays = allExchangeWays
        .filter(
            (way) =>
                config.availableRate.includes(way.rateType) &&
                !config.disabledProviders.includes(way.provider)
        )
        .filter(
            (way) =>
                way.inCurrency.code === inCurrency.code &&
                way.outCurrency.code === outCurrency.code
        )

    if (!exchangeWays.length || !exchangeWays) {
        return { min: 0, max: 0, currencySide: null }
    }

    switch (exchangeWays[0].exchangeWayType) {
        case 'BUY':
            exchangeWays = exchangeWays.filter(
                (way) => way.inPaywayCode === payway
            )
            break

        case 'SELL':
            exchangeWays = exchangeWays.filter(
                (way) => way.outPaywayCode === payway
            )
            break

        default:
            break
    }

    const response = await axios.post(config.backendUrl + '/data/limits', {
        exchangeWayIds: exchangeWays.map((way) => way.exchangeWayId)
    })
    const limits = response.data.limits

    if (limits.length === 1) {
        return limits[0]
    }

    let min = limits[0].min
    let max = limits[0].max
    limits.map((limit) => {
        if (limit.min < min) {
            min = limit.min
        }
        if (limit.max > max) {
            max = limit.max
        }
        return null
    })

    return { min, max, currencySide: limits[0].currencySide }
}
