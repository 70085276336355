import styled from 'styled-components'

const ButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    text-align: center;

    width: 100%;
    min-height: 50px;

    background: #404040;
    opacity: ${(p) => (p.condition ? 1 : 0.2)};
    box-shadow: 0px 6px 10px rgba(64, 64, 64, 0.2);
    border-radius: 14px;

    cursor: pointer;

    img {
        width: 40px;
    }

    .button-title {
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;

        /* text-align: center; */
        letter-spacing: 0.5px;

        color: #f7f7f7;
    }

    /* @media screen and (max-width: 640px) {
        width: calc(100vw - 48px);
    } */
`

export default ButtonDiv
