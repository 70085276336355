import styled from 'styled-components'

const FastExchangeDiv = styled.div`
    .advantages-content {
        display: flex;
        justify-content: space-between;
    }
    .advantages-item {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        /* max-width: 32%; */
    }

    .advantages-number {
        font-family: 'Montserrat Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 72px;
        color: #b8b8b8;
        min-width: 40px;
    }
    .advantages-text {
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        /* text-align: center; */
        color: #5c5c5c;
    }
    .exchange {
        padding-left: 5px;
    }
    .swap {
        padding-left: 10px;
    }
    .buy {
        padding-left: 10px;
    }
    @media screen and (max-width: 768px) {
        .advantages-item {
            max-width: 32%;
        }
        .advantages-text {
            text-align: left;
        }
    }

    @media screen and (max-width: 640px) {
        margin-top: 78px;

        .advantages-content {
            flex-direction: column;
        }
        .advantages-item {
            padding-left: 32px;
            width: 100%;
            padding-bottom: 40px;
            justify-content: flex-start;
            max-width: 70%;
        }
        .advantages-text {
            text-align: left;
        }
        .advantages-number {
            margin-right: 10px;
        }
    }

    /* @media screen and (min-width: 640px) and (max-width: 1280px) {
    } */
`

export default FastExchangeDiv
