import styled from 'styled-components'

const InputDiv = styled.div`
    width: 100%;

    .input-label {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        padding-left: 1.176em;
        padding-bottom: 0.352em;

        height: 2.941em;
        /* width: 45%; */

        /* max-width: 228px; */

        font-family: Fira Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1em;
        line-height: 120%;

        color: #5c5c5c;

        /* &.error-container {
            color: #F1E1B5;    
        } */
    }

    .input-container {
        display: flex;
        flex-direction: row;

        width: 100%;
        height: 50px;

        background: #f5f5f5;
        border: 1px solid #f5f5f5;
        box-sizing: border-box;
        border-radius: 0.823em;

        &.verification-block {
            border: 1px solid #dadada;
        }

        &.error-block {
            border: 1px solid #f1e1b5;
        }
    }

    .input-container-input {
        width: calc(100% - 50px);

        padding-left: 20px;

        background: #f5f5f5;

        border-radius: 0.823em;
        border: none;
        outline: none;

        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 1.0588em;
        line-height: 120%;

        color: #404040;

        ::placeholder {
            font-family: 'Montserrat SemiBold';
            font-style: normal;
            font-weight: 600;
            font-size: 1.0588em;
            line-height: 120%;

            color: #999999;
        }
    }

    .input-container-icon {
        height: 100%;
        width: 2.82em;

        display: flex;
        align-items: center;
        justify-content: center;

        &.canceled {
            background: #999999;
            border-radius: 50px;
            width: 24px;
            height: 24px;
            align-self: center;
            margin-right: 20px;
        }

        &.success {
            background: #864dd9;
            border-radius: 50px;
            width: 24px;
            height: 24px;
            align-self: center;
            margin-right: 20px;
        }

        &.progress {
            margin-right: 10px;
        }
    }

    .input-container-btn {
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;

        width: 7.412em;
        height: 2.706em;

        border: 0.117em solid #dadada;
        margin-right: 0.117em;
        box-sizing: border-box;
        border-radius: 0.823em;

        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 0.941;
        line-height: 120%;
        /* identical to box height, or 100% */

        text-align: center;
        letter-spacing: 0.0294em;

        color: #5c5c5c;
    }

    .input-error {
        display: flex;
        align-items: center;

        width: calc(100% - 2px);
        min-height: 32px;

        background: linear-gradient(0deg, #f1e1b5, #f1e1b5);
        border-radius: 0 0 0.82em 0.82em;

        border: 0.0588em solid #f1e1b5;

        padding-bottom: 10px;

        /* margin-top: -14px; */

        /* position: absolute;
        top: 100%;
        z-index: 600; */

        /* &.out {
            top: 100%;
        } */
    }

    .input-error-text {
        margin-top: 0.588em;
        margin-left: 1.176em;

        font-family: Fira Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 150%;

        color: rgba(186, 137, 10, 0.9);
    }

    .card-status {
        display: flex;
        align-self: flex-start;
        text-align: start;

        padding-top: 0.7em;
        padding-left: 1.176em;
        font-family: Fira Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 150%;
        letter-spacing: 0.029em;
        width: calc(100% - 2.35em);
        color: #5c5c5c;
    }

    .card-verif-container {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        width: 100%;

        border-radius: 0 0 14px 14px;
        padding-bottom: 20px;
    }

    .verification-container {
        background: rgba(184, 184, 184, 0.6);
        border-radius: 14px;
    }

    .wrapper-container {
        background: linear-gradient(0deg, #f1e1b5, #f1e1b5);
        border-radius: 14px;
    }

    @media screen and (max-width: 640px) {
        .input-label {
            font-size: 16px;
            line-height: 150%;
        }

        .input-value-error {
            font-size: 15px;
            line-height: 150%;
        }

        .input-container-icon {
            width: 40px;

            &.canceled {
                margin-right: 16px;
            }

            &.success {
                margin-right: 16px;
            }

            &.progress {
                margin-right: 6px;
            }
        }
    }
`

export default InputDiv
