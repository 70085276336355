import React, { useEffect, useState } from 'react'
import Resizer from 'react-image-file-resizer'
import ReCAPTCHA from 'react-google-recaptcha'
import axios from 'axios'
import queryString from 'query-string'
import socketIOClient from 'socket.io-client'

import CardPhotoComponent from './style.css'
import config from '../../config/config'
import i18n, { strings } from '../../i18n'

import statusCard from '../ExchangeForm/services/statusCard'
import PhotoExample from '../ExchangeForm/elements/PhotoUpload/PhotoExample/PhotoExample'

import Loader from '../../images/loaderBlack.svg'

const reCaptchaRef = React.createRef()

const CardPhoto = () => {
    const [fileData, setFileData] = useState(null)
    const [fileName, setFileName] = useState(null)
    const [upload, setUpload] = useState(null)
    const [fileValid, setFileValid] = useState(null)
    const [verificationStatus, setVerificationStatus] = useState(null)
    const [cardNumber, setCardNumber] = useState(null)
    const [countryCode, setCountryCode] = useState(null)
    const [locale, setLocale] = useState(null)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        await cardPhoto()
        window.scroll(0, 0)
    }, [])

    const fileInputHandler = async (event) => {
        const file = event.target.files[0]

        const fileData = await new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1920,
                1080,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri)
                },
                'base64'
            )
        })

        setFileData(fileData)
        setFileName(file.name)
        setFileValid(true)
    }

    const uploadButtonHandler = async () => {
        window.scrollTo(0, 0)
        try {
            if (!cardNumber) {
                setFileValid(false)
                return
            }
            if (!fileData) {
                setFileValid(false)
                return
            }

            const captchaToken = await reCaptchaRef.current.executeAsync()

            const body = new FormData()
            body.set('cardNumber', cardNumber)
            body.set('locale', locale)
            body.set('countryCode', countryCode)
            body.set('captchaToken', captchaToken)
            body.append('image', fileData)

            const response = await axios.post(
                config.backendUrl + '/payment-details/validate-card',
                body,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }
            )

            setUpload(true)
            setVerificationStatus(response.data.verificationStatus)
        } catch (error) {
            console.log(error.message)
            reCaptchaRef.current.reset()
            return
        }
    }

    const cardPhoto = async () => {
        const url = window.location.href
        const urlParamsString = url.slice(url.indexOf('?') + 1)
        const urlParamsObj = queryString.parse(urlParamsString)

        const cardNumber = urlParamsObj.cardNumber || null
        const countryCode = urlParamsObj.countryCode || null
        const locale = urlParamsObj.locale || 'en'

        i18n.changeLanguage(locale)
        localStorage.setItem('locale', locale)

        const socket = socketIOClient(config.backendUrl, {
            transports: ['websocket']
        })
        socket.emit('openedCardPhotoPage', { cardNumber })

        setCardNumber(cardNumber)
        setCountryCode(countryCode)
        setLocale(locale)
    }

    return (
        <>
            <CardPhotoComponent>
                <div className='container'>
                    <div className='container-info'>
                        <h3>{strings('cardPhoto.proof')}</h3>
                        <div className='container-description'>
                            {cardNumber
                                ? strings('cardPhoto.takePhoto') +
                                  cardNumber.slice(-4, cardNumber.length)
                                : null}
                        </div>
                    </div>
                    {upload ? (
                        <div className='card-verification-status'>
                            {strings('cardPhoto.verificationStatus') +
                                '\n ' +
                                statusCard(verificationStatus || '')}
                            <img src={Loader} alt='Loader' />
                        </div>
                    ) : (
                        <PhotoExample
                            fileInputHandler={fileInputHandler}
                            condition={fileValid}
                            uploadAction={uploadButtonHandler}
                            fileData={fileData}
                            fileName={fileName}
                            deleteFileAction={() => {
                                setFileData(null)
                                setFileName(null)
                            }}
                        />
                    )}
                </div>
            </CardPhotoComponent>
            <ReCAPTCHA
                ref={reCaptchaRef}
                size='invisible'
                sitekey={config.captchaSiteKey}
                style={{ position: 'absolute', top: '20%' }}
            />
        </>
    )
}

export default CardPhoto
