import React from 'react'
import Icon from '../Icon/Icon'
import { strings } from '../../i18n'
import { getThemeModeStyles } from '../../theme/useDarkMode'
import InfoMessageDiv from './style.css'

const InfoMessage = (props) => {
    const theme = getThemeModeStyles()

    return (
        <InfoMessageDiv bg={theme.infoMessageBg}>
            <div>
                <Icon icon='INFO' size={22} color={theme.violet} />
            </div>
            <div className='message-body'>
                <div
                    className='desktop-headline-h5'
                    style={{ color: theme.violet }}
                >
                    {strings('exchangeForm.attention')}
                </div>
                <pre
                    className='body-idle message-description'
                    style={{ color: theme.violet2 }}
                >
                    {strings('exchangeForm.saveUkraine')}
                </pre>
            </div>
        </InfoMessageDiv>
    )
}

export default InfoMessage
