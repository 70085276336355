import React from 'react'
import { strings } from '../../i18n'
import ExchangeAdvantagesDiv from './style.css'

const ExchangeAdvantages = (props) => {
    const contentArray = [
        {
            id: 1,
            title: strings('exchanger.advantagesSwap'),
            subStyle: 'exchange'
        },
        {
            id: 2,
            title: strings('exchanger.advantagesExchange'),
            subStyle: 'swap'
        },
        {
            id: 3,
            title: strings('exchanger.advantagesBuy'),
            subStyle: 'buy'
        }
    ]
    return (
        <ExchangeAdvantagesDiv>
            <div className='advantages-content'>
                {contentArray.map((item) => {
                    return (
                        <div className='advantages-item' key={item.id}>
                            <div className='advantages-number'>{item.id}</div>
                            <div
                                className={`advantages-text ${item.subStyle}`}>
                                {item.title}
                            </div>
                        </div>
                    )
                })}
            </div>

        </ExchangeAdvantagesDiv>
    )
}

export default ExchangeAdvantages
