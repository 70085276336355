import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { Route, BrowserRouter as Router } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { ThemeProvider } from 'styled-components'
import { SnackbarProvider } from 'notistack'
import queryString from 'query-string'

import { useDarkMode } from './theme/useDarkMode.js'
import { GlobalStyles } from './theme/globalStyles.js'

import store from './redux/index'
import Header from './modules/Header/Header.jsx'
import FooterComponent from './modules/Footer/Footer.jsx'
import RouteComponent from './modules/Route/Route.jsx'

import './App.css'
import OrderInfo from './modules/OrderInfo/OrderInfo.jsx'

function App() {
    const [themeMode] = useDarkMode()

    const [widgetMode, setMode] = useState(null)

    useEffect(() => {
        const url = window.location.href
        const urlParamsString = url.slice(url.indexOf('?') + 1)
        const urlParamsObj = queryString.parse(urlParamsString)

        const widgetMode = urlParamsObj?.widgetMode
            ? JSON.parse(urlParamsObj?.widgetMode)
            : null

        setMode(widgetMode)

        if (!widgetMode) {
            const script = document.createElement('script')
            script.src =
                'https://cdn.respond.io/webchat/widget/widget.js?cId=397c8aaad255d4b7f99091c685ac318be3d7d0062b62b9e50c1bd9bc9e903fc5'
            script.async = true
            script.id = 'respondio__widget'
            document.body.appendChild(script)

            return () => {
                document.body.removeChild(script)
            }
        }
    }, [])

    const locale = localStorage.getItem('locale') || 'en'

    return (
        <ThemeProvider theme={themeMode}>
            <GlobalStyles />
            <Provider store={store}>
                <SnackbarProvider
                    maxSnack={1}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    transitionDuration={{ enter: 100, exit: 200 }}
                    resumeHideDuration={0.5}
                >
                    <Router>
                        <div
                        
                            className='main-content'
                            style={{
                                display: widgetMode ? 'block' : 'grid',
                                overflow: widgetMode ? 'hidden' : 'scroll',
                                background: widgetMode
                                    ? '#F5F4F8'
                                    : 'transparent'
                            }}
                        >
                            {!widgetMode ? <Header /> : null}
                            <div>
                                <Route path='/' component={RouteComponent} />
                                <Route
                                    path='/order-info'
                                    component={OrderInfo}
                                />
                            </div>
                            {!widgetMode ? <FooterComponent locale={locale} /> : null}
                        </div>
                    </Router>
                </SnackbarProvider>
            </Provider>
        </ThemeProvider>
    )
}

export default withTranslation()(App)
