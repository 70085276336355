import React from 'react'
import { strings } from '../../i18n'
import ExchnageFormComponent from '../../modules/ExchangeForm/ExchangeForm'
import FastExchangeDiv from './style.css'

const FastExchange = (props) => {
    const { widgetMode } = props

    return (
        <FastExchangeDiv>
            {!widgetMode ? (
                <>
                    <div className='fast-exchange'>
                        <h1 className='fast-exchange-title'>
                            {strings('exchanger.fastExchange')}
                        </h1>
                        <div className='fast-exchange-description'>
                            {strings('exchanger.fastExchnageDescription')}
                        </div>
                    </div>
                </>
            ) : null}
            <ExchnageFormComponent mainPage />
        </FastExchangeDiv>
    )
}

export default FastExchange
