import styled from 'styled-components'

const InfoMessageDiv = styled.div`
    /* width: 556px; */
    max-width: 556px;
    min-height: 95px;
    background: ${(p) => p.bg};
    border-radius: 40px;

    padding: 32px 30px 32px 32px;

    display: flex;
    flex-direction: row;

    /* margin: 50px 0 0 0; */

    .message-body {
        margin-left: 12px;
    }

    .message-description {
        margin: 10px 0 0 0;
    }

    @media screen and (max-width: 640px) {
        width: 100%;
        max-width: 100%;

        margin: 40px 16px;
    }

    @media screen and (min-width: 640px) and (max-width: 1280px) {
        margin: 0 32px 0 0;
    }
`

export default InfoMessageDiv
