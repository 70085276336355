import styled from 'styled-components'

const OrderBlockDiv = styled.div`
    width: 100%;
    max-width: 510px;
    min-height: 400px;

    background: transparent;

    position: relative;

    .order-container {
        background: #e8e8e8;
        border-radius: 40px;
        width: calc(100% - 138px);
        max-width: 420px;
        min-height: 155px;

        display: flex;
        flex-direction: column;
        align-self: center;
        justify-content: center;
        margin-left: 45px;

        padding: ${(p) => (p.data ? '154px 24px 40px 24px' : '40px 24px')};
    }

    .data-loading {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 80px;
        }
    }

    .order-container-coin {
        position: absolute;
        top: 43px;
        left: 0;
        right: 0;

        width: 100%;
        max-width: 510px;

        display: flex;
        justify-content: space-between;
    }

    .order-currency {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .order-hash {
        font-family: Fira Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1em;
        line-height: 150%;

        color: #5c5c5c;

        text-align: center;
    }

    .order-currency-name {
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 1.41em;
        line-height: 140%;

        color: #404040;
    }

    .order-currency-icon {
        padding: 15px;
        background: #f7f7f7;
        border-radius: 50%;
        width: 60px;
        height: 60px;
    }

    .order-status-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;

        font-family: Fira Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 150%;

        text-align: center;

        color: #5c5c5c;
    }

    .order-status-label {
        margin-right: 4px;
        line-height: 170%;
    }

    .order-status-value {
        color: #864dd9;

        display: flex;
        align-items: center;

        img {
            width: 30px;
            margin-left: 10px;
        }

        &.canceled {
            color: #999;
        }
    }

    .canceled-order {
        background: #999;
        width: 24px;
        height: 24px;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;

        margin-left: 8px;
    }

    .done-order {
        background: #864dd9;
        width: 24px;
        height: 24px;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;

        margin-left: 8px;
    }

    .order-data-item {
        margin-left: 16px;
    }

    .order-data-item-label {
        font-family: Fira Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1em;
        line-height: 150%;

        color: #5c5c5c;

        margin-top: 16px;
    }

    .order-data-item-value {
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 1em;
        line-height: 130%;

        color: #404040;

        &.amount {
            font-family: 'Montserrat Medium';
            font-weight: 500;
            font-size: 1.294em;
        }
    }

    .order-data-deposit-container {
        width: calc(100% - 32px);
        background: rgba(184, 184, 184, 0.5);
        border-radius: 32px;
        padding: 4px 16px 20px 16px;
        margin-top: 16px;
    }

    .order-data-deposit {
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        align-items: center;
        /* width: 100%; */
    }

    .order-data-deposit-address {
        display: flex;
        align-items: center;

        background: rgba(184, 184, 184, 0.6);
        box-shadow: inset 0px 0px 16px rgba(0, 0, 0, 0.05);
        border-radius: 14px;

        padding: 0 20px;
        margin-top: 24px;

        word-break: break-all;

        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 130%;

        color: #404040;
    }

    .order-data-adress-qr {
        padding: 20px;
        background: #f5f5f5;
        box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.1);
        border-radius: 24px;
        margin-top: 24px;
    }

    .order-data-icon-copy {
        padding-left: 10px;
    }

    @media screen and (max-width: 640px) {
        width: 100vw;
        max-width: 100vw;

        .order-container {
            border-radius: 0;
            width: calc(100vw - 30px);
            max-width: 100vw;

            margin-left: 0;
            padding: 152px 15px 20px 15px;
        }

        .order-container-coin {
            width: calc(100vw - 30px);
            max-width: 100vw;
            margin-left: 15px;
        }

        .order-currency-icon {
            padding: 0;
            border-radius: 0;
            background: transparent;

            align-self: center;
        }

        .order-data-item-label {
            font-size: 16px;
            line-height: 150%;
        }

        .order-data-item-value {
            font-size: 17px;
            line-height: 130%;

            &.amount {
                font-size: 22px;
                line-height: 130%;
            }
        }

        .order-data-icon-copy {
            svg {
                width: 24px;
                height: 24px;
            }
        }

        .order-data-deposit-container {
            width: calc(100% - 32px);
        }
    }
`

export default OrderBlockDiv
