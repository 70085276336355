import styled from 'styled-components'

const CarouselDiv = styled.div`
    background: transparent;

    max-width: 90%;
    margin-top: 71px;

    /* max-height: 268px; */

    img {
        object-fit: cover;
        max-width: 98%;
    }

    .carousel-item {
        max-width: 100%;
        object-fit: contain;
        cursor: pointer;

        img {
            border-radius: 40px;
            object-fit: contain;
        }
    }
`

export default CarouselDiv
