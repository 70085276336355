import styled from 'styled-components'

const ExchangePageContainer = styled.div`
    width: 100%;
    max-width: 2010px;
    margin: 0 auto;

    padding: ${(p) => (!p.widgetMode ? '30px 0 100px 0' : 0)};

    background: no-repeat ${(p) => `url(${p.bgImageDesktop})`};
    background-position: left top;

    .exchange-container {
        display: flex;
        justify-content: space-between;

        &.external {
            justify-content: center;
        }
    }

    .exchange-wallet-component {
        width: 100%;
        max-width: 45vw;
        /* min-width: 320px; */
    }

    .exchange-select-way {
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 3.17em;
        line-height: 120%;

        display: flex;
        align-items: center;

        color: #404040;

        /* margin-top: 30px; */
    }

    .createvies-one {
        display: flex;
        max-width: 1024px;
    }

    .createvies-two {
        display: none;
    }

    .message-one {
        display: block;
    }

    .message-two {
        display: none;
    }

    @media screen and (max-width: 640px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding: ${(p) => (!p.widgetMode ? '24px 0 50px 0' : 0)};

        background: no-repeat ${(p) => `url(${p.bgImageMobile})`};

        .exchange-container {
            flex-wrap: wrap;
        }

        .exchange-select-way {
            font-size: 20px;
            line-height: 130%;

            margin-top: 0px;
        }

        .exchange-wallet-component {
            width: calc(100% - 32px);
            margin-left: 16px;
            max-width: 100%;

            margin-bottom: 16px;
        }

        .createvies-one {
            display: none;
        }

        .createvies-two {
            display: flex;
            justify-content: center;
        }

        .message-one {
            display: none;
        }

        .message-two {
            display: flex;
            justify-content: center;

            width: 100%;

            margin-top: -36px;
        }
    }

    @media screen and (min-width: 640px) and (max-width: 1280px) {
        width: calc(100% - 64px);
        margin-left: 32px;
        background: no-repeat ${(p) => `url(${p.bgImageTablet})`};

        .exchange-select-way {
            /* margin-top: 80px; */

            font-size: 40px;
            line-height: 120%;
        }
    }

    @media screen and (min-width: 1280px) and (max-width: 1920px) {
        max-width: 1266px;
        margin: 0 auto;
    }
`

export default ExchangePageContainer
