import React from 'react'
import { getPaywayName, PaywayIcon } from 'trustee-swap-assets'

const PaywayListItem = (props) => {
    const { selected, item, side, isActive, selectItem, setIsActive } = props

    return (
        <div
            className={`selector-item ${selected ? 'select' : ''}`}
            onClick={async () => {
                setIsActive(!isActive)
                await selectItem(side, item)
            }}
        >
            <div className='selector-item-container'>
                <div className='selector-item-text'>{getPaywayName(item)}</div>
            </div>
            <div className='selector-item-code'>
                <PaywayIcon paywayCode={item} />
            </div>
        </div>
    )
}

export default PaywayListItem
