import React from 'react'
import AdvantageDiv from './style.css'

import { strings } from '../../i18n'

import Rate from '../../images/icon/bestRate.png'
import Exchange from '../../images/icon/fastExchange.png'
import Term from '../../images/icon/confiditiality.png'
import Service from '../../images/icon/security.png'
import Support from '../../images/icon/support.png'
import WhatComponent from './modules/WhatComponent'

const AdvantageComponent = (props) => {
    const contentArray = [
        {
            id: 1,
            title: strings('exchanger.bestRate'),
            icon: Rate
        },
        {
            id: 2,
            title: strings('exchanger.exchangeFast'),
            icon: Exchange
        },
        {
            id: 3,
            title: strings('exchanger.confidentiality'),
            icon: Term
        },
        {
            id: 4,
            title: strings('exchanger.service'),
            icon: Service
        },
        {
            id: 5,
            title: strings('exchanger.support'),
            icon: Support
        }
    ]

    return (
        <AdvantageDiv>
           
           <WhatComponent/>
            <div className='advantage-container'>
                <h2>{strings('exchanger.advantage')}</h2>
                <div className='content'>
                    {contentArray.map((item) => {
                        return (
                            <div className='content-item' key={item.id}>
                                <img
                                    src={item.icon}
                                    datasrc={item.icon}
                                    alt={item.title}
                                    loading='lazy'
                                />
                                {/* <div className='content-item-circle'>
                                    <Icon icon={item.icon} size={30} />
                                </div> */}
                                <div className='content-item-title'>
                                    {item.title}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </AdvantageDiv>
    )
}

export default AdvantageComponent
