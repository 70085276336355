import React from 'react'

import store from '../../redux'
import ExchangeForm from '../ExchangeForm/ExchangeForm'
import ExchangePageContainer from './style.css'
import { strings } from '../../i18n'
import CarouselCompoent from '../../components/Carousel/Carousel'

import BG_desktop from '../../images/bgPicture/bgEnteringDetails_desktop.svg'
import BG_tablet from '../../images/bgPicture/bgEnteringDetails_tablet.svg'
import BG_mobile from '../../images/bgPicture/bgEnteringDetails_mobile.svg'
import InfoMessage from '../../components/InfoMessage/InfoMessage'

const ExchangePage = () => {
    const state = store.getState().exchangeStore

    const locale = localStorage.getItem('locale') || 'en'

    return (
        <ExchangePageContainer
            bgImageDesktop={state.widgetMode ? null : BG_desktop}
            bgImageTablet={state.widgetMode ? null : BG_tablet}
            bgImageMobile={state.widgetMode ? null : BG_mobile}
            widgetMode={state.widgetMode}
        >
            <div
                className={`exchange-container ${state.externalId && 'external'
                    } `}
            >
                {!state.widgetMode && state.saveUkraine ? (
                    <div className='message-two'>
                        <InfoMessage />
                    </div>
                ) : null}

                {!state.widgetMode ?
                    <div className='exchange-wallet-component'>
                        {!state.saveUkraine ? (
                            <div className='exchange-select-way'>
                                {strings('exchangeForm.selectPair', {
                                    inCurrencyCode: state?.inCurrency?.symbol,
                                    outCurrencyCode: state?.outCurrency?.symbol
                                })}
                            </div>
                        ) : null}
                        {!state.externalId ?
                            state.saveUkraine ?
                                <div className='message-one'>
                                    <InfoMessage />
                                </div>
                                :
                                <div className='createvies-one'>
                                    <CarouselCompoent locale={state.locale} />
                                </div> : null}
                    </div> : null}
                <ExchangeForm locale={locale} />
                {!state.externalId ? (
                    state.saveUkraine ?
                        null : (
                            <div className='createvies-two'>
                                <CarouselCompoent locale={state.locale} />
                            </div>
                        )
                ) : null}
            </div>
        </ExchangePageContainer>
    )
}

export default ExchangePage