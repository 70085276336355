import React, { useEffect, useRef, useState } from 'react'
import {
    CryptoMonoCurrencyIcon,
    FiatMonoCurrencyIcon,
    PaywayIcon,
    FiatCurrencyList,
    CurrencyList,
    CryptoCurrencyList,
    PaywayNameList,
    isToken,
    getTokenNet
} from 'trustee-swap-assets'

import store from '../../../../redux'
import { useDetectOutsideClick } from '../../../Header/useDetected'
import isActiveCoin from '../../services/isActiveCoin'
import SelectorDiv from './style.css'

import Icon from '../../../../components/Icon/Icon'
import {
    inCurrencySelectAction,
    outCurrencySelectAction,
    inCurrencySearchAction,
    outCurrencySearchAction,
    paywaySearchAction,
    paywaySelectAction,
    activeExchangeWays
} from '../../../../redux/stores/exchangeStore/action'
import { strings } from '../../../../i18n'
import { getPaywayList } from '../../services/paywayList'
import PaywayListItem from './elements/PaywayListItem'
import CoinListItem from './elements/CoinListItem'
import Input from '../Input/Input'
import getMinMaxLimitsObj from '../../services/getMinMaxLimitsObj'
import getActualWays from '../../services/getWaysData'

import Loader from '../../../../images/loaderBlack.svg'

const { dispatch } = store

const setFiatArray = () => {
    return Object.keys(FiatCurrencyList).map((key) => {
        return {
            code: key,
            value: [
                CurrencyList.get(key, 'en').name,
                CurrencyList.get(key, 'ru').name,
                CurrencyList.get(key, 'uk').name
            ]
        }
    })
}

const Selector = (props) => {
    const { currencyCode, side, setHeight } = props

    const state = store.getState().exchangeStore

    const selectorRef = useRef(null)
    const [isActive, setIsActive] = useDetectOutsideClick(
        selectorRef,
        false,
        () => onSearch('')
    )
    const onClick = () => {
        if ((state.widgetMode || state.externalId) && !state.saveUkraine) return

        if (side === 'OUT' && !state.editOutCurrency) return

        setIsActive(!isActive)
        if (!isActive) {
            if (side === 'PAYWAY') {
                getPaywayList()
            } else {
                isActiveCoin()
            }
        }
    }

    const [width, setWidth] = useState(210)

    useEffect(() => {
        const form = document.getElementsByClassName('exchange-form')[0]
        setWidth(form.offsetWidth - 62)
    }, [])

    const onSearch = (e) => {
        const value = e?.target?.value.toString().toUpperCase().trim() || ''

        if (side === 'IN') {
            dispatch(inCurrencySearchAction(value))
        } else if (side === 'OUT') {
            dispatch(outCurrencySearchAction(value))
        } else if (side === 'PAYWAY') {
            dispatch(paywaySearchAction(value))
        }
    }

    const selectItem = async (side, item) => {
        let paywayIn, paywayOut

        if (side === 'IN') {
            if (JSON.stringify(item) === JSON.stringify(state.inCurrency))
                return

            dispatch(
                inCurrencySelectAction(
                    item,
                    (item.type === 'FIAT' &&
                        state.outCurrency.type === 'CRYPTO') ||
                        (item.type === 'CRYPTO' &&
                            state.outCurrency.type === 'FIAT')
                        ? 'BUY/SELL'
                        : 'EXCHANGE'
                )
            )
            if (item.type === 'FIAT') {
                setHeight('auto')
            }
            if (item.type === 'CRYPTO' && state.outCurrency.type !== 'FIAT')
                setTimeout(() => {
                    setHeight(0)
                }, 200)
            onSearch('')

            const array = state.preRequestExchagneWays.filter(
                (way) =>
                    way.inCurrency.code === item.code &&
                    way.outCurrency.code ===
                        (state.outCurrency && state.outCurrency.code)
            )

            if (item && item.type === 'FIAT') {
                paywayIn = Array.from(
                    new Set(array.map((item) => item.inPaywayCode))
                )
                paywayIn =
                    paywayIn && paywayIn.length === 1
                        ? paywayIn[0]
                        : paywayIn && paywayIn.length
                        ? paywayIn.includes(state.payway)
                            ? state.payway
                            : paywayIn.includes('CARD')
                            ? 'CARD'
                            : paywayIn[0]
                        : null
            } else if (state.outCurrency && state.outCurrency.type === 'FIAT') {
                paywayIn = Array.from(
                    new Set(array.map((item) => item.outPaywayCode))
                )
                paywayIn =
                    paywayIn && paywayIn.length === 1
                        ? paywayIn[0]
                        : paywayIn && paywayIn.length
                        ? paywayIn.includes(state.payway)
                            ? state.payway
                            : paywayIn.includes('CARD')
                            ? 'CARD'
                            : paywayIn[0]
                        : null
            }

            paywayIn =
                item.type === 'CRYPTO' && state.outCurrency.type === 'CRYPTO'
                    ? null
                    : paywayIn
                    ? paywayIn
                    : state.payway

            if (state.payway !== paywayIn) {
                dispatch(paywaySelectAction(paywayIn))
            }

            const allActiveExchangeWays = await getActualWays(
                item,
                state.outCurrency,
                paywayIn,
                state.cashbackToken
            )
            const limits = await getMinMaxLimitsObj(
                allActiveExchangeWays,
                item,
                state.outCurrency,
                paywayIn
            )

            dispatch(activeExchangeWays(allActiveExchangeWays, limits))
        } else if (side === 'OUT') {
            if (JSON.stringify(item) === JSON.stringify(state.outCurrency))
                return

            dispatch(
                outCurrencySelectAction(
                    item,
                    (item.type === 'FIAT' &&
                        state.inCurrency.type === 'CRYPTO') ||
                        (item.type === 'CRYPTO' &&
                            state.inCurrency.type === 'FIAT')
                        ? 'BUY/SELL'
                        : 'EXCHANGE'
                )
            )
            if (item.type === 'FIAT') setHeight('auto')
            if (item.type === 'CRYPTO' && state.inCurrency.type !== 'FIAT')
                setTimeout(() => {
                    setHeight(0)
                }, 200)

            onSearch('')

            const array = state.preRequestExchagneWays.filter(
                (way) =>
                    way.inCurrency.code === state.inCurrency.code &&
                    way.outCurrency.code === item.code
            )

            if (item && item.type === 'FIAT') {
                paywayOut = Array.from(
                    new Set(array.map((item) => item.outPaywayCode))
                )
                paywayOut =
                    paywayOut && paywayOut.length === 1
                        ? paywayOut[0]
                        : paywayOut && paywayOut.length
                        ? paywayOut.includes(state.payway)
                            ? state.payway
                            : paywayOut.includes('CARD')
                            ? 'CARD'
                            : paywayOut[0]
                        : null
            } else if (state.inCurrency && state.inCurrency.type === 'FIAT') {
                paywayOut = Array.from(
                    new Set(array.map((item) => item.inPaywayCode))
                )
                paywayOut =
                    paywayOut && paywayOut.length === 1
                        ? paywayOut[0]
                        : paywayOut && paywayOut.length
                        ? paywayOut.includes(state.payway)
                            ? state.payway
                            : paywayOut.includes('CARD')
                            ? 'CARD'
                            : paywayOut[0]
                        : null
            }

            paywayOut =
                item.type === 'CRYPTO' && state.inCurrency.type === 'CRYPTO'
                    ? null
                    : paywayOut
                    ? paywayOut
                    : state.payway

            if (state.payway !== paywayOut) {
                dispatch(paywaySelectAction(paywayOut))
            }

            const allActiveExchangeWays = await getActualWays(
                state.inCurrency,
                item,
                paywayOut,
                state.cashbackToken
            )
            const limits = await getMinMaxLimitsObj(
                allActiveExchangeWays,
                state.inCurrency,
                item,
                paywayOut
            )
            dispatch(activeExchangeWays(allActiveExchangeWays, limits))
        } else if (side === 'PAYWAY') {
            if (item === state.payway) return

            dispatch(paywaySelectAction(item, null))
            onSearch('')

            const allActiveExchangeWays = await getActualWays(
                state.inCurrency,
                state.outCurrency,
                item,
                state.cashbackToken
            )
            const limits = await getMinMaxLimitsObj(
                allActiveExchangeWays,
                state.inCurrency,
                state.outCurrency,
                item
            )
            dispatch(activeExchangeWays(allActiveExchangeWays, limits))
        }
    }

    const renderBody = () => {
        if (state.loading) {
            return (
                <div className='selector-loading'>
                    <img src={Loader} alt='loading' width={50} />
                </div>
            )
        }

        let searchCryptoArray, searchFiatArray, searchPaywayArray

        if (side !== 'PAYWAY') {
            searchCryptoArray = Object.keys(CryptoCurrencyList).filter((key) =>
                CryptoCurrencyList[key].currencyName
                    .toUpperCase()
                    .includes(state[side.toLowerCase() + 'CurrencySearchValue'])
            )
            searchFiatArray = setFiatArray()
                .filter((item) =>
                    item.value
                        .toString()
                        .toUpperCase()
                        .includes(
                            state[side.toLowerCase() + 'CurrencySearchValue']
                        )
                )
                .map((item) => item.code)
        } else {
            searchPaywayArray = Object.keys(PaywayNameList).filter((key) =>
                PaywayNameList[key]
                    .toString()
                    .toUpperCase()
                    .includes(state.paywaySearchValue)
            )
        }

        switch (side) {
            case 'IN':
                const searhInArray = state.inCurrencySearchValue
                    ? state.activeInCurrency.filter(
                          (item) =>
                              item.code.includes(state.inCurrencySearchValue) ||
                              searchCryptoArray.includes(item.code) ||
                              searchFiatArray.includes(item.code)
                      )
                    : []
                let cryptoInArray, fiatInArray

                cryptoInArray = state.activeInCurrency
                    ? state.activeInCurrency.filter((item) =>
                          state.inCurrency.type === 'FIAT'
                              ? item.type === 'FIAT'
                              : item.type === 'CRYPTO'
                      )
                    : []
                fiatInArray = state.activeInCurrency
                    ? state.activeInCurrency.filter((item) =>
                          state.inCurrency.type === 'FIAT'
                              ? item.type === 'CRYPTO'
                              : item.type === 'FIAT'
                      )
                    : []

                return (
                    <>
                        {state.inCurrencySearchValue ? (
                            searhInArray &&
                            searhInArray.map((item, index) => {
                                return (
                                    <CoinListItem
                                        selected={
                                            JSON.stringify(state.inCurrency) ===
                                            JSON.stringify(item)
                                        }
                                        key={item.code}
                                        item={item}
                                        side={side}
                                        isActive={isActive}
                                        selectItem={selectItem}
                                        setIsActive={setIsActive}
                                        line={searhInArray.length !== index + 1}
                                        setHeight={setHeight}
                                    />
                                )
                            })
                        ) : (
                            <>
                                <div className='currency-type'>
                                    {state?.inCurrency?.type === 'FIAT'
                                        ? 'FIAT'
                                        : 'CRYPTO'}
                                </div>
                                {cryptoInArray &&
                                    cryptoInArray.map((item, index) => {
                                        return (
                                            <CoinListItem
                                                selected={
                                                    JSON.stringify(
                                                        state.inCurrency
                                                    ) === JSON.stringify(item)
                                                }
                                                key={item.code}
                                                item={item}
                                                side={side}
                                                isActive={isActive}
                                                selectItem={selectItem}
                                                setIsActive={setIsActive}
                                                line={
                                                    cryptoInArray.length !==
                                                    index + 1
                                                }
                                                setHeight={setHeight}
                                            />
                                        )
                                    })}
                                <div className='currency-type'>
                                    {state?.inCurrency?.type === 'FIAT'
                                        ? 'CRYPTO'
                                        : 'FIAT'}
                                </div>
                                {fiatInArray &&
                                    fiatInArray.map((item, index) => {
                                        return (
                                            <CoinListItem
                                                selected={
                                                    JSON.stringify(
                                                        state.inCurrency
                                                    ) === JSON.stringify(item)
                                                }
                                                key={item.code}
                                                item={item}
                                                side={side}
                                                isActive={isActive}
                                                selectItem={selectItem}
                                                setIsActive={setIsActive}
                                                line={
                                                    fiatInArray.length !==
                                                    index + 1
                                                }
                                                setHeight={setHeight}
                                            />
                                        )
                                    })}
                            </>
                        )}
                    </>
                )
            case 'OUT':
                const searhOutArray = state.outCurrencySearchValue
                    ? state.activeOutCurrency.filter(
                          (item) =>
                              item.code.includes(
                                  state.outCurrencySearchValue
                              ) ||
                              searchCryptoArray.includes(item.code) ||
                              searchFiatArray.includes(item.code)
                      )
                    : []
                let cryptoOutArray, fiatOutArray

                cryptoOutArray = state.activeOutCurrency
                    ? state.activeOutCurrency.filter((item) =>
                          state.outCurrency.type === 'FIAT'
                              ? item.type === 'FIAT'
                              : item.type === 'CRYPTO'
                      )
                    : []
                fiatOutArray = state.activeOutCurrency
                    ? state.activeOutCurrency.filter((item) =>
                          state.outCurrency.type === 'FIAT'
                              ? item.type === 'CRYPTO'
                              : item.type === 'FIAT'
                      )
                    : []

                return (
                    <>
                        {state.outCurrencySearchValue ? (
                            searhOutArray &&
                            searhOutArray.map((item, index) => {
                                return (
                                    <CoinListItem
                                        selected={
                                            JSON.stringify(
                                                state.outCurrency
                                            ) === JSON.stringify(item)
                                        }
                                        key={item.code}
                                        item={item}
                                        side={side}
                                        isActive={isActive}
                                        selectItem={selectItem}
                                        setIsActive={setIsActive}
                                        line={
                                            searhOutArray.length !== index + 1
                                        }
                                        setHeight={setHeight}
                                    />
                                )
                            })
                        ) : (
                            <>
                                <div className='currency-type'>
                                    {state?.outCurrency?.type === 'FIAT'
                                        ? 'FIAT'
                                        : 'CRYPTO'}
                                </div>
                                {cryptoOutArray &&
                                    cryptoOutArray.map((item, index) => {
                                        return (
                                            <CoinListItem
                                                selected={
                                                    JSON.stringify(
                                                        state.outCurrency
                                                    ) === JSON.stringify(item)
                                                }
                                                key={item.code}
                                                item={item}
                                                side={side}
                                                isActive={isActive}
                                                selectItem={selectItem}
                                                setIsActive={setIsActive}
                                                line={
                                                    cryptoOutArray.length !==
                                                    index + 1
                                                }
                                                setHeight={setHeight}
                                            />
                                        )
                                    })}
                                <div className='currency-type'>
                                    {state?.outCurrency?.type === 'FIAT'
                                        ? 'CRYPTO'
                                        : 'FIAT'}
                                </div>
                                {fiatOutArray &&
                                    fiatOutArray.map((item, index) => {
                                        return (
                                            <CoinListItem
                                                selected={
                                                    JSON.stringify(
                                                        state.outCurrency
                                                    ) === JSON.stringify(item)
                                                }
                                                key={item.code}
                                                item={item}
                                                side={side}
                                                isActive={isActive}
                                                selectItem={selectItem}
                                                setIsActive={setIsActive}
                                                line={
                                                    fiatOutArray.length !==
                                                    index + 1
                                                }
                                                setHeight={setHeight}
                                            />
                                        )
                                    })}
                            </>
                        )}
                    </>
                )
            case 'PAYWAY':
                const searchPayway = state.paywayList
                    ? state.paywaySearchValue
                        ? state.paywayList.filter(
                              (item) =>
                                  item.includes(state.paywaySearchValue) ||
                                  searchPaywayArray.includes(
                                      state.paywaySearchValue
                                  )
                          )
                        : state.paywayList || []
                    : []
                return (
                    <>
                        {searchPayway.map((item, index) => {
                            return (
                                <PaywayListItem
                                    selected={state.payway === item}
                                    key={item}
                                    item={item}
                                    side={side}
                                    isActive={isActive}
                                    selectItem={selectItem}
                                    setIsActive={setIsActive}
                                    line={searchPayway.length !== index + 1}
                                />
                            )
                        })}
                    </>
                )
            default:
                return null
        }
    }

    const getSelectorIcon = () => {
        switch (side) {
            case 'IN':
            case 'OUT':
                return (
                    <>
                        {currencyCode?.type === 'CRYPTO' ? (
                            <div className='coin-icon'>
                                <CryptoMonoCurrencyIcon
                                    currencyCode={currencyCode?.code || ''}
                                />
                                {isToken(currencyCode?.code || '') ? (
                                    <div className='token-icon'>
                                        <CryptoMonoCurrencyIcon
                                            currencyCode={getTokenNet(
                                                currencyCode?.code || ''
                                            )}
                                            style={{ fontSize: 32 }}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        ) : currencyCode?.type === 'FIAT' ? (
                            <FiatMonoCurrencyIcon
                                currencyCode={currencyCode?.code || ''}
                            />
                        ) : null}
                    </>
                )
            case 'PAYWAY':
                return (
                    <>
                        {state.payway && (
                            <PaywayIcon paywayCode={state.payway} size={24} />
                        )}
                    </>
                )
            default:
                return null
        }
    }

    const inputValue =
        side === 'IN'
            ? state.inCurrencySearchValue
            : side === 'OUT'
            ? state.outCurrencySearchValue
            : side === 'PAYWAY'
            ? state.paywaySearchValue
            : ''

    return (
        <SelectorDiv>
            <div className='selector-container'>
                <div
                    className={`selector-btn ${
                        state.widgetMode ? 'widget' : ''
                    }`}
                    onClick={onClick}
                >
                    <div style={{ paddingRight: 6 }}>{getSelectorIcon()}</div>
                    {currencyCode?.symbol || ''}
                    {!state.widgetMode || state.saveUkraine ? (
                        <Icon icon='ARROW_DOWN' size={24} color='#404040' />
                    ) : null}
                </div>
                <nav
                    ref={selectorRef}
                    className={`selector ${state.widgetMode ? 'widget' : ''} ${
                        isActive ? 'active' : 'inactive'
                    }`}
                    style={{ width: width }}
                >
                    <div
                        className='selector-header'
                        style={{ width: width - 32 }}
                    >
                        <div className='selector-header-input'>
                            <Input
                                placeholder={strings('exchangeForm.search')}
                                value={inputValue}
                                onChange={onSearch}
                                searchIcon
                                condition
                                rightType='search'
                            />
                        </div>
                    </div>
                    <div className='selector-body'>{renderBody()}</div>
                </nav>
            </div>
        </SelectorDiv>
    )
}

export default Selector
