import React from 'react'
import MonitoringDiv from './style.css'

import bitsmedia from '../../../images/listing/88x31(2).png'
// import kurs from '../../../images/listing/log.png'
import obmenRu from '../../../images/bannerImage/inform5.gif'

const Monitoring = () => {
    return (
        <MonitoringDiv>
            <a
                href='https://kurs.com.ua/forums/topic/4917-trustee-swap/'
                title='Мониторинг обменников электронных валют'
                target='_blank'
                rel='noopener noreferrer'
            >
                <img
                    src='https://kurs.com.ua/static/images/banner/88x31.png'
                    width='88'
                    height='31'
                    alt='kurs.com.ua'
                />
            </a>
            <a
                href='https://eobmen-obmen.ru/trustee-swap-detali'
                title='Мониторинг обменников eobmen-obmen.ru'
                target='_blank'
                rel='noopener noreferrer'
            >
                <img src={obmenRu} alt='eobmen-obmen.ru - просто сравни курcы' />
            </a>
            <a
                href='https://bits.media/exchanger/trustee-swap/'
                target='_blank'
                rel='noopener noreferrer'
            >
                <img src={bitsmedia} alt='bitsmedia' />
            </a>
            {/* <a 
                href='https://kurs.expert'
                title='Обмен Биткоин, Киви, Яндекс, AdvCash'
                target='_blank'
                rel='noopener noreferrer'
            >
                <img src={kurs} width='31' height='31' border='0' alt='https://kurs.expert' />
            </a> */}
        </MonitoringDiv>
    )
}

export default Monitoring
