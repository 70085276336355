import React from 'react'
import {
    CryptoCurrencyIcon,
    FiatCurrencyIcon,
    getCurrencyName
} from 'trustee-swap-assets'

import { capitalize } from '../../../services/capitalize'

const CoinListItem = (props) => {
    const { item, side, selectItem, setIsActive, isActive, selected, line } =
        props

    return (
        <div>
            <div
                className={`selector-item ${selected ? 'select' : ''}`}
                onClick={async () => {
                    setIsActive(!isActive)
                    await selectItem(side, item)
                }}
            >
                <div className='selector-item-container'>
                    <div className='icon-wrapper'>
                        {item.type === 'CRYPTO' ? (
                            <CryptoCurrencyIcon
                                currencyCode={item.code}
                                colorNotActive={item.notActive ? '#A1A1A1' : ''}
                            />
                        ) : (
                            <FiatCurrencyIcon
                                currencyCode={item.code}
                                colorNotActive={item.notActive ? '#A1A1A1' : ''}
                            />
                        )}
                    </div>
                    <div
                        className={`selector-item-text ${
                            item.notActive && 'notActive'
                        }`}
                    >
                        {item.type === 'FIAT'
                            ? capitalize(
                                  getCurrencyName(
                                      item.code,
                                      localStorage.getItem('locale')
                                  ) || item.name
                              )
                            : getCurrencyName(
                                  item.code,
                                  localStorage.getItem('locale')
                              ) || item.name}
                    </div>
                </div>
                <div
                    className={`selector-item-code ${
                        item.notActive && 'notActive'
                    }`}
                >
                    {item.symbol}
                </div>
            </div>
            {line ? <div className='selector-line' /> : null}
        </div>
    )
}

export default CoinListItem
