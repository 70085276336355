import config from '../../../config/config'
import { serverRequest } from '../../../services/request'

const getActualWays = async (
    inCurrencyCode,
    outCurrencyCode,
    payway,
    cashbackToken
) => {
    if (!inCurrencyCode || !outCurrencyCode || !cashbackToken) return []

    let needDataAllLink = `${config.backendUrl}/data/all?inCurrencyCode=${inCurrencyCode.code}&outCurrencyCode=${outCurrencyCode.code}&destination=${config.destination}`
    // &cashbackToken=${cashbackToken}
    if ((inCurrencyCode.type === 'FIAT' || outCurrencyCode.type === 'FIAT') && payway) {
        needDataAllLink += `&paywayCode=${payway}`
    }

    let [dataAll] = await Promise.all([serverRequest(needDataAllLink, 'DATA')])

    dataAll =
        dataAll && dataAll.length
            ? dataAll.map((way) =>
                  way.exchangeWayType === 'BUY' &&
                  way.inPaywayCode.indexOf('VISA_MC') !== -1
                      ? { ...way, inPaywayCode: 'CARD' }
                      : way.exchangeWayType === 'SELL' &&
                        way.outPaywayCode.indexOf('VISA_MC') !== -1
                      ? { ...way, outPaywayCode: 'CARD' }
                      : way
              )
            : []

    dataAll =
        dataAll && dataAll.length
            ? dataAll.filter(
                  (way) =>
                      config.availableRate.includes(way.rateType) &&
                      !config.disabledProviders.includes(way.provider)
              )
            : []

    if (
        (inCurrencyCode.type === 'FIAT' || outCurrencyCode.type === 'FIAT') &&
        !payway
    ) {
        return []
    }

    return dataAll
}

export default getActualWays
