import styled from 'styled-components'

const InputAndSelectorsDiv = styled.div`
    width: 100%;

    .amount-inputs {
        display: flex;
        flex-direction: column;

        position: relative;

        width: 100%;

        z-index: 100;
    }

    .amount-inputs-label {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        /* padding-left: 20px; */
        padding-bottom: 0.353em;

        height: 2.94em;
        width: 45%;

        max-width: 13.4em;

        font-family: Fira Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1em;
        line-height: 120%;

        color: #5c5c5c;

        background: #e8e8e8;
        /* background: brown; */
        border-radius: 2.94em;

        &.widget {
            background: #e0e0ea;
            color: #808080;
        }

        &.saveUkraine {
            width: 100%;
            max-width: 100%;
        }
    }

    .amount-inputs-in-wrap,
    .amount-inputs-out-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        background: #f5f5f5;
        border-radius: 0.823em;

        border: 0.058em solid #f5f5f5;

        height: 50px;
        width: 100%;
        /* padding-right: 1px; */

        &.wrap-error {
            border: 0.058em solid #f1e1b5;
            /* max-width: 30em; */

            z-index: 200;
        }
    }

    .amount-inputs-input {
        background: #f5f5f5;
        border-radius: 0.823em;

        height: 50px;
        width: calc(100% - 9.76em);

        border: none;
        outline: none;

        padding: 0 1.17em;

        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 1.06em;
        line-height: 120%;

        color: #404040;
    }

    .amount-inputs-input::placeholder {
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 1.06em;
        line-height: 120%;

        color: #999999;
    }

    .amount-inputs-swap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        width: 100%;
        background: #f5f5f5;
        border-radius: 2.94em;
    }

    .amount-inputs-swap-btn {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 2.47em;
        height: 2.47em;

        background-color: rgba(134, 77, 217, 0.07);
        border-radius: 0.82em;

        margin: 0 0.35em;

        cursor: pointer;
    }

    .amount-inputs-swap-btn-icon {
        &.rotate-animation {
            transform: rotate(180deg);
            transition: all ease-out 0.25s;
        }
    }

    .amount-input-limits {
        display: flex;
        align-items: center;

        width: 100%;
        height: 2.94em;

        background: linear-gradient(0deg, #f1e1b5, #f1e1b5);
        border-radius: 0 0 0.82em 0.82em;

        border: 1px solid #f1e1b5;

        margin-top: -0.82em;

        position: absolute;
        top: 50%;

        &.out {
            top: 100%;
        }
    }

    .amount-input-limits-text {
        margin-top: 0.588em;
        margin-left: 1.176em;

        display: flex;
        flex-direction: row;
        font-family: Fira Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1em;
        line-height: 120%;

        color: rgba(186, 137, 10, 0.9);

        cursor: pointer;
    }

    .amount-input-limits-value {
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 1em;
        line-height: 120%;
        /* identical to box height, or 129% */

        text-align: center;
        letter-spacing: 0.12em;
        text-decoration-line: underline;
        text-transform: uppercase;

        color: rgba(186, 137, 10, 0.9);

        margin-left: 0.412em;
    }

    @media screen and (max-width: 640px) {
        .amount-inputs-label {
            height: 44px;
            padding-bottom: 2px;
        }

        .amount-inputs-swap-btn {
            width: 42px;
            height: 42px;
        }
    }
`

export default InputAndSelectorsDiv
