import React, { useEffect } from 'react'
import queryString from 'query-string'

import Icon from '../../../../components/Icon/Icon'
import Selector from '../Selector/Selector'
import InputAndSelectorsDiv from './style.css'

import { strings } from '../../../../i18n'
import store from '../../../../redux'
import {
    activeExchangeWays,
    amountAction,
    errorAction,
    swapCurrencyAction
} from '../../../../redux/stores/exchangeStore/action'
import getEquivalentData from '../../services/getEquivalentData'

import getActualWays from '../../services/getWaysData'
import getMinMaxLimitsObj from '../../services/getMinMaxLimitsObj'

import Loader from '../../../../images/loaderBlack.svg'

const { dispatch } = store

const amountInputHandler = async (event, side, reCaptchaRef, setAmountLoad) => {
    const state = store.getState().exchangeStore

    if (!state.inCurrency || !state.outCurrency) return

    if (state.amountTimerId) {
        clearTimeout(state.amountTimerId)
    }

    let value
    try {
        value = event.target.value
    } catch {
        value = event
    }
    value = value?.toString()?.replace(/,/g, '.') || value

    if (state.inCurrency && state.inCurrency.type === 'FIAT' && side === 'IN') {
        if (value.indexOf('.') !== -1 && value.length - value.indexOf('.') > 3)
            return
    } else if (
        state.outCurrency &&
        state.outCurrency.type === 'FIAT' &&
        side === 'OUT'
    ) {
        if (value.indexOf('.') !== -1 && value.length - value.indexOf('.') > 3)
            return
    }

    const amount = parseFloat(value) || value

    if (!amount) {
        dispatch(amountAction(null, side, null, false))
        if (state.externalId) setAmountLoad(false)
        return
    }

    if (
        state.inCurrency &&
        state.outCurrency &&
        (state.inCurrency.type === 'FIAT' ||
            state.outCurrency.type === 'FIAT') &&
        !state.payway
    ) {
        dispatch(errorAction(null, null, true))
        if (state.externalId) setAmountLoad(false)
        return
    }

    dispatch(amountAction(amount, side, null, false))

    if (side === 'IN') {
        if (state.outCurrency.type === 'FIAT') {
            if (amount <= 0) return
        } else {
            if (amount < state.limits.min || amount > state.limits.max) {
                dispatch(errorAction(true, null, null))
                if (state.externalId) setAmountLoad(false)
                return
            }
        }
    } else if (side === 'OUT') {
        if (state.outCurrency.type === 'FIAT') {
            if (amount < state.limits.min || amount > state.limits.max) {
                dispatch(errorAction(null, true, null))
                if (state.externalId) setAmountLoad(false)
                return
            }
        } else if (amount <= 0) return
    }

    dispatch(errorAction(null, null, null))

    const timerId = setTimeout(async () => {
        reCaptchaRef &&
            typeof reCaptchaRef.current !== 'undefined' &&
            reCaptchaRef.current &&
            reCaptchaRef.current.reset()
        const captchaToken = await reCaptchaRef.current.executeAsync()

        const equivalentData = await getEquivalentData(
            state,
            amount,
            captchaToken,
            side
        )
        if (state.externalId) setAmountLoad(false)

        reCaptchaRef &&
            typeof reCaptchaRef.current !== 'undefined' &&
            reCaptchaRef.current.reset()

        if (equivalentData?.errorCode === 'EXCEEDING_LIMITS') {
            dispatch(errorAction(true, null, null, equivalentData))
            dispatch(amountAction(amount, side, null, true))
            return
        }

        if (!equivalentData) {
            dispatch(amountAction(amount, side, null, true))
            return
        }

        dispatch(amountAction(amount, side, null, false, equivalentData))
    }, 1e3) // 1 sec

    dispatch(amountAction(amount, side, timerId))
}

const InputsAndSelectors = (props) => {
    const state = store.getState().exchangeStore

    const { reCaptchaRef, setHeight, setAmountLoad } = props

    useEffect(() => {
        const url = window.location.href
        const urlParamsString = url.slice(url.indexOf('?') + 1)
        const urlParamsObj = queryString.parse(urlParamsString)

        if (urlParamsObj.amount) {
            if (state.externalId) setAmountLoad(true)
            amountInputHandler(
                state.amount,
                urlParamsObj.amountSide || 'IN',
                reCaptchaRef,
                setAmountLoad
            )
        }

        return () => {}
    }, [])

    const swapCoin = async () => {
        dispatch(swapCurrencyAction())

        const btn = document.getElementsByClassName(
            'amount-inputs-swap-btn-icon'
        )[0]
        btn.classList.add('rotate-animation')
        setTimeout(() => {
            btn.classList.remove('rotate-animation')
        }, 250)

        const allActiveExchangeWays = await getActualWays(
            state.outCurrency,
            state.inCurrency,
            state.payway,
            state.cashbackToken
        )
        const limits = await getMinMaxLimitsObj(
            allActiveExchangeWays,
            state.outCurrency,
            state.inCurrency,
            state.payway
        )
        dispatch(activeExchangeWays(allActiveExchangeWays, limits))
    }

    return (
        <InputAndSelectorsDiv>
            <div className='amount-inputs'>
                <div
                    className={`amount-inputs-label ${
                        state.widgetMode ? 'widget' : ''
                    }`}
                >
                    <Icon
                        icon='ARROW_SEND'
                        color={state.widgetMode ? '#808080' : '#404040'}
                        size={18}
                        style={{ paddingRight: 6, paddingLeft: 20 }}
                    />
                    {strings('exchangeForm.youGive')}
                </div>
                <div
                    className={`amount-inputs-in-wrap ${
                        (state.inAmountError || state.amountNotValid) &&
                        'wrap-error'
                    }`}
                >
                    {state.amountTimerId && state.amountSide === 'OUT' ? (
                        <img
                            src={Loader}
                            alt='loader'
                            width={40}
                            style={{ marginLeft: 10 }}
                        />
                    ) : (
                        <input
                            className={`amount-inputs-input ${
                                (state.inAmountError || state.amountNotValid) &&
                                'input-error'
                            }`}
                            placeholder='0.00'
                            type='number'
                            inputMode='decimal'
                            maxLength={17}
                            autoComplete='off'
                            autoFocus={false}
                            onChange={(e) =>
                                amountInputHandler(e, 'IN', reCaptchaRef)
                            }
                            // onBlur={() => dispatch(errorAction(null, null, null))}
                            value={
                                state.amountSide === 'IN'
                                    ? state.amount
                                    : state.amountSide === 'OUT'
                                    ? state?.equivalentData?.equivalentData
                                          ?.inAmount
                                    : ''
                            }
                            readOnly={!state.editAmount}
                            onWheel={(e) => e.target.blur()}
                        />
                    )}
                    <div>
                        <Selector
                            currencyCode={state.inCurrency}
                            side='IN'
                            state={state}
                            setHeight={setHeight}
                        />
                    </div>
                </div>
                {state.amountNotValid ? (
                    <div
                        className={`amount-input-limits ${
                            state.externalId ? 'out' : ''
                        }`}
                    >
                        <div className='amount-input-limits-text'>
                            {strings('exchangeForm.notEquivalent')}
                        </div>
                    </div>
                ) : null}
                {state.equivalentError ? (
                    <div
                        className={`amount-input-limits ${
                            state.externalId ? 'out' : ''
                        }`}
                    >
                        <div className='amount-input-limits-text'>
                            {state.equivalentError.type === 'MIN'
                                ? strings('exchangeForm.minAmount') + ' '
                                : strings('exchangeForm.maxAmount') + ' '}
                            <div
                                className='amount-input-limits-value'
                                onClick={() =>
                                    amountInputHandler(
                                        state.equivalentError.limitValue,
                                        state.equivalentError.currencySide,
                                        reCaptchaRef
                                    )
                                }
                            >
                                {`${state.equivalentError.limitValue} ${
                                    state.equivalentError.currencySide === 'IN'
                                        ? state.inCurrency.symbol
                                        : state.outCurrency.symbol
                                }`}
                            </div>
                        </div>
                    </div>
                ) : state.inAmountError ? (
                    <div
                        className={`amount-input-limits ${
                            state.externalId ? 'out' : ''
                        }`}
                    >
                        <div className='amount-input-limits-text'>
                            {state.amount < state.limits.min ? (
                                <>
                                    {strings('exchangeForm.minAmount') + ' '}
                                    <div
                                        className='amount-input-limits-value'
                                        onClick={() =>
                                            amountInputHandler(
                                                state.limits.min,
                                                'IN',
                                                reCaptchaRef
                                            )
                                        }
                                    >
                                        {`${state.limits.min} ${
                                            state.limits.currencySide === 'IN'
                                                ? state.inCurrency.symbol
                                                : state.outCurrency.symbol
                                        }`}
                                    </div>
                                </>
                            ) : state.amount > state.limits.max ? (
                                <>
                                    {strings('exchangeForm.maxAmount') + ' '}
                                    <div
                                        className='amount-input-limits-value'
                                        onClick={() =>
                                            amountInputHandler(
                                                state.limits.max,
                                                'IN',
                                                reCaptchaRef
                                            )
                                        }
                                    >
                                        {`${state.limits.max} ${
                                            state.limits.currencySide === 'IN'
                                                ? state.inCurrency.symbol
                                                : state.outCurrency.symbol
                                        }`}
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </div>
                ) : null}
                {!state.externalId && (
                    <>
                        <div className='amount-inputs-swap'>
                            <div
                                className={`amount-inputs-label ${
                                    state.widgetMode && state.saveUkraine
                                        ? 'widget saveUkraine'
                                        : state.saveUkraine
                                        ? 'saveUkraine'
                                        : state.widgetMode
                                        ? 'widget'
                                        : ''
                                }`}
                            >
                                <Icon
                                    icon='ARROW_RECEIVE'
                                    color={
                                        state.widgetMode ? '#808080' : '#404040'
                                    }
                                    size={18}
                                    style={{ paddingRight: 6, paddingLeft: 20 }}
                                />
                                {strings('exchangeForm.youGet')}
                            </div>
                            {!state.saveUkraine && (
                                <>
                                    <div
                                        className='amount-inputs-swap-btn'
                                        onClick={async () => swapCoin()}
                                    >
                                        <Icon
                                            icon='ARROW_SWAP'
                                            size={24}
                                            className='amount-inputs-swap-btn-icon'
                                            color='#864DD9'
                                        />
                                    </div>
                                    <div
                                        className={`amount-inputs-label ${
                                            state.widgetMode ? 'widget' : ''
                                        }`}
                                    />
                                </>
                            )}
                        </div>
                        <div
                            className={`amount-inputs-out-wrap ${
                                state.outAmountError && 'wrap-error'
                            }`}
                            style={{ zIndex: state.outAmountError ? 200 : 0 }}
                        >
                            {state.amountTimerId &&
                            state.amountSide === 'IN' ? (
                                <img
                                    src={Loader}
                                    alt='loader'
                                    width={40}
                                    style={{ marginLeft: 10 }}
                                />
                            ) : (
                                <input
                                    className={`amount-inputs-input ${
                                        state.outAmountError && 'input-error'
                                    }`}
                                    placeholder='0.00'
                                    type='number'
                                    inputMode='decimal'
                                    maxLength={17}
                                    autoComplete='off'
                                    autoFocus={false}
                                    onChange={(e) =>
                                        amountInputHandler(
                                            e,
                                            'OUT',
                                            reCaptchaRef
                                        )
                                    }
                                    // onBlur={() => dispatch(errorAction(null, null, null))}
                                    value={
                                        state.amountSide === 'OUT'
                                            ? state.amount
                                            : state.amountSide === 'IN'
                                            ? state?.equivalentData
                                                  ?.equivalentData?.outAmount
                                            : ''
                                    }
                                    readOnly={!state.editAmount}
                                    onWheel={(e) => e.target.blur()}
                                />
                            )}
                            <div>
                                <Selector
                                    currencyCode={state.outCurrency}
                                    side='OUT'
                                    state={state}
                                    setHeight={setHeight}
                                />
                            </div>
                        </div>
                        {state.outAmountError && (
                            <div className='amount-input-limits out'>
                                <div className='amount-input-limits-text'>
                                    {state.amount < state.limits.min ? (
                                        <>
                                            {strings('exchangeForm.minAmount') +
                                                ' '}
                                            <div
                                                className='amount-input-limits-value'
                                                onClick={() =>
                                                    amountInputHandler(
                                                        state.limits.min,
                                                        'OUT',
                                                        reCaptchaRef
                                                    )
                                                }
                                            >
                                                {`${state.limits.min} ${
                                                    state.limits
                                                        .currencySide === 'IN'
                                                        ? state.inCurrency
                                                              .symbol
                                                        : state.outCurrency
                                                              .symbol
                                                }`}
                                            </div>
                                        </>
                                    ) : state.amount > state.limits.max ? (
                                        <>
                                            {strings('exchangeForm.maxAmount') +
                                                ' '}
                                            <div
                                                className='amount-input-limits-value'
                                                onClick={() =>
                                                    amountInputHandler(
                                                        state.limits.max,
                                                        'OUT',
                                                        reCaptchaRef
                                                    )
                                                }
                                            >
                                                {`${state.limits.max} ${
                                                    state.limits
                                                        .currencySide === 'IN'
                                                        ? state.inCurrency
                                                              .symbol
                                                        : state.outCurrency
                                                              .symbol
                                                }`}
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </InputAndSelectorsDiv>
    )
}

export default InputsAndSelectors
