import { strings } from '../../../i18n'

export default function prettyStatus(status) {
    switch (status.toUpperCase()) {
        case 'PENDING_PAYIN':
            return strings('orderInfo.pending_payin')
        case 'DONE_PAYIN':
            return strings('orderInfo.done_payin')
        case 'WAIT_TRADE':
        case 'DONE_TRADE':
            return strings('orderInfo.done_trade')
        case 'WAIT_PAYOUT':
            return strings('orderInfo.wait_payout')
        case 'DONE_PAYOUT':
            return strings('orderInfo.done_payout')
        case 'CANCELED_PAYIN':
            return strings('orderInfo.canceled_payin')
        case 'ERROR_ORDER':
            return strings('orderInfo.error_order')
        case 'WAIT_KYC':
            return strings('orderInfo.wait_kyc')
        case 'NOT_ENTIRE_WITHDRAW':
            return strings('orderInfo.not_entire_withdraw')
        default:
            return null
    }
}
