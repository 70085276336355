import React, { useState, useEffect } from 'react'
import queryString from 'query-string'

import OrderInfoContainer from './style.css'
import config from '../../config/config'
import { axiosRequest } from '../../services/request'
import OrderBlock from './OrderBlock/OrderBlock'
import { strings } from '../../i18n'

import BG_desktop from '../../images/bgPicture/bgETransactionTracking_desktop.svg'
import BG_tablet from '../../images/bgPicture/bgETransactionTracking_tablet.svg'
import BG_mobile from '../../images/bgPicture/bgETransactionTracking_mobile.svg'
import CarouselCompoent from '../../components/Carousel/Carousel'
import InfoMessage from '../../components/InfoMessage/InfoMessage'
import getQrValue from './service/getQrValue'

const OrderInfo = () => {
    const [data, setData] = useState(null)
    const [widgetMode, setWidgetMode] = useState(null)
    const [saveUkraineModa, setSaveUkraineMode] = useState(null)

    const getOrderData = async () => {
        const url = window.location.href
        const urlParamsString = url.slice(url.indexOf('?') + 1)
        const urlParamsObj = queryString.parse(urlParamsString)
        const widget = urlParamsObj?.widgetMode
            ? JSON.parse(urlParamsObj?.widgetMode)
            : false
        const locale = urlParamsObj?.locale || false
        const saveUkraine = urlParamsObj?.saveUkraine
            ? JSON.parse(urlParamsObj?.saveUkraine)
            : false

        setWidgetMode(widget)
        setSaveUkraineMode(saveUkraine)

        if (locale) {
            localStorage.setItem('locale', locale)
        }

        let timerId = setTimeout(async function getOrderInfo() {
            const link = `${config.backendUrl}/order/order-info?orderHash=${urlParamsObj.orderHash}`
            try {
                let orderInfo = await axiosRequest(link)
                orderInfo.valueQR = orderInfo
                    ? orderInfo?.exchangeWayType !== 'BUY'
                        ? await getQrValue(
                              orderInfo?.inCurrencySymbol,
                              orderInfo?.depositAddress,
                              orderInfo?.inAmount
                          )
                        : ''
                    : ''
                setData(orderInfo)

                if (orderInfo.orderStatus !== 'DONE_PAYOUT') {
                    setTimeout(getOrderInfo, 15000) // 15 sec
                } else {
                    clearTimeout(timerId)
                }
            } catch (error) {
                console.log(error)
                setTimeout(getOrderInfo, 5000) // 5 sec
            }
        }, 0) // 0 sec
    }

    useEffect(() => {
        getOrderData()
        window.scroll(0, 0)

        return () => {}
    }, [])

    return (
        <OrderInfoContainer
            bgImageDesktop={BG_desktop}
            bgImageTablet={BG_tablet}
            bgImageMobile={BG_mobile}
        >
            <div className='order-wrap'>
                {!widgetMode ? (
                    saveUkraineModa ? (
                        <div className='message-one'>
                            <InfoMessage />
                        </div>
                    ) : (
                        <div className='order-tracking'>
                            <p>{strings('orderInfo.orderTracking')}</p>
                            <div className='createvies-one'>
                                <CarouselCompoent
                                    random
                                    locale={localStorage.getItem('locale')}
                                />
                            </div>
                        </div>
                    )
                ) : null}
                <OrderBlock data={data} />
                {saveUkraineModa ? (
                    <div className='message-two'>
                        <InfoMessage />
                    </div>
                ) : (
                    <div className='createvies-two'>
                        <CarouselCompoent
                            random
                            locale={localStorage.getItem('locale')}
                        />
                    </div>
                )}
            </div>
        </OrderInfoContainer>
    )
}

export default React.memo(OrderInfo)
