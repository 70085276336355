import {
    EXCHANGE_WAY,
    CURRENCY_COIN_ACTION,
    SWAP_COIN,
    IN_CURRENCY_SELECT,
    OUT_CURRENCY_SELECT,
    IN_CURRENCY_SEARCH,
    OUT_CURRENCY_SEARCH,
    PAYWAY_SEARCH,
    PAYWAY_LIST_ACTION,
    PAYWAY_ACTION,
    OUT_DESTINATION_ACTION,
    EMAIL_ACTION,
    PAYMENT_DATA_ACTION,
    UNIQUE_PARAMS_VALID,
    AMOUNT_ACTION,
    ERROR_ACTION,
    EXCHANGE_WAYS_ACTION,
    FILE_INPUT_ACTION,
    UPLOAD_CARD_PHOTO_ACTION,
    CARD_PHOTO_ACTION,
    CARD_VERIFY_ACTION,
    LOADER_ACTION,
    OUT_DESTINATION_TAG_ACTION,
    WIDGET_MODE,
    RESET_DATA,
    EXTERNAL_ID_ACTION,
    SAVE_UKRAINE_ACTION
} from './type'

export function setExchangeWayAction(
    exchangeWay,
    inCurrency,
    outCurrency,
    payway
) {
    return {
        type: EXCHANGE_WAY,
        exchangeWay,
        inCurrency,
        outCurrency,
        payway
    }
}

export function currencyCoinAction(activeInCurrency, activeOutCurrency) {
    return {
        type: CURRENCY_COIN_ACTION,
        activeInCurrency,
        activeOutCurrency
    }
}

export function swapCurrencyAction() {
    return {
        type: SWAP_COIN
    }
}

export function inCurrencySelectAction(inCurrency, exchangeWay) {
    return {
        type: IN_CURRENCY_SELECT,
        inCurrency,
        exchangeWay
    }
}

export function outCurrencySelectAction(outCurrency, exchangeWay) {
    return {
        type: OUT_CURRENCY_SELECT,
        outCurrency,
        exchangeWay
    }
}

export function inCurrencySearchAction(inCurrencySearchValue) {
    return {
        type: IN_CURRENCY_SEARCH,
        inCurrencySearchValue
    }
}

export function outCurrencySearchAction(outCurrencySearchValue) {
    return {
        type: OUT_CURRENCY_SEARCH,
        outCurrencySearchValue
    }
}

export function paywaySearchAction(paywaySearchValue) {
    return {
        type: PAYWAY_SEARCH,
        paywaySearchValue
    }
}

export function setPaywayListAction(paywayList) {
    return {
        type: PAYWAY_LIST_ACTION,
        paywayList
    }
}

export function paywaySelectAction(payway, paymentData = null) {
    return {
        type: PAYWAY_ACTION,
        payway,
        paymentData
    }
}

export function outDestinationAction(outDestination) {
    return {
        type: OUT_DESTINATION_ACTION,
        outDestination
    }
}

export function emailAction(email) {
    return {
        type: EMAIL_ACTION,
        email
    }
}

export function paymenDataAction(paymentData) {
    return {
        type: PAYMENT_DATA_ACTION,
        paymentData
    }
}

export function qniqueParamsAction(
    outDestinationNotValid,
    emailNotValid,
    paymentDataNotValid
) {
    return {
        type: UNIQUE_PARAMS_VALID,
        outDestinationNotValid,
        emailNotValid,
        paymentDataNotValid
    }
}

export function amountAction(
    amount,
    amountSide,
    TimerId = null,
    amountNotValid = false,
    equivalentData = null
) {
    return {
        type: AMOUNT_ACTION,
        amount,
        amountSide,
        TimerId,
        amountNotValid,
        equivalentData
    }
}

export function errorAction(
    inAmountError,
    outAmountError,
    paywayError,
    equivalentError = false
) {
    return {
        type: ERROR_ACTION,
        inAmountError,
        outAmountError,
        paywayError,
        equivalentError
    }
}

export function activeExchangeWays(allActiveExchangeWays, limits) {
    return {
        type: EXCHANGE_WAYS_ACTION,
        allActiveExchangeWays,
        limits
    }
}

export function fileInputAction(fileData, fileName = false) {
    return {
        type: FILE_INPUT_ACTION,
        fileData,
        fileName
    }
}

export function uploadCardPhotoAction(status) {
    return {
        type: UPLOAD_CARD_PHOTO_ACTION,
        status
    }
}

export function cardPhotoAction() {
    return {
        type: CARD_PHOTO_ACTION
    }
}

export function cardVerifyStatusAction(paymentData, data) {
    return {
        type: CARD_VERIFY_ACTION,
        paymentData,
        status: data.verificationStatus,
        firstName: data.firstName || null,
        lastName: data.lastName || null
    }
}

export function loaderAction(loading) {
    return {
        type: LOADER_ACTION,
        loading
    }
}

export function outDestinationTagAction(outDestinationTag) {
    return {
        type: OUT_DESTINATION_TAG_ACTION,
        outDestinationTag
    }
}

export function widgetModeAction(widgetMode) {
    return {
        type: WIDGET_MODE,
        widgetMode
    }
}

export function resetDataAction() {
    return {
        type: RESET_DATA
    }
}

export function externalIdAction(externalId) {
    return {
        type: EXTERNAL_ID_ACTION,
        externalId
    }
}

export function saveUkraineAction(saveUkraine) {
    return {
        type: SAVE_UKRAINE_ACTION,
        saveUkraine
    }
}
