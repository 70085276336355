import React from 'react'
import ButtonDiv from './style.css'

import Loader from '../../images/loaderWhite.svg'

const Button = (props) => {
    const { title, condition, onPress, containerStyle, loader } = props

    return (
        <ButtonDiv
            style={{ ...containerStyle }}
            onClick={loader ? null : condition ? onPress : null}
            condition={condition}
        >
            <div className='button-title'>{title}</div>
            {loader ? <img src={Loader} alt='Loader' /> : null}
        </ButtonDiv>
    )
}

export default Button
