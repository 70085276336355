import axios from 'axios'

export const axiosRequest = async (link, post) => {
    let response
    if (post) {
        response = await axios.post(link, post).catch((error) => {
            console.log(error.message)
        })
        return response.data
    } else {
        response = await axios.get(link).catch((error) => {
            console.log(error.message)
            if (error.response) {
                console.log(error.response.data.message)
            }
        })
        return response.data
    }
}

export const serverRequest = async (link, type, post = false) => {
    try {
        let response = await axiosRequest(link, post)
        if (type === 'DATA') {
            let activeExchangeWays = response
            let allExchangeWays = activeExchangeWays
                ? [
                      ...activeExchangeWays.buy,
                      ...activeExchangeWays.sell,
                      ...activeExchangeWays.exchange
                  ]
                : []
            return allExchangeWays
        } else {
            return response
        }
    } catch (e) {
        console.log(JSON.stringify(e))
        return null
    }
}
