import styled from 'styled-components'

const InfoSectionDiv = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    max-width: 2010px;

    margin: 0 auto;

    .info-wrapper {
        margin-top: 100px;
    }
    h2 {
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 54px;
        line-height: 120%;
        color: #404040;
        padding: 0;
        margin: 0;
    }
    p {
        margin: 0;
        padding: 0;
    }
    .description {
        font-family: 'Fira Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 150%;
        color: #5c5c5c;
        text-align: center;
        margin-top: 24px;
        max-width: 650px;
    }
    .info-item {
        /* justify-content: center; */
        align-items: left;
        justify-content: center;
        margin-top: 56px;
        display: flex;
        max-width: 750px;
        flex-direction: column;
        margin-bottom: 40px;
    }
    .info-title {
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 130%;
        text-align: left;
        color: #404040;
    }
    .info-content {
        font-family: 'Fira Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 150%;
        text-align: left;
        margin-top: 8px;
        padding-right: 10px;
        color: #5c5c5c;
    }
    @media screen and (max-width: 640px) {
        h2 {
            font-size: 36px;
            padding-right: 20px;
        }
        .description {
            font-size: 17px;
        }
        .info-title {
            font-size: 18px;
        }
        .info-content {
            font-size: 17px;
        }
        .info-item {
            padding: 0 10px;
            display: block;
            margin-top: 32px;
        }
        .info-wrapper{
            padding:0 20px;
        }
    }

    @media screen and (min-width: 640px) and (max-width: 1280px) {
    }

    @media screen and (min-width: 1280px) and (max-width: 1920px) {
        max-width: 1266px;
        margin: 0 auto;
    }
`
export default InfoSectionDiv
