import styled from 'styled-components'

const PaywaySelectorDiv = styled.div`
    width: 100%;
    position: relative;

    .payway-container {
        width: 100%;

        position: relative;
        z-index: 100;
    }

    .payway-label {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        height: 2.94em;

        margin-left: 1.176em;
        padding-bottom: 0.353em;

        font-family: Fira Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1em;
        line-height: 120%;

        color: #5c5c5c;
    }

    .payway-selector-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        background: #b8b8b8;
        box-shadow: inset 0px 0px 0.941em rgba(0, 0, 0, 0.05);
        border-radius: 0.823em;

        border: 1px solid #b8b8b8;

        height: 50px;
        width: calc(100% - 2px);

        cursor: pointer;

        /* padding-right: 1px; */

        &.payway-selector-error {
            border: 0.0588em solid #f1e1b5;

            max-width: 30em;
            z-index: 0;
        }
    }

    .payway-selectro-wrap-text {
        padding-left: 1.176em;

        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 1.0588em;
        line-height: 120%;

        color: #404040;
    }

    .payway-error {
        display: flex;
        align-items: center;

        width: 100%;
        height: 2.941em;

        background: linear-gradient(0deg, #f1e1b5, #f1e1b5);
        border-radius: 0 0 0.82em 0.82em;

        border: 0.0588em solid #f1e1b5;

        margin-top: -0.82em;

        position: absolute;
        top: 100%;

        /* &.out {
            top: 100%;
        } */
    }

    .payway-error-text {
        margin-top: 0.588em;
        margin-left: 1.176em;
    }
`

export default PaywaySelectorDiv
