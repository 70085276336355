import { strings } from '../../../i18n'

export default function statusCard(status) {
    switch (status) {
        case 'INVALID_CARD_NUMBER':
            return strings('exchangeForm.statusCard.invalidCardNumber')
        case 'NOT_SUPPORTED_COUNTRY':
            return strings('exchangeForm.statusCard.notSupportedCountry')
        case 'NO_INFO':
            return strings('exchangeForm.statusCard.noInfo')
        case 'WAIT_FOR_PHOTO':
            return strings('exchangeForm.statusCard.waitingForPhoto')
        case 'PENDING':
            return strings('exchangeForm.statusCard.pending')
        case 'CANCELED':
            return strings('exchangeForm.statusCard.canceled')
        case 'SUCCESS':
            return strings('exchangeForm.statusCard.success')
        default:
            return null
    }
}
