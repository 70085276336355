import styled from 'styled-components'

const MonitoringDiv = styled.div`
    width: 80%;
    min-height: 2.35em;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
        padding-top: 3px;
        padding-right: 0.6em;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row;

        a {
            padding-bottom: 0.35em;
        }
    }
`

export default MonitoringDiv
