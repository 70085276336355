import axios from 'axios'
import config from '../../../config/config'

export default async function getEquivalentData(
    state,
    amount,
    captchaToken,
    side
) {
    const {
        inCurrency,
        outCurrency,
        payway,
        cashbackToken,
        allActiveExchangeWays
    } = state

    if (!inCurrency || !outCurrency || !amount) {
        return null
    }

    const body = {
        inCurrencyCode: inCurrency.code,
        outCurrencyCode: outCurrency.code,
        paywayCode:
            inCurrency.type === 'CRYPTO' && outCurrency.type === 'CRYPTO'
                ? inCurrency.code
                : payway,
        amountData: {
            amount: amount,
            side: side
        },
        cashbackToken,
        captchaToken
    }

    try {
        const response = await axios
            .post(
                `${config.backendUrl}/provider/get-profit-providers?cashbackToken=${cashbackToken}&destination=${config.destination}`,
                body
            )
            .catch((error) => {
                throw new Error(
                    'API v.3 error | ' + error.response.data.message
                )
            })

        if (response.data.variants.length === 0) {
            throw new Error('API v.3 error | No variants. Check request body.')
        }

        const resultArray = response.data.variants.filter(
            (way) =>
                config.availableRate.includes(way.rateType) &&
                !config.disabledProviders.includes(way.providerName)
        )

        if (
            !response.data.variants[0].equivalentData ||
            response.data.variants[0].equivalentData.status !== 'SUCCESS'
        ) {
            // throw new Error('API v.3 error | No equivalentData or equivalent status is FAIL')
            const limitArray = resultArray
                .filter(
                    (item) =>
                        item.equivalentData !== null &&
                        item.equivalentData?.errorCode &&
                        item.equivalentData.errorCode === 'EXCEEDING_LIMITS'
                )
                .map((item) => item.equivalentData.limit)

            let maxLimit, type
            if (limitArray.length > 0) {
                type = limitArray[0].type
                maxLimit =
                    type === 'MIN'
                        ? Math.min(...limitArray.map((item) => item.amount))
                        : Math.max(...limitArray.map((item) => item.amount))
            }

            return {
                errorCode: 'EXCEEDING_LIMITS',
                limitValue: maxLimit,
                currencySide: allActiveExchangeWays[0].limits.currencySide,
                type
            }
        }

        const result = state.exchangeWayId
            ? response.data.variants.find(
                  (way) => state.exchangeWayId === way.exchangeWayId
              )
            : resultArray[0]
        return result
    } catch (error) {
        console.log(error.message)
        return null
    }
}
