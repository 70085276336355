import styled from 'styled-components'

const WalletDiv = styled.div`
    position: relative;
    width: 100%;

    padding-top: 100px;
    padding-bottom: 100px;

    max-width: 2010px;

    margin: 0 auto;

    .phone-block-container {
        position: absolute;
        right: -23px;
        top: 16%;
        z-index: 1;
        /* max-width: 20vw; */
        /* width: 100%; */

        img {
            width: 100%;
            max-width: 380px;
            object-fit: cover;
        }
    }

    .wallet-block-container {
        position: absolute;
        left: 0;
        top: 30%;

        /* max-width: 15vw; */
        /* width: 100%; */

        img {
            width: 100%;
            max-width: 324px;
            object-fit: cover;
        }
    }

    .wallet-container {
        width: 100%;
        max-width: 40%;

        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
    }

    .wallet-container-title {
        margin: 0;

        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 44px;
        line-height: 54px;

        display: flex;
        align-items: center;
        text-align: center;

        color: #404040;

        &.trustee {
            font-size: 34px;
            line-height: 120%;
        }

        &.trustee-title {
            font-size: 62px;
            line-height: 120%;
        }
    }

    .wallet-container-description {
        font-family: Fira Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 25px;

        display: flex;
        align-items: center;
        text-align: center;

        color: #5c5c5c;

        /* width: 75%; */
        margin-top: 16px;

        z-index: 400;
    }

    .wallet-container-btn {
        width: 100%;
        max-width: 236px;
    }

    /* @media screen and (max-width: 768px) {

        display: flex;
        justify-content: center;
        align-items: center;

        .wallet-container {
            width: 100%;
            margin-top: 100px;
        }

        .wallet-container-description {
            width: 80%;
            text-align: center;
        }
    } */

    @media screen and (max-width: 640px) {
        display: flex;
        flex-wrap: wrap;

        padding-top: 70px;

        .wallet-block-container {
            display: none;
        }

        .phone-block-container {
            position: relative;
            padding-top: 40px;
            right:0%;

            margin: 0 auto;
            img {
                object-fit: cover;
                z-index: 500;
            }
        }

        .wallet-container {
            width: calc(100% - 32px);
            max-width: 100%;
            /* margin-left: 16px; */

            /* margin: 72px 16px; */
        }

        .wallet-container-title {
            &.trustee {
                font-size: 20px;
                line-height: 120%;
            }

            &.trustee-title {
                font-size: 36px;
                line-height: 120%;
            }
        }

        .wallet-container-description {
            font-size: 16px;
            line-height: 150%;

            margin-top: 8px;
        }

        .wallet-container-btn {
            margin-top: 32px;
            max-width: 100%;
        }
    }

    @media screen and (min-width: 640px) and (max-width: 1280px) {
        /* width: calc(100vw - 64px); */
        /* margin-left: 32px; */

        padding-top: 70px;

        .phone-block-container {
            top: 25%;
            right: 32px;
            img {
                max-width: 220px;
                object-fit: cover;
            }
        }

        .wallet-block-container {
            /* top: 25%; */
            /* left: -32px; */

            img {
                max-width: 220px;
            }
        }

        .wallet-container {
            max-width: 50vw;
        }

        .wallet-container-title {
            &.trustee {
                font-size: 22px;
                line-height: 130%;
            }

            &.trustee-title {
                font-size: 40px;
                line-height: 120%;
            }
        }

        .wallet-container-description {
            font-size: 22px;
            line-height: 130%;
        }
    }

    @media screen and (min-width: 1280px) and (max-width: 1920px) {
        max-width: 1266px;
        margin: 0 auto;
    }
`

export default WalletDiv
