import styled from 'styled-components'

const ExchangeFormDiv = styled.div`
    min-height: ${(p) => (p.widgetMode ? '326px' : '386px')};
    width: 100%;
    max-width: 570px;
    min-width: 420px;
    position: relative;

    .exchange-ways {
        display: flex;
        text-align: center;
        /* width: calc(100% - 60px); */
        width: calc(100% - 2.5em);

        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 1.76em;
        line-height: 130%;

        color: #404040;

        margin-bottom: 0.834em;
        margin-left: 1.25em;
    }

    .tabs {
        height: 3.764em;

        display: flex;
        align-items: center;
        justify-content: center;

        background: #d2d2d2;

        border-radius: 2.353em 2.353em 0 0;
    }

    .tabs-item {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 50%;
        height: 100%;

        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 1em;
        line-height: 100%;

        text-align: center;
        letter-spacing: 0.11em;
        text-transform: uppercase;

        color: rgba(64, 64, 64, 0.7);

        cursor: pointer;
    }

    .tabs-item-acitve {
        height: 100%;
        width: 50%;

        color: #404040;

        border-radius: 2.353em 2.353em 0 0;

        background: #e8e8e8;

        /* animation: clickAnimation 0.25s ease-in-out; */
    }

    @keyframes clickAnimation {
        from {
            /* opacity: 0; */
            background: #d2d2d2;
        }
        to {
            /* opacity: 1; */
            background: #e8e8e8;
        }
    }

    .exchange-form {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: calc(100% - 3.53em);
        min-height: 300px;

        padding: 0 1.76em 1.76em 1.76em;

        background: #e8e8e8;
        border-radius: 0 0 2.353em 2.353em;

        &.widget {
            background: #e0e0ea;
            border-radius: 2.353em;
        }

        &.saveUkraine {
            border-radius: 2.353em;
        }
    }

    .exchane-form-loading {
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-self: center;

        margin-top: 32px;

        img {
            width: 80px;
        }

        &.absolute {
            position: absolute;
            background: #e8e8e8;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1000;
            margin-top: 0;
            border-radius: 0 0 2.353em 2.353em;
        }
    }

    .exchange-form-payway {
        width: 100%;

        transform: translateY(0);

        &.payway-show {
            /* transform: translateY(0); */
            /* animation: showPayway ease 0.25s; */
        }
    }

    .exchange-form-rate {
        width: calc(100% - 1.17em);

        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;

        font-family: Fira Sans, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 1em;
        line-height: 150%;

        color: #5c5c5c;

        margin-left: 1.176em;
        margin-top: 1.88em;
    }

    .exchange-form-rate-label {
        font-family: Fira Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1em;
        line-height: 150%;
        color: #5c5c5c;

        padding-right: 0.53em;
    }

    .apply-terms {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        margin-top: 66px;
        margin-left: 52px;
        width: calc(100% - 104px);

        font-family: Fira Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1em;
        line-height: 150%;

        color: #5c5c5c;

        a {
            text-decoration: underline;
            color: #5c5c5c;

            font-family: Fira Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1em;
        line-height: 150%;
        }
    }

    .exchane-form-btn {
        width: 100%;
        max-width: 216px;
    }

    @keyframes showPayway {
        from {
            transform: translateY(-60px);
        }
        to {
            transform: translateY(0px);
        }
    }

    @media screen and (max-width: 640px) {
        width: 100%;
        min-width: 100%;

        .tabs {
            border-radius: 0;
            height: 54px;
        }

        .tabs-item {
            font-size: 14px;
            line-height: 120%;
            height: 54px;
        }

        .tabs-item-acitve {
            border-radius: 0px 30px 0px 0px;
            height: 54px;

            &.right {
                border-radius: 30px 0px 0px 00px;
            }
        }

        .exchange-form {
            border-radius: 0;

            width: calc(100% - 32px);
            padding: 0 16px 32px 16px;

            &.widget {
                border-radius: 0;
            }

            &.saveUkraine {
                border-radius: 0;
            }
        }

        .apply-terms {
            font-size: 16px;
            line-height: 150%;

            width: calc(100% - 32px);
            margin-left: 16px;
            margin-top: 40px;
        }

        .exchange-form-rate {
            font-size: 17px;
            line-height: 150%;
        }

        .exchange-form-rate-label {
            font-size: 16px;
            line-height: 150%;
        }

        .exchane-form-btn {
            max-width: 100%;
        }
    }

    @media screen and (min-width: 640px) and (max-width: 1280px) {
        width: 100%;
        max-width: ${(p) => (p.widgetMode ? '570px' : '450px')};

        .tabs {
            height: 54px;
        }

        .tabs-item {
            font-size: 14px;
            line-height: 120%;
            height: 54px;
        }

        .tabs-item-acitve {
            height: 54px;
        }
    }
`

export default ExchangeFormDiv