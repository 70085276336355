class ConfigLocal {
    baseUrl = 'http://localhost:4000'
    backendUrl = 'http://localhost:3000'
    captchaSiteKey = '6LdUWZQUAAAAAN-GyfT6XLVNX3ios-t1IyRmMIEm'
    cashbackTokensArray = [
        'jYjY4M2J',
        'zNTk1QTM',
        'xMjRENGE',
        'wOGE5Mjg',
        '2RDBCYzI'
    ]
    disabledProviders = ['MINE_EXCHANGE', 'THE_1INCH', 'ANYCASH']
    availableRate = ['FLOATING']
    disavledPayways = ['CASH']
    originalToken = 'xNzQ0YUU'
    downloadLink = 'https://trusteeglobal.com/link/hNzUzODB'
    destination = 'SITE'
    termsRu = 'https://drive.google.com/file/d/17t1kyiADJ6rBWo9meMGR8maQXvmwS9CG/view?usp=sharing'
    termsEn = 'https://drive.google.com/file/d/1BZmNYiHaoZ3hSjLgzXQCQWUiwuukyy8w/view?usp=sharing'
    // __REACT_DEVTOOLS_GLOBAL_HOOK__ = true;
}

class ConfigDevelopment {
    baseUrl = 'https://test.trusteeswap.com'
    backendUrl = 'https://testapiv3.trustee.deals'
    captchaSiteKey = '6LdYQLcZAAAAAE8ahlTyY2XQH8o3N7JQra535oaU'
    cashbackTokensArray = [
        'jYjY4M2J',
        'zNTk1QTM',
        'xMjRENGE',
        'wOGE5Mjg',
        '2RDBCYzI'
    ]
    disabledProviders = ['THE_1INCH']
    availableRate = ['FLOATING']
    disavledPayways = ['CASH']
    originalToken = 'xNzQ0YUU'
    downloadLink = 'https://trusteeglobal.com/link/hNzUzODB'
    destination = 'SITE'
    termsRu = 'https://drive.google.com/file/d/17t1kyiADJ6rBWo9meMGR8maQXvmwS9CG/view?usp=sharing'
    termsEn = 'https://drive.google.com/file/d/1BZmNYiHaoZ3hSjLgzXQCQWUiwuukyy8w/view?usp=sharing'
    // __REACT_DEVTOOLS_GLOBAL_HOOK__ = true;
}

class ConfigProduction {
    baseUrl = 'https://trusteeswap.com'
    backendUrl = 'https://api.v3.trustee.deals'
    captchaSiteKey = '6LdYQLcZAAAAAE8ahlTyY2XQH8o3N7JQra535oaU'
    cashbackTokensArray = [
        'jYjY4M2J',
        'zNTk1QTM',
        'xMjRENGE',
        'wOGE5Mjg',
        '2RDBCYzI'
    ]
    disabledProviders = ['THE_1INCH']
    availableRate = ['FLOATING']
    disavledPayways = ['CASH']
    originalToken = 'xNzQ0YUU'
    downloadLink = 'https://trusteeglobal.com/link/hNzUzODB'
    destination = 'SITE'
    termsRu = 'https://drive.google.com/file/d/17t1kyiADJ6rBWo9meMGR8maQXvmwS9CG/view?usp=sharing'
    termsEn = 'https://drive.google.com/file/d/1BZmNYiHaoZ3hSjLgzXQCQWUiwuukyy8w/view?usp=sharing'
    // __REACT_DEVTOOLS_GLOBAL_HOOK__ = true;
}

const config =
    process.env.NODE_ENV === 'production' ?
    process.env.REACT_APP_MODE === 'DEV' ?
    new ConfigDevelopment() :
    new ConfigProduction() :
    new ConfigLocal()
export default config