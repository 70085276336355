import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
    body {
        background: ${({ theme }) => theme.body};
    }

    .desktop-headline-h5 {
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 29px;
    }

    .body-idle {
        font-family: Fira Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 26px;
    }

    pre {
        white-space: pre-wrap;       /* Since CSS 2.1 */
        white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
        white-space: -pre-wrap;      /* Opera 4-6 */
        white-space: -o-pre-wrap;    /* Opera 7 */
        word-wrap: break-word;       /* Internet Explorer 5.5+ */
    }

    @media screen and (min-width: 640px) and (max-width: 1280px) {
        .desktop-headline-h5 {
            font-size: 16px;
            line-height: 21px;
        }

        .body-idle {
            font-size: 17px;
            line-height: 26px;
        }

    }

    @media screen and (max-width: 640px){
        .desktop-headline-h5 {
            font-size: 16px;
            line-height: 21px;
        }

        .body-idle {
            font-size: 17px;
            line-height: 26px;
        }

    }
`
