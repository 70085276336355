import React from 'react'
import { strings } from '../../../i18n'

const ServiceComponent = (props) => {
    const { data, i18key } = props

    return (
        <>
            {data.map((item) => {
                return (
                    <div className='other-wrapper' key={item.id}>
                        <a className='other-links' href={item.href}>
                            <div className='other-links-wrapper-item'>
                                {item.key === 'mail'
                                    ? 'contact@trustee.deals'
                                    : strings(`footer.${i18key}.${item.key}`)}
                            </div>
                        </a>
                    </div>
                )
            })}
        </>
    )
}

export default React.memo(ServiceComponent)
