import React from 'react'
// import Icon from '../Icon/Icon'
import { strings } from '../../i18n'
// import { getThemeModeStyles } from '../../theme/useDarkMode'
import InfoSectionDiv from './style.css'
import FaqItem from './element/faqItem/FaqItem.jsx'

const InfoSection = (props) => {
    const contentArray = [
        {
            id: 1,
            title: strings('faq.amountTitle'),
            content: strings('faq.amountContent')
        },
        {
            id: 2,
            title: strings('faq.сommissionTitle'),
            content: strings('faq.сommissionContent')
        },
        {
            id: 3,
            title: strings('faq.rateTitle'),
            content: strings('faq.rateContent')
        },
        {
            id: 4,
            title: strings('faq.timeTitle'),
            content: strings('faq.timeContent')
        },
        {
            id: 5,
            title: strings('faq.registerTitle'),
            content: strings('faq.registerContent')
        },
        {
            id: 6,
            title: strings('faq.cryptoWalletTitle'),
            content: strings('faq.cryptoWalletContent')
        }
    ]

    return (
        <InfoSectionDiv>
            <div className='info-wrapper'>
                <h2>{strings('faq.faqTitle')}</h2>
                <p className='description'>{strings('faq.faqDescr')}</p>
            </div>
            <div className='info-content'>
                {contentArray.map((item) => {
                    return (
                        <div className='info-item' key={item.id}>
                            <FaqItem {...item} />
                        </div>
                    )
                })}
            </div>
        </InfoSectionDiv>
    )
}

export default InfoSection
