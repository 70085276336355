import { currencyType } from 'trustee-swap-assets'

import validation from './ValidationService'

export const emailValid = (email) => {
    if (email) {
        if (!validation('EMAIL', email)) {
            return true
        }
    } else {
        return true
    }
}

export const outDestinationValid = (state, outDestination) => {
    if (outDestination) {
        if (state.payway === 'MOBILE_PHONE') {
            if (!validation('PHONE_UA', outDestination)) {
                return true
            }
        } else if (state.payway === 'QIWI') {
            if (!validation('PHONE_RU', outDestination)) {
                return true
            }
        } else if (
            state.payway === 'CARD' &&
            state.outCurrency.type === 'FIAT'
        ) {
            cardValid(outDestination)
        } else {
            if (
                !validation(
                    currencyType(state.outCurrency.code),
                    outDestination
                )
            ) {
                return true
            }
        }
    } else {
        return true
    }
}

export const cardValid = (card) => {
    if (card) {
        card = card.toString().split(' ').join('')
        if (!validation('CARD', card)) return true
    } else {
        return true
    }
}
