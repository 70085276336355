import { useState, useEffect } from 'react'

export const useDetectOutsideClick = (el, initialState, callback = false) => {
    const [isActive, setIsActive] = useState(initialState)

    useEffect(() => {
        const pageClickEvent = (e) => {
            // If the active element exists and is clicked outside of
            if (el.current !== null && !el.current.contains(e.target)) {
                setIsActive(!isActive)
                if (callback) callback()
            }
        }

        // If the item is active (ie open) then listen for clicks
        if (isActive) {
            window.addEventListener('click', pageClickEvent)
        }

        return () => {
            window.removeEventListener('click', pageClickEvent)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, el])

    return [isActive, setIsActive]
}
