import React, { useCallback } from 'react'
// import { strings } from '../../i18n'
import FaqSectionDiv from './style.css'
import { useState } from 'react'
import ArrowApp from '../../../../images/arrow-up.svg'

import AnimateHeight from 'react-animate-height'

const FaqSection = (props) => {
    const [open, setOpen] = useState(false)
    
    const handleHeight = useCallback(() => setOpen(!open), [open])

    return (
        <FaqSectionDiv>
            <div className='isMobile'>
                <AnimateHeight duration={300} height={open ? 'auto' : 45}>
                    <div className='info-title'>
                        {props.title}
                        <div className='button-wrap'>
                            <div
                                className={`buttonFaq ${
                                    open ? 'open' : 'close'
                                }`}
                                onClick={handleHeight}>
                                <img src={ArrowApp} alt='arrow' />
                            </div>
                        </div>
                    </div>
                    <div className='info-content'>{props.content}</div>
                </AnimateHeight>
            </div>
            <div className='isDesktop'>
                <div className='info-title'>{props.title}</div>
                <div className='info-content'>{props.content}</div>
            </div>
        </FaqSectionDiv>
    )
}

export default FaqSection
