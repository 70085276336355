import React, { useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router'
import ReCAPTCHA from 'react-google-recaptcha'
import AnimateHeight from 'react-animate-height'

import Button from '../../components/Button/Button'
import { strings } from '../../i18n'
import store from '../../redux'
import {
    activeExchangeWays,
    emailAction,
    outDestinationAction,
    outDestinationTagAction,
    paymenDataAction,
    qniqueParamsAction,
    setExchangeWayAction,
    errorAction,
    amountAction,
    resetDataAction
} from '../../redux/stores/exchangeStore/action'
import Input from './elements/Input/Input'
import InputsAndSelectors from './elements/InputsAndSelectors/InputAndSelectors'
import PaywaySelectorComponent from './elements/PaywaysSelector/PaywaySelector'
import { checkCardNumber } from './services/checkCardNumber'
import {
    emailValid,
    outDestinationValid,
    cardValid
} from './services/inputValid'
import ExchangeFormDiv from './style.css'
import config from '../../config/config'
import getActualWays from './services/getWaysData'
import getMinMaxLimitsObj from './services/getMinMaxLimitsObj'

import Loader from '../../images/loaderBlack.svg'
import ModalComponent from './elements/Modal/Modal'

const { dispatch } = store
const reCaptchaRef = React.createRef()

const setOutDestination = (e) => {
    const value = e.target.value || ''

    dispatch(outDestinationAction(value))
}

const setEmail = (e) => {
    const value = e.target.value || ''

    dispatch(emailAction(value))
}

const setOutDestinationTag = (e) => {
    const value = e.target.value || ''

    dispatch(outDestinationTagAction(value))
}

const ExchnageFormComponent = (props) => {
    const state = store.getState().exchangeStore

    const history = useHistory()

    const [loader, setLoader] = useState(false)
    const [height, setHeight] = useState('auto')
    const [dataOrder, setOrderData] = useState(null)
    const [amountLoad, setAmountLoad] = useState(true)

    const { mainPage } = props

    const skipPaymentDetails =
        state.equivalentData?.providerName === 'MOON_PAY' ||
        state.equivalentData?.providerName === 'MERCURYO'

    const urlPath = (path) => {
        history.push(`` + path)
    }

    const setPaymentData = async (e) => {
        const value = e.target.value || ''

        if (state.payway === 'CARD') {
            const cardData = await checkCardNumber(value)
            dispatch(paymenDataAction(cardData))
        } else {
            dispatch(paymenDataAction({ number: value }))
        }
    }

    const getCardStatus = () => {
        if (state.payway !== 'CARD') return null
        if (
            !state.paymentData ||
            typeof state.paymentData.status === 'undefined'
        )
            return null

        switch (state.paymentData.status.toUpperCase()) {
            case 'PENDING':
                return 'progress'
            case 'WAIT_PHOTO':
                return null
            case 'CANCELED':
                return 'canceled'
            case 'SUCCESS':
                return 'success'
            default:
                return null
        }
    }

    const renderUniqueExchangeDetails = () => {
        switch (state.exchangeWay) {
            case 'EXCHANGE':
                return (
                    <>
                        <Input
                            id='outDestinationExchange'
                            label={
                                state.saveUkraine
                                    ? strings('exchangeForm.donateWallet')
                                    : strings('exchangeForm.walletReceive')
                            }
                            placeholder={strings('exchangeForm.inputAddress')}
                            type='outDestination'
                            value={
                                (state.outDestination
                                    ? state.outDestination.length > 32
                                        ? state.outDestination
                                              .toString()
                                              .slice(0, 8) +
                                          '...' +
                                          state.outDestination
                                              .toString()
                                              .slice(-8)
                                        : state.outDestination
                                    : '') || ''
                            }
                            onChange={setOutDestination}
                            error={state.outDestinationNotValid}
                            textError={
                                strings('exchangeForm.walletReceive') +
                                ' ' +
                                strings('exchangeForm.outDestinationError')
                            }
                            readOnly={!state.editOutDestination}
                            onBlur={() => {
                                const address = document.getElementById(
                                    'outDestinationExchange'
                                )
                                if (address.value.length > 32) {
                                    address.value =
                                        address.value.toString().slice(0, 8) +
                                        '...' +
                                        address.value.toString().slice(-8)
                                } else {
                                    address.value = address.value.toString()
                                }
                            }}
                            onFocus={() => {
                                if (state.editOutDestination) return
                                const address = document.getElementById(
                                    'outDestinationExchange'
                                )
                                address.value = state.outDestination || ''
                            }}
                            inputMode='text'
                        />
                        {!state.saveUkraine && (
                            <Input
                                label={strings('exchangeForm.email')}
                                placeholder='yourMail@gmail.com'
                                type='email'
                                value={state.email || ''}
                                onChange={setEmail}
                                error={state.emailNotValid}
                                textError={
                                    strings('exchangeForm.email') +
                                    ' ' +
                                    strings('exchangeForm.outDestinationError')
                                }
                                readOnly={state.editEmail}
                                inputMode='email'
                            />
                        )}
                    </>
                )
            case 'BUY/SELL':
                return (
                    <>
                        {state.inCurrency.type === 'FIAT' && (
                            <Input
                                id='outDestinationBuy'
                                label={
                                    state.saveUkraine
                                        ? strings('exchangeForm.donateWallet')
                                        : strings('exchangeForm.walletReceive')
                                }
                                placeholder={strings(
                                    'exchangeForm.inputAddress'
                                )}
                                type='outDestination'
                                inputMode='text'
                                value={
                                    (state.outDestination
                                        ? state.outDestination.length > 32
                                            ? state.outDestination
                                                  .toString()
                                                  .slice(0, 8) +
                                              '...' +
                                              state.outDestination
                                                  .toString()
                                                  .slice(-8)
                                            : state.outDestination
                                        : '') || ''
                                }
                                onChange={setOutDestination}
                                error={state.outDestinationNotValid}
                                textError={
                                    strings('exchangeForm.walletReceive') +
                                    ' ' +
                                    strings('exchangeForm.outDestinationError')
                                }
                                readOnly={!state.editOutDestination}
                                onBlur={() => {
                                    const address =
                                        document.getElementById(
                                            'outDestinationBuy'
                                        )
                                    if (address.value.length > 32) {
                                        address.value =
                                            address.value
                                                .toString()
                                                .slice(0, 8) +
                                            '...' +
                                            address.value.toString().slice(-8)
                                    } else {
                                        address.value = address.value.toString()
                                    }
                                }}
                                onFocus={() => {
                                    if (state.editOutDestination) return
                                    const address =
                                        document.getElementById(
                                            'outDestinationBuy'
                                        )
                                    address.value = state.outDestination || ''
                                }}
                            />
                        )}
                        {(state.outCurrency.code === 'XRP' ||
                            state.outCurrency.code === 'XLM' ||
                            state.outCurrency.code === 'BNB') && (
                            <Input
                                label={strings(
                                    'exchangeForm.outDestinationTag'
                                )}
                                placeholder={strings(
                                    'exchangeForm.outDestinationTag'
                                )}
                                type='outDestinationTag'
                                value={state.outDestinationTag || ''}
                                onChange={setOutDestinationTag}
                                inputMode='text'
                            />
                        )}
                        {!state.saveUkraine && (
                            <Input
                                label={strings('exchangeForm.email')}
                                placeholder='yourMail@gmail.com'
                                type='email'
                                value={state.email || ''}
                                onChange={setEmail}
                                error={state.emailNotValid}
                                textError={
                                    strings('exchangeForm.email') +
                                    ' ' +
                                    strings('exchangeForm.outDestinationError')
                                }
                                readOnly={state.editEmail}
                                inputMode='email'
                            />
                        )}
                        {state.equivalentData ? (
                            skipPaymentDetails &&
                            state.inCurrency.type === 'FIAT' &&
                            state.payway ===
                                'CARD' ? null : state.paymentData &&
                              state.inCurrency.type === 'FIAT' &&
                              state.payway === 'CARD' ? (
                                !skipPaymentDetails &&
                                state.paymentData &&
                                state.inCurrency.type === 'FIAT' &&
                                state.payway === 'CARD' ? (
                                    <Input
                                        label={strings(
                                            'exchangeForm.cardNumber'
                                        )}
                                        placeholder={strings(
                                            'exchangeForm.inputNumber'
                                        )}
                                        typePayment={state.payway}
                                        value={
                                            state.paymentData
                                                ? state.paymentData.number
                                                : ''
                                        }
                                        onChange={setPaymentData}
                                        error={state.paymentDataNotValid}
                                        textError={strings(
                                            'exchangeForm.outDestinationError'
                                        )}
                                        rightType={getCardStatus()}
                                        verification={
                                            state.paymentData &&
                                            state.inCurrency.type === 'FIAT' &&
                                            state.payway === 'CARD' &&
                                            state.paymentData.status
                                        }
                                        reCaptchaRef={reCaptchaRef}
                                        state={state}
                                    />
                                ) : skipPaymentDetails &&
                                  state.outCurrency.type === 'FIAT' &&
                                  state.payway === 'BANK_TRANSFER' ? (
                                    <Input
                                        label={strings(
                                            'exchangeForm.accountNumber'
                                        )}
                                        placeholder={strings(
                                            'exchangeForm.inputAccount'
                                        )}
                                        typePayment={state.payway}
                                        value={
                                            state.paymentData
                                                ? state.paymentData.number
                                                : ''
                                        }
                                        onChange={setPaymentData}
                                        error={state.paymentDataNotValid}
                                        textError={strings(
                                            'exchangeForm.outDestinationError'
                                        )}
                                    />
                                ) : (
                                    <Input
                                        label={strings(
                                            'exchangeForm.accountNumber'
                                        )}
                                        placeholder={strings(
                                            'exchangeForm.inputAccount'
                                        )}
                                        typePayment={state.payway}
                                        value={
                                            state.paymentData
                                                ? state.paymentData.number
                                                : ''
                                        }
                                        onChange={setPaymentData}
                                        error={state.paymentDataNotValid}
                                        textError={strings(
                                            'exchangeForm.outDestinationError'
                                        )}
                                    />
                                )
                            ) : (
                                <Input
                                    label={
                                        state.payway === 'CARD'
                                            ? strings('exchangeForm.cardNumber')
                                            : strings(
                                                  'exchangeForm.accountNumber'
                                              )
                                    }
                                    placeholder={
                                        state.payway === 'CARD'
                                            ? strings(
                                                  'exchangeForm.inputNumber'
                                              )
                                            : strings(
                                                  'exchangeForm.inputAccount'
                                              )
                                    }
                                    typePayment={state.payway}
                                    value={
                                        state.paymentData
                                            ? state.paymentData.number
                                            : ''
                                    }
                                    onChange={setPaymentData}
                                    error={state.paymentDataNotValid}
                                    textError={strings(
                                        'exchangeForm.outDestinationError'
                                    )}
                                />
                            )
                        ) : null}
                    </>
                )
            default:
                return null
        }
    }

    const nextStep = async () => {
        if (state.widgetMode && !state.saveUkraine) {
            let url = `${config.baseUrl}/exchange?inCurrency=${state.inCurrency.code}&outCurrency=${state.outCurrency.code}`

            if (state.amount) {
                url += `&amount=${state.amount}&amountSide=${state.amountSide}`
            }

            window.open(url, '_blank')
            return
        }

        if (mainPage) {
            urlPath('exchange')
            return
        }

        const localEmail = emailValid(state.email) || false
        const localCard = skipPaymentDetails
            ? false
            : !state.paymentData
            ? state.inCurrency.type === 'FIAT'
                ? true
                : false
            : state.paymentData.status !== 'SUCCESS'
            ? true
            : (state.payway === 'CARD'
                  ? cardValid(state.paymentData.number)
                  : false) || false
        const localDestination =
            outDestinationValid(state, state.outDestination) || false

        if (!state.amount) {
            dispatch(amountAction(0, null, null, false))
            dispatch(errorAction(true, null, null))
        }

        if (state.outCurrency.type === 'FIAT') {
            if (localEmail || localCard) {
                dispatch(qniqueParamsAction(false, localEmail, localCard))
                return true
            }
        } else {
            if (localDestination || localEmail || localCard) {
                console.log('here')
                dispatch(
                    qniqueParamsAction(localDestination, localEmail, localCard)
                )
                return true
            }
        }
        if (!state.equivalentData) return

        if (
            state.payway === 'CARD' &&
            state.inCurrency.type === 'FIAT' &&
            !skipPaymentDetails
        ) {
            if (
                typeof state.equivalentData !== 'undefined' &&
                state.equivalentData
            ) {
                const exchangeWayInfo = state.allActiveExchangeWays.find(
                    (way) =>
                        way.exchangeWayId === state.equivalentData.exchangeWayId
                )
                if (
                    (!state.paymentData ||
                        state.paymentData.status !== 'SUCCESS') &&
                    exchangeWayInfo.inCurrency.type === 'FIAT'
                ) {
                    return true
                }
                // return false
            }
        }

        setLoader(true)

        await createOrderButtonHandler(null, null, setLoader)
    }

    const goToOrder = () => {
        let link = `/order-info?orderHash=${dataOrder.orderHash}&locale=${state.locale}`
        if (state.saveUkraine) link += '&saveUkraine=true'

        window.open(link, '_blank')
        setOrderData(null)
        dispatch(resetDataAction())
    }

    const orderError = () => {
        setOrderData(null)
        dispatch(resetDataAction())
    }

    const createOrderButtonHandler = async (captcha = null, self = null) => {
        const localState = store.getState().exchangeStore

        const captchaToken = !captcha
            ? await reCaptchaRef.current.executeAsync()
            : await captcha.current.executeAsync()

        const activeExchangeWay =
            localState.allActiveExchangeWays.find(
                (way) =>
                    way.exchangeWasId ===
                    localState.equivalentData.exchangeWasId
            ).exchangeWayType || false

        const body = {
            inAmount: localState.equivalentData.equivalentData.inAmount,
            outAmount: localState.equivalentData.equivalentData.outAmount,
            amountSide: localState.amountSide,
            exchangeWayId: localState.equivalentData.exchangeWayId,
            outDestination:
                activeExchangeWay === 'SELL'
                    ? localState?.paymentData?.number
                    : localState.outDestination,
            outDestinationTag: localState.outDestinationTag,
            rateId: localState?.equivalentData?.equivalentData?.rateData?.rateId || null,
            cashbackToken: localState.cashbackToken,
            locale: localState.locale,
            email: localState.email,
            equivalentData: localState.equivalentData.equivalentData,
            captchaToken,
            createdFrom: config.destination,
            uniqueParams: {},
            paymentDetailsData: {}
        }

        if (localState.paymentData) {
            if (localState.payway === 'CARD' && !skipPaymentDetails) {
                body.paymentDetailsData = {
                    paymentDetails: localState.paymentData.number,
                    supportedCountries: [localState.paymentData.countryCode]
                }
            } else {
                body.paymentDetailsData = {
                    ...body.paymentDetailsData,
                    paymentDetails: localState.paymentData.number
                }

                if (state.payway === 'ADVCASH') {
                    body.uniqueParams.email = state.paymentData.cardEmail
                }
            }
        }

        if (localState.equivalentData.providerName === 'KASSA') {
            body.uniqueParams.calculationId = localState.equivalentData.equivalentData.rateData.calculationId
        }

        if (localState.externalId) {
            body.invoiceData = {
                externalId: localState.externalId
            }
        }

        if (state.callbackUrl) {
            body.invoiceData = {
                ...body.invoiceData,
                callbackUrl: localState.callbackUrl
            }
        }

        if (localState.redirectUrl) {
            body.invoiceData = {
                ...body.invoiceData,
                redirectUrl: localState.redirectUrl
            }
        }

        try {
            const response = await axios
                .post(
                    `${config.backendUrl}/order/create-order?cashbackToken=${localState.cashbackToken}&createdFrom=${config.destination}`,
                    body
                )
                .catch((error) => {
                    console.log(error.response.data.message)
                    setLoader && setLoader(false)
                    throw new Error(error.response.data.message)
                })
            const orderData = response.data
            console.log('orderData', orderData)
            setLoader && setLoader(false)

            if (state.widgetMode) {
                setOrderData(orderData)
            } else {
                let orderLink = `/order-info?orderHash=${orderData.orderHash}`
                if (state.saveUkraine) orderLink += '&saveUkraine=true'

                if (state.inCurrency.type === 'FIAT') {
                    if (state.externalId) {
                        window.open(orderData.url, '_self')
                    } else {
                        window.open(orderLink, '_self')
                    }
                } else {
                    window.open(orderLink, '_self')
                }
            }
        } catch (error) {
            if (state.widgetMode) {
                setOrderData({ error: error })
            }

            captcha ? captcha.current.reset() : reCaptchaRef.current.reset()
            console.log(error.message)
        }
    }

    const buttonName = mainPage
        ? strings('exchangeForm.next')
        : state.saveUkraine
        ? strings('exchangeForm.donate')
        : state.exchangeWay === 'EXCHANGE'
        ? strings('exchangeForm.exchange')
        : state.inCurrency
        ? state.inCurrency.type === 'FIAT'
            ? strings('exchangeForm.buy')
            : strings('exchangeForm.sell')
        : strings('exchangeForm.buy') + '/' + strings('exchangeForm.sell')

    return (
        <ExchangeFormDiv widgetMode={state.widgetMode}>
            {!state.widgetMode && !state.saveUkraine ? (
                <div className='tabs'>
                    <div
                        className={`tabs-item ${
                            state.exchangeWay === 'EXCHANGE'
                                ? 'tabs-item-acitve'
                                : ''
                        }`}
                        onClick={async () => {
                            if (state.loading) return
                            if (state.exchangeWay === 'EXCHANGE') return
                            const inCurrency = {
                                code: 'ETH',
                                type: 'CRYPTO',
                                symbol: 'ETH',
                                name: 'Ethereum'
                            }
                            const outCurrency = state.saveUkraine
                                ? state.outCurrency
                                : {
                                      code: 'BTC',
                                      type: 'CRYPTO',
                                      symbol: 'BTC',
                                      name: 'Bitcoin'
                                  }
                            setTimeout(() => {
                                dispatch(
                                    setExchangeWayAction(
                                        'EXCHANGE',
                                        inCurrency,
                                        outCurrency,
                                        null
                                    )
                                )
                            }, 200)
                            setHeight(0)

                            const allActiveExchangeWays = await getActualWays(
                                inCurrency,
                                outCurrency,
                                null,
                                state.cashbackToken
                            )
                            const limits = await getMinMaxLimitsObj(
                                allActiveExchangeWays,
                                inCurrency,
                                outCurrency,
                                null
                            )
                            dispatch(
                                activeExchangeWays(
                                    allActiveExchangeWays,
                                    limits
                                )
                            )
                        }}
                    >
                        {strings('exchangeForm.exchange')}
                    </div>
                    <div
                        className={`tabs-item ${
                            state.exchangeWay !== 'EXCHANGE'
                                ? 'tabs-item-acitve right'
                                : ''
                        }`}
                        onClick={async () => {
                            if (state.loading) return
                            if (state.exchangeWay === 'BUY/SELL') return

                            setHeight('auto')
                            const inCurrency = {
                                code: 'UAH',
                                type: 'FIAT',
                                symbol: 'UAH'
                            }
                            const outCurrency = state.saveUkraine
                                ? state.outCurrency
                                : {
                                      code: 'BTC',
                                      type: 'CRYPTO',
                                      symbol: 'BTC',
                                      name: 'Bitcoin'
                                  }
                            dispatch(
                                setExchangeWayAction(
                                    'BUY/SELL',
                                    inCurrency,
                                    outCurrency,
                                    'CARD'
                                )
                            )
                            const allActiveExchangeWays = await getActualWays(
                                inCurrency,
                                outCurrency,
                                'CARD',
                                state.cashbackToken
                            )
                            const limits = await getMinMaxLimitsObj(
                                allActiveExchangeWays,
                                inCurrency,
                                outCurrency,
                                'CARD'
                            )
                            dispatch(
                                activeExchangeWays(
                                    allActiveExchangeWays,
                                    limits
                                )
                            )

                            const paywayComponent =
                                document.getElementsByClassName(
                                    'exchange-form-payway'
                                )[0]
                            paywayComponent.classList.add('payway-show')
                            setTimeout(() => {
                                paywayComponent.classList.remove('payway-show')
                            }, 250)
                        }}
                    >
                        {strings('exchangeForm.buy') +
                            '/' +
                            strings('exchangeForm.sell')}
                    </div>
                </div>
            ) : null}
            <div
                className={`exchange-form ${
                    state.widgetMode
                        ? 'widget'
                        : state.saveUkraine
                        ? 'saveUkraine'
                        : ''
                }`}
            >
                {state.externalId && amountLoad ? (
                    <div className='exchane-form-loading absolute'>
                        <img src={Loader} alt='loader' />
                    </div>
                ) : null}
                {!state.loading ? (
                    <>
                        <InputsAndSelectors
                            state={state}
                            reCaptchaRef={reCaptchaRef}
                            setHeight={setHeight}
                            amountLoad={amountLoad}
                            setAmountLoad={setAmountLoad}
                        />
                        <AnimateHeight
                            id='exchangeForm'
                            duration={200}
                            height={height} // see props documentation below
                            easing='ease-in-out'
                            className='exchange-form-payway'
                        >
                            {state.exchangeWay !== 'EXCHANGE' ? (
                                <PaywaySelectorComponent />
                            ) : null}
                        </AnimateHeight>

                        {!mainPage && (
                            <>
                                {state.equivalentData ? (
                                    state.equivalentData.equivalentData &&
                                    !state.externalId ? (
                                        <div className='exchange-form-rate'>
                                            <div className='exchange-form-rate-label'>
                                                {strings(
                                                    'exchangeForm.estimatedRate'
                                                )}
                                            </div>
                                            {`1 ${
                                                state.equivalentData
                                                    .exchangeRateSide === 'OUT'
                                                    ? state.equivalentData
                                                          .inCurrency.symbol
                                                    : state.equivalentData
                                                          .exchangeRateSide ===
                                                      'IN'
                                                    ? state.equivalentData
                                                          .outCurrency.symbol
                                                    : state.equivalentData
                                                          .inCurrency.symbol
                                            }`}
                                            {`${
                                                state.equivalentData
                                                    .equivalentData.exchangeRate
                                                    ? state.equivalentData
                                                          .rateType === 'FIXED'
                                                        ? ' = '
                                                        : ' ≈ '
                                                    : ''
                                            }
                            ${
                                state.equivalentData.equivalentData.exchangeRate
                                    ? state.equivalentData.equivalentData
                                          .exchangeRate
                                    : state.equivalentData.equivalentData
                                          .currencySide === 'IN'
                                    ? state.equivalentData.outCurrency.type ===
                                      'CRYPTO'
                                        ? '> 0.000001'
                                        : '> 0.01'
                                    : state.equivalentData.outCurrency.type ===
                                      'CRYPTO'
                                    ? '< 0.000001'
                                    : '< 0.01'
                            }
                            ${
                                state.equivalentData.exchangeRateSide === 'OUT'
                                    ? state.equivalentData.outCurrency.symbol
                                    : state.equivalentData.equivalentData
                                          .exchangeRateSide === 'IN'
                                    ? state.equivalentData.inCurrency.symbol
                                    : state.equivalentData.inCurrency.symbol
                            }`}
                                        </div>
                                    ) : null
                                ) : null}
                                {renderUniqueExchangeDetails()}
                            </>
                        )}
                        <div className='exchane-form-btn'>
                            <Button
                                condition={true}
                                title={buttonName}
                                containerStyle={{ marginTop: 32 }}
                                onPress={nextStep}
                                loader={loader}
                            />
                        </div>
                    </>
                ) : (
                    <div className='exchane-form-loading'>
                        <img src={Loader} alt='loader' />
                    </div>
                )}
            </div>
            {!mainPage ? (
                state.saveUkraine ? null : (
                    <div className='apply-terms'>
                        {strings('exchangeForm.apllyTerms', { button: buttonName })}
                        <a href={(props.locale === 'ru' || props.locale === 'uk') ? config?.termsRu || 'https://drive.google.com/file/d/17t1kyiADJ6rBWo9meMGR8maQXvmwS9CG/view?usp=sharing' : config?.termsEn || 'https://drive.google.com/file/d/1BZmNYiHaoZ3hSjLgzXQCQWUiwuukyy8w/view?usp=sharing'}>{strings('exchangeForm.termOfUse')}</a>
                    </div>
                )
            ) : null}
            {state.widgetMode ? (
                <ModalComponent show={dataOrder}>
                    <div className='widget-modal-header'>
                        {strings('exchangeForm.attention')}
                        <div className='widget-modal-desciption'>
                            {dataOrder?.error
                                ? strings('exchangeForm.createOrderError')
                                : strings('exchangeForm.orderCreated')}
                        </div>
                    </div>

                    <div className='widget-modal-button'>
                        <Button
                            condition={true}
                            title={strings('exchangeForm.tracking')}
                            onPress={dataOrder?.error ? orderError : goToOrder}
                        />
                    </div>
                </ModalComponent>
            ) : null}
            <ReCAPTCHA
                ref={reCaptchaRef}
                size='invisible'
                sitekey={config.captchaSiteKey}
                style={{
                    position: 'absolute',
                    top: '20%',
                    left: 0,
                    zIndex: 9000,
                    display: state.widgetMode ? 'none' : 'flex'
                }}
            />
        </ExchangeFormDiv>
    )
}

export default ExchnageFormComponent