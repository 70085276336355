import config from '../../../config/config'
import { axiosRequest } from '../../../services/request'

export default async function getQrValue(code, depositAddress, inAmount) {
    try {
        const link = `${config.backendUrl}/exchange-way/address-qr-string?address=${depositAddress}&currencyCode=${code}&amount=${inAmount}`
        let linkForQR = ''
        linkForQR = await axiosRequest(link)
        return linkForQR?.addressQrString
    } catch (e) {
        console.log(e)
    }
}
