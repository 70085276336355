import { useEffect, useState } from 'react'
import { lightTheme } from './colorsLight'
import { darkTheme } from './colorsDark'

export const useDarkMode = () => {
    const [theme, setTheme] = useState('light')
    const [mountedComponent, setMountedComponent] = useState(false)

    const setMode = (mode) => {
        window.localStorage.setItem('themeMode', mode)
        setTheme(mode)
    }

    const themeToggler = () => {
        theme === 'light' ? setMode('dark') : setMode('light')
    }

    useEffect(() => {
        const localTheme = window.localStorage.getItem('themeMode')
        localTheme && setTheme(localTheme)
        setMountedComponent(true)
    }, [])

    const themeMode = theme === 'light' ? lightTheme : darkTheme

    return [themeMode, theme, themeToggler, mountedComponent]
}

export const getThemeModeStyles = () => {
    const localTheme = window.localStorage.getItem('themeMode')
    if (localTheme) {
        return localTheme === 'light' ? lightTheme : darkTheme
    } else {
        return lightTheme
    }
}

export const getThemeMode = () => {
    const localTheme = window.localStorage.getItem('themeMode')
    return localTheme || 'light'
}
