import styled from 'styled-components'

const PhotoUploadDiv = styled.div`
    margin-top: 40px;

    display: flex;
    flex-direction: row;

    margin-left: 39px;
    width: calc(100% - 78px);

    .photo-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        margin-left: 16px;
        margin-bottom: 11px;
        width: 100%;
    }

    .photo-container-btn {
        width: 100%;
        max-width: 236px;
    }

    .photo-qr {
        padding: 15px 15px 13px 15px;
        background: #f7f7f7;
        border-radius: 14px;
        height: 100%;
    }

    .photo-qr-text {
        margin-top: 10px;
        text-align: start;

        font-family: Fira Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1em;
        line-height: 150%;

        color: #5c5c5c;
    }

    @media screen and (max-width: 768px) {
        justify-content: center;

        margin-top: 20px;

        .photo-container {
            margin-left: 0;
            margin-bottom: 0;
        }

        .photo-qr,
        .photo-qr-text {
            display: none;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1280px) {
        .photo-container-btn {
            margin-top: 10px;
        }
    }
`

export default PhotoUploadDiv
