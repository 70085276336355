import React,{ useCallback } from 'react'
import WhatComponentDiv from './style.css'
import What from '../../../images/what-img.png'
import WhatMobile from '../../../images/trusteeSwap_mobile.png'
import { useState } from 'react'
import { strings } from '../../../i18n'
import AnimateHeight from 'react-animate-height'
import ArrowApp from '../../../images/arrow-purple.png'

const WhatComponent = (props) => {
 
    const[height, setHeight] = useState(false);
    const checkHeight = useCallback(() => setHeight(!height),[height])

    return (
        <WhatComponentDiv>
            <div className='what-container isDesktop'>
            <picture className='what-img'>
                    <source
                        srcSet={WhatMobile}
                        media='(max-width: 640px)'
                    />
                <img src={What} alt='img' />
            </picture>
                <h2 className='what-title'>{strings('exchanger.whatExchangesTitle')}</h2>
                <div className='what-text'>
                    <p>{strings('exchanger.whatExchangesContentFirstStep')} </p>
                    <p>{strings('exchanger.whatExchangesContentSecondStep')} </p>
                    <p>{strings('exchanger.whatExchangesContentThirdStep')} </p>
                    <p>{strings('exchanger.whatExchangesContentFourthStep')} </p>
                    </div>
            </div>
            <div className='what-container isMobile'>
                <h2 className='what-title'>{strings('exchanger.whatExchangesTitle')}</h2> 
                <picture className='what-img'>
                <source
                        srcSet={WhatMobile}
                        media='(max-width: 640px)'
                    />
                    <img src={WhatMobile} alt='phone-block' />
                </picture>
                <AnimateHeight duration={300} height={!height ? 200 : "auto"}>
                <div className="what-text">
                    <p>{strings('exchanger.whatExchangesContentFirstStep')} </p>
                    <p>{strings('exchanger.whatExchangesContentSecondStep')} </p>
                    <p>{strings('exchanger.whatExchangesContentThirdStep')} </p>
                    <p>{strings('exchanger.whatExchangesContentFourthStep')} </p>
                </div>
                </AnimateHeight>
                <div className='button-more' onClick={checkHeight}>
                {strings('exchanger.whatButton')} 
                    <img src={ArrowApp} alt='arrow' />
                </div>
            </div>
        </WhatComponentDiv>
    )
}

export default WhatComponent
