import React from 'react'

import FooterDiv from './style.css.js'
import { getThemeModeStyles } from '../../theme/useDarkMode'

import { strings } from '../../i18n'
import Monitoring from './Monitoring/Monitoring.jsx'
import Service from './elements/Service.jsx'
import config from '../../config/config.js'

const FooterComponent = (props) => {
    const theme = getThemeModeStyles()

    const currentYear = new Date().getFullYear()

    const aboutUs = [
        {
            id: 'service_1',
            key: 'chart'
        },
        {
            id: 'service_2',
            key: 'type'
        },
        {
            id: 'service_3',
            key: 'terms',
            href: props.locale === 'ru' || props.locale === 'uk' ? config?.termsRu || 'https://drive.google.com/file/d/17t1kyiADJ6rBWo9meMGR8maQXvmwS9CG/view?usp=sharing' : 
            config?.termsEn || 'https://drive.google.com/file/d/1BZmNYiHaoZ3hSjLgzXQCQWUiwuukyy8w/view?usp=sharing'
        },
        {
            id: 'service_4',
            key: 'mail',
            href: 'mailto:contact@trustee.deals'
        }
    ]

    const information = [
        {
            id: 'item_1',
            key: 'cryptocurrencies',
            href: 'https://blog.trusteeglobal.com/ru/category/criptovalyuty/'
        },
        {
            id: 'item_2',
            key: 'instruction',
            href: 'https://blog.trusteeglobal.com/ru/category/gajdy/'
        },
        {
            id: 'item_3',
            key: 'earn',
            href: 'https://blog.trusteeglobal.com/ru/category/zarabotok-na-kripte/'
        },
        {
            id: 'item_4',
            key: 'twitter',
            href: 'https://twitter.com/Trustee_Wallet'
        },
        {
            id: 'item_5',
            key: 'telegram',
            href: 'https://t.me/trustee_deals'
        },
        {
            id: 'item_6',
            key: 'facebook',
            href: 'https://www.facebook.com/Trustee.Wallet/'
        }
    ]

    const wallet = [
        {
            id: 'wallet_1',
            key: 'BTC-wallet',
            href: 'https://trusteeglobal.com/ru/crypto-wallet/bitcoin/'
        },
        {
            id: 'wallet_2',
            key: 'ETH-wallet',
            href: 'https://trusteeglobal.com/ru/crypto-wallet/ethereum/'
        },
        {
            id: 'wallet_3',
            key: 'TRX-wallet',
            href: 'https://trusteeglobal.com/ru/crypto-wallet/tron/'
        },
        {
            id: 'wallet_4',
            key: 'USDT-wallet',
            href: 'https://trusteeglobal.com/ru/crypto-wallet/tether/'
        }
    ]

    return (
        <FooterDiv
            footerBg={theme.footer.bg}
            itemColors={theme.footer.itemColor}
            reservedColor={theme.footer.reservedColor}>
            <footer className='footer'>
                <div className='links'>
                    <div className='other-links-wrapper'>
                        <div className='aboutUs'>
                            <div className='other-links-title'>
                                {strings('footer.service.title')}
                            </div>
                            <Service data={aboutUs} i18key='service' />
                            <div className='links-wrapper desktop'>
                                <div className='links-wrapper-item-blocksoft'>
                                    <div className='links-content'>
                                        Trustee | Swap
                                    </div>
                                    <div className='links-wrapper-item-right-reserved'>
                                        ©{' '}
                                        {strings('footer.rightsReserved', {
                                            currentYear
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='information'>
                            <div className='other-links-title'>
                                {strings('footer.information.title')}
                            </div>
                            <Service data={information} i18key='information' />
                        </div>
                        <div className='cryptocurrency-wallet'>
                            <div className='other-links-title'>
                                {strings('footer.wallet.title')}
                            </div>
                            <Service data={wallet} i18key='wallet' />
                        </div>
                        <div className='links-monitoring'>
                            <div className='monitoring-title'>
                                {strings('footer.monitoring.title')}
                            </div>
                            <Monitoring />
                        </div>
                        <div className='links-wrapper mobile'>
                            <div className='links-wrapper-item-blocksoft'>
                                <div className='links-content'>
                                    Trustee | Swap
                                </div>
                                <div className='links-wrapper-item-right-reserved'>
                                    ©{' '}
                                    {strings('footer.rightsReserved', {
                                        currentYear
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className='links-wrapper'>
                            <div className='links-wrapper-item'>
                                <a
                                    href='https://trustee.deals/faq'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    {strings('footer.faq')}
                                </a>
                            </div>
                            <div className='links-wrapper-item'>
                                <a
                                    href='mailto:contact@trustee.deals'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    {strings('footer.contactUs')}
                                </a>
                            </div>
                            <div className='links-wrapper-item'>
                                <a
                                    href='https://docs.google.com/document/d/1HqK6qRWyZkGdOLTOYTynTFJ9tesvqIuQjhyF6nn16bM/edit'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    {strings('footer.privacyPolicy')}
                                </a>

                            </div>
                            <div className='links-wrapper-item last'>
                                <a
                                    href='https://docs.google.com/document/d/1xvDZuqeAliCoX-_FUvjq_MmOIu64oWNz0623dcxEL_8/edit'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    {strings('footer.termOfUse')}
                                </a>
                            </div>
                        </div> */}
                </div>
            </footer>
        </FooterDiv>
    )
}

export default FooterComponent
