import {
    INIT,
    EXCHANGE_WAY,
    CURRENCY_COIN_ACTION,
    SWAP_COIN,
    IN_CURRENCY_SELECT,
    OUT_CURRENCY_SELECT,
    IN_CURRENCY_SEARCH,
    OUT_CURRENCY_SEARCH,
    PAYWAY_SEARCH,
    PAYWAY_LIST_ACTION,
    PAYWAY_ACTION,
    OUT_DESTINATION_ACTION,
    EMAIL_ACTION,
    PAYMENT_DATA_ACTION,
    UNIQUE_PARAMS_VALID,
    AMOUNT_ACTION,
    ERROR_ACTION,
    EXCHANGE_WAYS_ACTION,
    FILE_INPUT_ACTION,
    UPLOAD_CARD_PHOTO_ACTION,
    CARD_PHOTO_ACTION,
    CARD_VERIFY_ACTION,
    LOADER_ACTION,
    OUT_DESTINATION_TAG_ACTION,
    WIDGET_MODE,
    RESET_DATA,
    EXTERNAL_ID_ACTION,
    SAVE_UKRAINE_ACTION
} from './type'

const INITIAL_STATE = {
    exchangeWay: null,

    inCurrency: null,
    outCurrency: null,
    payway: null,
    cashbackToken: null,
    userToken: null,

    allActiveExchangeWays: null,
    preRequestExchagneWays: null,
    activeInCurrency: null,
    activeOutCurrency: null,
    paywayList: null,
    limits: null,

    locale: null,

    inCurrencySearchValue: null,
    outCurrencySearchValue: null,
    paywaySearchValue: null,

    outDestination: null,
    outDestinationNotValid: false,
    outDestinationTag: null,
    email: null,
    emailNotValid: false,
    paymentData: null,
    paymentDataNotValid: false,

    amount: null,
    amountSide: null,
    amountNotValid: false,
    amountTimerId: null,
    minMaxLimits: null,
    equivalentData: null,

    inAmountError: null,
    outAmountError: null,
    paywayError: null,
    equivalentError: null,

    fileData: null,
    status: null,

    editAmount: null,
    editEmail: null,
    editOutDestination: null,
    editOutCurrency: null,
    exchangeWayId: null,
    externalId: null,
    callbackUrl: null,
    redirectUrl: null,
    loading: true,

    widgetMode: null,
    saveUkraine: false
}

const exchangeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case INIT:
            return {
                ...state,
                inCurrency: action.inCurrency,
                outCurrency: action.outCurrency,
                payway: action.payway,
                exchangeWay: action.exchangeWay,
                locale: action.locale,
                cashbackToken: action.cashbackToken,
                userToken: action.userToken,
                preRequestExchagneWays: action.preRequestExchagneWays,
                allActiveExchangeWays: action.allActiveExchangeWays,
                limits: action.limits,
                amount: action.amount,
                amountSide: action.amountSide,
                outDestination: action.outDestination,
                email: action.email,
                editAmount: action.editAmount,
                editEmail: action.editEmail,
                editOutDestination: action.editOutDestination,
                exchangeWayId: action.exchangeWayId,
                externalId: action.externalId,
                callbackUrl: action.callbackUrl,
                redirectUrl: action.redirectUrl,
                loading: action.loading,
                editOutCurrency: action.editOutCurrency,
                widgetMode: action.widgetMode,
                saveUkraine: action.saveUkraine
            }
        case WIDGET_MODE:
            return {
                ...state,
                widgetMode: action.widgetMode
            }
        case EXCHANGE_WAY:
            return {
                ...state,
                exchangeWay: action.exchangeWay,
                amount: null,
                amountSide: null,
                equivalentData: null,
                payway: action.payway,
                inCurrency: action.inCurrency,
                outCurrency: action.outCurrency,
                outDestinationNotValid: false,
                emailNotValid: false,
                paymentDataNotValid: false,
                inAmountError: null,
                outAmountError: null,
                paywayError: null,
                equivalentError: null,
                amountNotValid: null
            }
        case CURRENCY_COIN_ACTION:
            return {
                ...state,
                activeInCurrency: action.activeInCurrency,
                activeOutCurrency: action.activeOutCurrency
            }
        case PAYWAY_LIST_ACTION:
            return {
                ...state,
                paywayList: action.paywayList
            }
        case SWAP_COIN:
            return {
                ...state,
                inCurrency: state.outCurrency,
                outCurrency: state.inCurrency,
                amount: null,
                amountSide: null,
                equivalentData: null
            }
        case IN_CURRENCY_SELECT:
            return {
                ...state,
                inCurrency: action.inCurrency,
                exchangeWay: action.exchangeWay,
                amount: null,
                amountSide: null,
                equivalentData: null
            }
        case OUT_CURRENCY_SELECT:
            return {
                ...state,
                outCurrency: action.outCurrency,
                exchangeWay: action.exchangeWay,
                amount: null,
                amountSide: null,
                equivalentData: null
            }
        case IN_CURRENCY_SEARCH:
            return {
                ...state,
                inCurrencySearchValue: action.inCurrencySearchValue
            }
        case OUT_CURRENCY_SEARCH:
            return {
                ...state,
                outCurrencySearchValue: action.outCurrencySearchValue
            }
        case PAYWAY_SEARCH:
            return {
                ...state,
                paywaySearchValue: action.paywaySearchValue
            }
        case PAYWAY_ACTION:
            return {
                ...state,
                payway: action.payway,
                paymentData: action.paymentData,
                amount: null,
                amountSide: null,
                equivalentData: null
            }

        case OUT_DESTINATION_ACTION:
            return {
                ...state,
                outDestination: action.outDestination,
                outDestinationNotValid: false
            }
        case OUT_DESTINATION_TAG_ACTION:
            return {
                ...state,
                outDestinationTag: action.outDestinationTag
            }
        case EMAIL_ACTION:
            return {
                ...state,
                email: action.email,
                emailNotValid: false
            }
        case PAYMENT_DATA_ACTION:
            return {
                ...state,
                paymentData: action.paymentData,
                paymentDataNotValid: false
            }
        case UNIQUE_PARAMS_VALID:
            return {
                ...state,
                outDestinationNotValid: action.outDestinationNotValid,
                emailNotValid: action.emailNotValid,
                paymentDataNotValid: action.paymentDataNotValid
            }
        case AMOUNT_ACTION:
            return {
                ...state,
                amount: action.amount,
                amountSide: action.amountSide,
                amountTimerId: action.TimerId,
                amountNotValid: action.amountNotValid,
                equivalentData: action.equivalentData
            }
        case ERROR_ACTION:
            return {
                ...state,
                inAmountError: action.inAmountError,
                outAmountError: action.outAmountError,
                paywayError: action.paywayError,
                equivalentError: action.equivalentError
            }
        case EXCHANGE_WAYS_ACTION:
            return {
                ...state,
                allActiveExchangeWays: action.allActiveExchangeWays,
                limits: action.limits,
                inAmountError: null,
                outAmountError: null,
                paywayError: null,
                equivalentError: null,
                amountNotValid: null
            }
        case FILE_INPUT_ACTION:
            return {
                ...state,
                paymentData: {
                    ...state.paymentData,
                    fileData: action.fileData,
                    fileName: action.fileName
                }
            }
        case UPLOAD_CARD_PHOTO_ACTION:
            return {
                ...state,
                paymentDataNotValid: false,
                paymentData: {
                    ...state.paymentData,
                    status: action.status
                }
            }
        case CARD_PHOTO_ACTION:
            return {
                ...state,
                paymentData: {
                    ...state.paymentData,
                    status: 'WAIT_FOR_PHOTO'
                }
            }
        case CARD_VERIFY_ACTION:
            return {
                ...state,
                paymentData: {
                    ...action.paymentData,
                    status: action.status,
                    firstName: action.firstName,
                    lastName: action.lastName
                }
            }
        case LOADER_ACTION:
            return {
                ...state,
                loading: action.loading
            }
        case RESET_DATA:
            return {
                ...state,
                amount: null,
                amountSide: null,
                equivalentData: null,
                paymentData: null,
                inAmountError: null,
                outAmountError: null,
                paywayError: null,
                equivalentError: null,
                amountNotValid: null,
                outDestination: null,
                outDestinationNotValid: false,
                outDestinationTag: null,
                email: false,
                emailNotValid: false,
                paymentDataNotValid: false
            }
        case EXTERNAL_ID_ACTION:
            return {
                ...state,
                externalId: action.externalId
            }
        case SAVE_UKRAINE_ACTION:
            return {
                ...state,
                saveUkraine: action.saveUkraine
            }
        default:
            return {
                ...state
            }
    }
}

export default exchangeReducer
