import styled from 'styled-components'

const HeaderDiv = styled.div`
    z-index: 400;
    background: ${(p) => p.bg};

    .header {
        background: ${(p) => p.bg};
        height: 4.7em;
        width: 100%;
    }

    .nav {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        width: 100%;
        max-width: 2010px;
        height: 100%;
        margin: 0 auto;
    }

    .logo-mobile {
        display: none;
    }

    .lang {
        position: relative;
    }

    .lang-selector {
        height: 40px;
        width: 131px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        border: 0.12em solid ${(p) => p.selectorBorder};
        background: #dadada;

        box-sizing: border-box;
        border-radius: 13px;

        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 1em;
        line-height: 120%;

        text-align: center;
        letter-spacing: 0.12em;
        text-transform: uppercase;

        color: ${(p) => p.selectorColor};

        padding: 0 16px;

        cursor: pointer;
    }

    .lang-selector-select {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        background: transparent;
        border: none;
        outline: none;
    }

    .menu {
        background: #dadada;
        border-radius: 0.76em;
        position: absolute;
        top: 3.53em;
        right: 0;
        width: 8.12em;
        box-shadow: 0 0.05em 0.47em rgba(0, 0, 0, 0.3);
        opacity: 0;
        visibility: hidden;
        transform: translateY(-1.18em);
        transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
        -webkit-tap-highlight-color: transparent;
        outline: none;

        z-index: 3000;

        &.active {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);

            z-index: 3000;
        }
    }

    .menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
    }

    .menu li {
        text-decoration: none;
        color: #333333;
        padding: 15px 20px;
        display: block;
    }

    .menu li:hover {
        background: rgba(64, 64, 64, 0.2);
    }

    @media screen and (max-width: 640px) {
        .header {
            width: calc(100% - 32px);
            margin-left: 0.94em;
        }

        .logo-desktop {
            display: none;
        }

        .logo-mobile {
            display: flex;
        }
    }

    @media screen and (min-width: 640px) and (max-width: 1280px) {
        .header {
            width: calc(100% - 64px);
            margin-left: 32px;
        }
    }

    @media screen and (min-width: 1280px) and (max-width: 1920px) {
        .header {
            max-width: 1266px;
            margin: 0 auto;
        }
    }
`

export default HeaderDiv
