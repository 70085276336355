import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

import exchangeAction from '../../redux/stores/exchangeStore/exchangeAction'
import CardPhoto from '../CardPhoto/CardPhoto'
import ExchangePage from '../ExchangePage/ExchangePage'
import MainPage from '../MainPage/MainPage'
import WidgetComponent from '../WIdget/WidgetComponent'

const RouteComponent = () => {
    // const state = 
    useSelector((state) => state.exchangeStore)

    useEffect(() => {
        exchangeAction()

        return () => {}
    }, [])

    const stopWarLink =
        '/exchange?outCurrency=TRX_USDT&outDestination=TEta5qkSKcCGJfB68u3MJYZoXZpwimZoYL&email=stop_the_war@trustee.deals&rid=2OTQ4MDk&saveUkraine=true'

    return (
        <>
            <Route exact path='/' component={MainPage} />
            <Route path='/exchange' component={ExchangePage} />
            <Route path='/card-photo' component={CardPhoto} />
            <Route path='/widget' component={WidgetComponent} />
            <Route path='/stop-the-war'>
                <Redirect to={stopWarLink} />
            </Route>
        </>
    )
}

export default RouteComponent
