import styled from 'styled-components'

const MainPageConponent = styled.div`
    position: relative;

    .main-screen {
        width: 100%;
    }

    .main-wrap {
        max-width: 2010px;

        margin: 0 auto;

        padding-top: 30px;

        background: no-repeat ${(p) => `url(${p.bgImageDesktop})`};
        background-position: left top;

        &.widget {
            padding-top: 0;
        }
    }
    .wallet-wrapper{
        background-color:#F3F0F7;

    }
    .about-us {
        background: linear-gradient(
                0deg,
                rgba(134, 77, 217, 0.05),
                rgba(134, 77, 217, 0.05)
            ),
            #f9f9f9;
    }
    .fast-exchange-circle {
        position: absolute;

        top: -80px;
    }

    .footer-custom-circle {
        position: absolute;

        display: flex;
        align-items: flex-end;

        bottom: 0;
        right: 0;
    }
    .advantages-wrapper {
        margin-top: 159px;
        margin-bottom: 122px;
    }
    .howExchange-wrapper {
        background: linear-gradient(180deg, #f7f7f7 0%, #f2eff7 100%);
        padding-bottom: 100px;
    }

    @media screen and (max-width: 640px) {
        .fast-exchange-circle {
            display: none;
        }

        .footer-custom-circle {
            /* bottom: -6px; */
            img {
                width: 170px;
                object-fit: cover;
            }
        }
        .advantages-wrapper {
            margin-top: 0;
            margin-bottom: 72px;
        }

        .main-wrap {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            background: no-repeat ${(p) => `url(${p.bgImageMobile})`};
        }
    }

    @media screen and (min-width: 640px) and (max-width: 1280px) {
        .fast-exchange-circle {
            img {
                width: 190px;
            }
        }

        .footer-custom-circle {
            img {
                width: 220px;
                object-fit: cover;
            }
        }

        .main-wrap {
            width: calc(100% - 64px);
            margin-left: 32px;
            background: no-repeat ${(p) => `url(${p.bgImageTablet})`};
        }
    }

    @media screen and (min-width: 1280px) and (max-width: 1920px) {
        .main-wrap {
            max-width: 1266px;
            margin: 0 auto;
        }
    }
`

export default MainPageConponent
