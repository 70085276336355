import styled from 'styled-components'

const ModalDiv = styled.div`
    .widget-modal-container {
        position: absolute; /* Stay in place */
        z-index: 400; /* Sit on top */
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        outline: 0;
        width: auto; /* Full width */
        height: 100vh; /* Full height */
        overflow: hidden; /* Enable scroll if needed */
        background-color: rgb(0, 0, 0); /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

        -webkit-animation-name: animatetop;
        -webkit-animation-duration: 0.25s;
        -webkit-animation-timing-function: ease-in-out;

        animation-name: animatetop;
        animation-duration: 0.25s;
        animation-timing-function: ease-in-out;
    }

    .widget-modal-content {
        width: 100%;
        max-width: 313px;
        min-height: 213px;
        background-color: #e8e8e8;
        border-radius: 16px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        padding-top: 40px;

        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    /* Add Animation */
    @-webkit-keyframes animatetop {
        from {
            top: -300px;
            opacity: 0;
        }
        to {
            top: 0;
            opacity: 1;
        }
    }

    @keyframes animatetop {
        from {
            top: -300px;
            opacity: 0;
        }
        to {
            top: 0;
            opacity: 1;
        }
    }

    .widget-modal-header {
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-size: 19px;
        line-height: 25px;
        text-align: center;
        color: #404040;
    }

    .widget-modal-desciption {
        font-family: 'SF UI Display Regular';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0.5px;
        color: #404040;
        width: 80%;
        margin: 0 auto;
        margin-top: 8px;
    }

    .widget-modal-button {
        margin: 32px 16px 40px 16px;
    }

    /* .widget-modal-btn {
        border-radius: 10px;
        width: 100%;
        height: 50px;
        border: none;
        outline: none;
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.5px;
        color: #F7F7F7;
        display: flex;
        justify-content: center;
        align-items: center;
    } */
`

export default ModalDiv
