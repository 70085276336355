import React from 'react'
import Helmet from 'react-helmet'
import ImageGallery from 'react-image-gallery'

import { handleDownloadLink } from '../../modules/ExchangeForm/services/handleDownloadLink'

import Item1ru from '../../images/creatives/1_1_2.png'
import Item1uk from '../../images/creatives/1_1_2ua.png'
import Item1en from '../../images/creatives/1_1_2en.png'

import Item2ru from '../../images/creatives/2_2_2.png'
import Item2uk from '../../images/creatives/2_2_2ua.png'
import Item2en from '../../images/creatives/2_2_2en.png'

import Item3ru from '../../images/creatives/3_1_3.png'
import Item3uk from '../../images/creatives/3_1_3ua.png'
import Item3en from '../../images/creatives/3_1_3en.png'

import Item4ru from '../../images/creatives/3_2.png'
import Item4uk from '../../images/creatives/3_2ua.png'
import Item4en from '../../images/creatives/3_2en.png'

import CarouselDiv from './style.css'

function mixarr(arr) {
    return arr
        .map((i) => [Math.random(), i])
        .sort()
        .map((i) => i[1])
}

const CarouselCompoent = (props) => {
    const { random } = props

    const locale = localStorage.getItem('locale')

    let creativiesArray = [
        {
            original:
                locale === 'en' ? Item1en : locale === 'uk' ? Item1uk : Item1ru,
            originalClass: 'carousel-item'
        },
        {
            original:
                locale === 'en' ? Item2en : locale === 'uk' ? Item2uk : Item2ru,
            originalClass: 'carousel-item'
        },
        {
            original:
                locale === 'en' ? Item3en : locale === 'uk' ? Item3uk : Item3ru,
            originalClass: 'carousel-item'
        },
        {
            original:
                locale === 'en' ? Item4en : locale === 'uk' ? Item4uk : Item4ru,
            originalClass: 'carousel-item'
        }
    ]

    if (random) {
        creativiesArray = mixarr(creativiesArray)
    }

    return (
        <CarouselDiv>
            <Helmet>
                <link
                    rel='preload'
                    href={creativiesArray[0].original}
                    as='image'
                    type='image/svg+xml'
                />
                <link
                    rel='preload'
                    href={creativiesArray[1].original}
                    as='image'
                    type='image/svg+xml'
                />
                <link
                    rel='preload'
                    href={creativiesArray[2].original}
                    as='image'
                    type='image/svg+xml'
                />
                <link
                    rel='preload'
                    href={creativiesArray[3].original}
                    as='image'
                    type='image/svg+xml'
                />
            </Helmet>
            <ImageGallery
                items={creativiesArray}
                showNav={false}
                showThumbnails={false}
                autoPlay={true}
                showFullscreenButton={false}
                showPlayButton={false}
                slideInterval={5000}
                slideDuration={400}
                onClick={handleDownloadLink}
                disableThumbnailScroll={false}
            />
        </CarouselDiv>
    )
}

export default CarouselCompoent
