import React from 'react'
import { strings } from '../../i18n'
import HowExchangeDiv from './style.css'
import Arrows from '../../images/arrows.png'
import Coins from '../../images/coins.png'
import Check from '../../images/check.png'
import ApproveTX from '../../images/approveTX.png'
import userData from '../../images/userData.png'

const HowExchangeComponent = (props) => {
    const contentArray = [
        {
            id: 1,
            title: strings('exchanger.exchangeStepTitle'),
            content: strings('exchanger.exchangeStepContent'),
            image: Arrows
        },
        {
            id: 2,
            title: strings('exchanger.exchangePairTitle'),
            content: strings('exchanger.exchangePairContent'),
            image: Coins,
            rightPosititon: true
        },
        {
            id: 3,
            title: strings('exchanger.exchangeDetailsTitle'),
            content: strings('exchanger.exchangeDetailsContent'),
            image: userData
        },
        {
            id: 4,
            title: strings('exchanger.exchangeCheckTitle'),
            content: strings('exchanger.exchangeCheckContent'),
            image: Check,
            rightPosititon: true
        },
        {
            id: 5,
            title: strings('exchanger.exchangeConfirmTitle'),
            content: strings('exchanger.exchangeConfirmContent'),
            image: ApproveTX,
            checklastblock: true
        }
    ]

    const renderItem = (item, index) => {
        return (
            <div key={item.id}>
                <div
                    className={`exchange-steps ${
                        index % 2 !== 0 ? 'right' : ''
                    }`}>
                    <div className='exchange-item'>
                        {item.rightPosititon ? (
                            <div className='exchange-content-right'>
                                <img src={item.image} alt='' />
                                <div className='exchange-title'>
                                    {item.title}
                                </div>
                            </div>
                        ) : (
                            <>
                                <img src={item.image} alt='' />
                                <div className='exchange-title'>
                                    {item.title}
                                </div>
                            </>
                        )}
                        <div
                            className={`exchange-text ${
                                item.rightPosititon ? 'text-right' : ''
                            }`}>
                            {item.content}
                        </div>
                    </div>
                    <div className='content-item'>
                        <div className='line-wrapper'>
                            <div className='content-item-circle'>{item.id}</div>
                            <div
                                className={`line-item ${
                                    item.checklastblock ? 'last' : ''
                                }`}
                            />
                        </div>
                    </div>
                    <div className='exchange-empty' />
                </div>
            </div>
        )
    }

    return (
        <HowExchangeDiv>
            <div>
                <h2>{strings('exchanger.howExchange')}</h2>
                <div className='description'>
                    {strings('exchanger.exchangeDescription')}
                </div>
                <div className='content'>
                    <div className='exchange-wrapper'>
                        {contentArray.map((item, index) => {
                            return renderItem(item, index)
                        })}
                    </div>
                </div>
            </div>
        </HowExchangeDiv>
    )
}

export default HowExchangeComponent
