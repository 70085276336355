import styled from 'styled-components'

const FaqSectionDiv = styled.div`
    .info-item {
        /* justify-content: center; */
        align-items: left;
        justify-content: center;
        margin-top: 56px;
        display: flex;
        max-width: 750px;
        flex-direction: column;
        margin-bottom: 40px;
    }
    .info-title {
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 130%;
        text-align: left;
        color: #404040;
    }
    .info-content {
        font-family: 'Fira Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 150%;
        text-align: left;
        margin-top: 8px;
        padding-right: 10px;
        color: #5c5c5c;
    }
    .buttonFaq {
        cursor: pointer;
        width: 32px;
        height: 32px;
        background-color: #dadada;
        border-radius: 50px;
        justify-content: center;
        margin-left: 20px;

        &.open {
            transform: rotate(0deg);
            transition: transform 300ms;
        }
        &.close {
            transform: rotate(180deg);
            transition: transform 300ms;
        }
    }
    .buttonFaq img {
        margin: 0 auto;
        padding-top: 11px;
        display: block;
        width: 12px;
    }
    .button-wrap {
        justify-content: space-between;
    }
    .isMobile {
        display: none;
    }
    @media screen and (max-width: 640px) {
        .isMobile {
            display: flex;
        }
        .info-title {
            font-size: 18px;
            display: flex;
            margin-right: 16px;
            width: 97%;
            justify-content: space-between;
        }
        .info-content {
            font-size: 17px;
        }
        .info-item {
            padding: 0 10px;
        }
        .isDesktop {
            display: none;
        }
    }

    @media screen and (min-width: 640px) and (max-width: 1280px) {
        .isDesktop {
            margin:0 30px;
        }
    }

    @media screen and (min-width: 1280px) and (max-width: 1920px) {
        max-width: 1266px;
        margin: 0 auto;
    }
`
export default FaqSectionDiv
