import React, { useState } from 'react'
import axios from 'axios'
import QRCode from 'qrcode.react'
import Resizer from 'react-image-file-resizer'

import PhotoUploadDiv from './style.css'
import config from '../../../../config/config'
import { strings } from '../../../../i18n'
import Button from '../../../../components/Button/Button'
import ModalWindow from '../ModalWindow/modal'

import Logo from '../../../../images/icon/favicon.png'
import store from '../../../../redux'
import {
    fileInputAction,
    uploadCardPhotoAction
} from '../../../../redux/stores/exchangeStore/action'
import PhotoExample from './PhotoExample/PhotoExample'

const { dispatch } = store

const PhotoUpload = (props) => {
    const [show, setShow] = useState(false)
    const [count, setCount] = useState(0)

    const { state, reCaptchaRef } = props

    if (
        !state.paymentData ||
        (state.paymentData.status !== 'WAIT_FOR_PHOTO' &&
            state.paymentData.status !== 'NO_INFO' &&
            state.paymentData.status !== 'CANCELED')
    ) {
        return null
    }

    const uploadCardPhotoButtonHandler = async (state) => {
        try {
            const { number, fileData, countryCode } = state.paymentData

            if (!number) {
                throw new Error('Invalid card number')
            }
            if (!fileData) {
                throw new Error('Invalid file')
            }
            reCaptchaRef.current.reset()
            const captchaToken = await reCaptchaRef.current.executeAsync()

            const body = new FormData()
            body.set('cardNumber', number)
            body.set('locale', state.locale)
            body.set('countryCode', countryCode)
            body.set('captchaToken', captchaToken)
            body.append('image', fileData)

            const response = await axios.post(
                config.backendUrl + '/payment-details/validate-card',
                body,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }
            )

            setCount(0)
            setShow(false)
            dispatch(uploadCardPhotoAction(response.data.verificationStatus))
        } catch (error) {
            // alert(error.message)
            setCount(0)
            console.log(error.response.data)
            reCaptchaRef.current.reset()
            return
        }
    }

    const fileInputHandler = async (event) => {
        const file = event.target.files[0]

        const fileData = await new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1920,
                1080,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri)
                },
                'base64'
            )
        })
        dispatch(fileInputAction(fileData, file.name))
    }

    return (
        <PhotoUploadDiv>
            <div className='photo-qr'>
                <QRCode
                    value={
                        config.baseUrl +
                        '/card-photo?cardNumber=' +
                        state.paymentData.number +
                        '&countryCode=' +
                        state.paymentData.countryCode +
                        '&scheme=' +
                        state.paymentData.scheme +
                        '&locale=' +
                        state.locale
                    }
                    size={164}
                    level='Q'
                    bgColor='transparent'
                    imageSettings={{
                        src: Logo,
                        width: 20,
                        height: 20
                    }}
                />
            </div>
            <div className='photo-container'>
                <div className='photo-qr-text'>
                    {strings('exchangeForm.uploadQR')}
                </div>

                <div className='photo-container-btn'>
                    <Button
                        title={strings('exchangeForm.chooseFile')}
                        condition={true}
                        onPress={() => setShow(!show)}
                    />
                </div>
                <ModalWindow show={show} close={() => setShow(!show)}>
                    <PhotoExample
                        condition={
                            state.paymentData.fileData || count !== 0
                                ? true
                                : false
                        }
                        uploadAction={async () => {
                            setCount(1)
                            await uploadCardPhotoButtonHandler(state)
                        }}
                        fileInputHandler={fileInputHandler}
                        fileData={state.paymentData.fileData || false}
                        fileName={state.paymentData.fileName || false}
                        deleteFileAction={() =>
                            dispatch(fileInputAction(null, null))
                        }
                        modal={true}
                    />
                </ModalWindow>
            </div>
        </PhotoUploadDiv>
    )
}

export default PhotoUpload
