import store from '../../../redux/index'
import { currencyCoinAction } from '../../../redux/stores/exchangeStore/action'

const { dispatch } = store

const isActiveCoin = (props) => {
    const state = store.getState().exchangeStore

    if (!state.preRequestExchagneWays) {
        return
    }

    // inCurrencies
    const inCurrencyCode = state.outCurrency
        ? Array.from(
              new Set(
                  state.preRequestExchagneWays
                      .filter(
                          (way) =>
                              way.outCurrency.code === state.outCurrency.code
                      )
                      .map((way) => way.inCurrency.code)
              )
          )
        : Array.from(
              new Set(
                  state.preRequestExchagneWays.map((way) => way.inCurrency.code)
              )
          )
    const notActiveInCurrencyCode = state.outCurrency
        ? Array.from(
              new Set(
                  state.preRequestExchagneWays
                      .filter(
                          (way) => !inCurrencyCode.includes(way.inCurrency.code)
                      )
                      .map((way) => way.inCurrency.code)
              )
          )
        : []

    const result1 = inCurrencyCode.map(
        (way) =>
            state.preRequestExchagneWays.find(
                (item) => item.inCurrency.code === way
            ).inCurrency
    )
    const result2 = notActiveInCurrencyCode.map((way) => ({
        ...state.preRequestExchagneWays.find(
            (item) => item.inCurrency.code === way
        ).inCurrency,
        notActive: true
    }))

    let inCurrency = [...result1, ...result2]

    // outCurrencies
    const outCurrencyCode = state.inCurrency
        ? Array.from(
              new Set(
                  state.preRequestExchagneWays
                      .filter(
                          (way) => way.inCurrency.code === state.inCurrency.code
                      )
                      .map((way) => way.outCurrency.code)
              )
          )
        : Array.from(
              new Set(
                  state.preRequestExchagneWays.map(
                      (way) => way.outCurrency.code
                  )
              )
          )
    const notActiveOutCurrencyCode = state.inCurrency
        ? Array.from(
              new Set(
                  state.preRequestExchagneWays
                      .filter(
                          (way) =>
                              !outCurrencyCode.includes(way.outCurrency.code)
                      )
                      .map((way) => way.outCurrency.code)
              )
          )
        : []

    const result3 = outCurrencyCode.map(
        (way) =>
            state.preRequestExchagneWays.find(
                (item) => item.outCurrency.code === way
            ).outCurrency
    )
    const result4 = notActiveOutCurrencyCode.map((way) => ({
        ...state.preRequestExchagneWays.find(
            (item) => item.outCurrency.code === way
        ).outCurrency,
        notActive: true
    }))

    const outCurrency = [...result3, ...result4]

    dispatch(currencyCoinAction(inCurrency, outCurrency))
}

export default isActiveCoin
