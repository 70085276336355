import queryString from 'query-string'

import config from '../../../config/config'
import i18n from '../../../i18n'
import getMinMaxLimitsObj from '../../../modules/ExchangeForm/services/getMinMaxLimitsObj'
import getActualWays from '../../../modules/ExchangeForm/services/getWaysData'
import RandomString from '../../../modules/ExchangeForm/services/randomString'
import { axiosRequest, serverRequest } from '../../../services/request'
import store from '../../index'
import {
    externalIdAction,
    loaderAction,
    saveUkraineAction,
    widgetModeAction
} from './action'
import { INIT } from './type'
import UtilsService from '../../../modules/ExchangeForm/services/utilsService'

const { dispatch } = store

export default async function exchangeAction() {
    const url = window.location.href
    const urlParamsString = url.slice(url.indexOf('?') + 1)
    const urlParamsObj = queryString.parse(urlParamsString)

    let inCurrency,
        outCurrency,
        payway,
        amount,
        amountSide,
        externalId,
        callbackUrl,
        email,
        outDestination,
        redirectUrl,
        exchangeWayId,
        widgetMode,
        saveUkraine = null
    let editEmail,
        editOutDestination = true,
        editAmount,
        editOutCurrency = true

    inCurrency = urlParamsObj.inCurrency || null
    outCurrency = urlParamsObj.outCurrency || null
    payway = urlParamsObj.payway || null
    outDestination = urlParamsObj.outDestination || null
    email = urlParamsObj.email || null

    if (
        urlParamsObj.cashbackToken &&
        config.cashbackTokensArray.includes(urlParamsObj.cashbackToken)
    ) {
        editEmail = email ? true : false
        editAmount = amount ? true : false
        exchangeWayId = urlParamsObj.exchangeWayId
            ? urlParamsObj.exchangeWayId
            : false
    }

    editOutDestination = outDestination ? false : true

    externalId = urlParamsObj.externalId || null
    callbackUrl = urlParamsObj.callbackUrl || null
    redirectUrl = urlParamsObj.redirectUrl || null
    widgetMode = urlParamsObj?.widgetMode
        ? JSON.parse(urlParamsObj.widgetMode)
        : null
    amount = Number(urlParamsObj.amount) || null
    amountSide = urlParamsObj.amountSide || null
    saveUkraine = urlParamsObj.saveUkraine
        ? JSON.parse(urlParamsObj.saveUkraine)
        : null

    dispatch(widgetModeAction(widgetMode))
    dispatch(externalIdAction(externalId))
    dispatch(saveUkraineAction(saveUkraine))

    let cashbackToken = config.originalToken

    if (urlParamsObj?.rid && urlParamsObj.rid) {
        cashbackToken = urlParamsObj.rid || null
    } else if (urlParamsObj?.cashbackToken && urlParamsObj.cashbackToken) {
        cashbackToken = urlParamsObj.cashbackToken
        localStorage.setItem('cashbackToken', urlParamsObj.cashbackToken)
    }

    let token = localStorage.getItem('cashbackToken')
    if (
        token &&
        !config.cashbackTokensArray.includes(token) &&
        !urlParamsObj.rid &&
        !urlParamsObj.cashbackToken
    ) {
        cashbackToken = localStorage.getItem('cashbackToken')
    }

    // links
    const dataAllPrerequest = `${config.backendUrl}/data/all-prerequest?destination=${config.destination}`
    // cashbackToken=${cashbackToken}&

    let [preRequestExchagneWays] = await Promise.all([
        serverRequest(dataAllPrerequest, 'DATA')
    ])

    preRequestExchagneWays =
        preRequestExchagneWays && preRequestExchagneWays.length
            ? preRequestExchagneWays.map((way) =>
                  way.exchangeWayType === 'BUY' &&
                  way.inPaywayCode.indexOf('VISA_MC') !== -1
                      ? { ...way, inPaywayCode: 'CARD' }
                      : way.exchangeWayType === 'SELL' &&
                        way.outPaywayCode.indexOf('VISA_MC') !== -1
                      ? { ...way, outPaywayCode: 'CARD' }
                      : way
              )
            : []

    preRequestExchagneWays =
        preRequestExchagneWays && preRequestExchagneWays.length
            ? preRequestExchagneWays.filter(
                  (way) =>
                      config.availableRate.includes(way.rateType) &&
                      !config.disabledProviders.includes(way.provider) &&
                      !config.disavledPayways.includes(
                          way.exchangeWayType === 'SELL'
                              ? way.outPaywayCode
                              : way.exchangeWayType === 'BUY'
                              ? way.inPaywayCode
                              : false
                      )
              )
            : []

    if (urlParamsObj.cur_from || urlParamsObj.cur_to) {
        const curLink = `${config.backendUrl}/best-change/bc-to-trustee?cashbackToken=${cashbackToken}&currencyFrom=${urlParamsObj.cur_from}&currencyTo=${urlParamsObj.cur_to}`
        let res
        try {
            res = await axiosRequest(curLink)
        } catch (e) {
            console.log('error', e)
            dispatch(loaderAction(false))
        }
        inCurrency =
            res?.inCurrencyCode &&
            typeof res?.inCurrencyCode !== 'undefined' &&
            res?.inCurrencyCode !== 'undefined'
                ? res.inCurrencyCode
                : null
        outCurrency =
            res?.outCurrencyCode &&
            typeof res?.outCurrencyCode !== 'undefined' &&
            res?.outCurrencyCode !== 'undefined'
                ? res.outCurrencyCode
                : null

        const exchangeWay = preRequestExchagneWays.find(
            (way) =>
                way.inCurrency.code === inCurrency &&
                way.outCurrency.code === outCurrency
        )
        payway = exchangeWay
            ? exchangeWay.exchangeWayType === 'SELL'
                ? res.outPaywayCode
                : res.inPaywayCode
            : null
    }

    const exchangeWayItem = preRequestExchagneWays.filter(
        (way) =>
            way.inCurrency.code === inCurrency &&
            way.outCurrency.code === outCurrency
    )

    let payways =
        exchangeWayItem && exchangeWayItem.length
            ? Array.from(
                  new Set(
                      exchangeWayItem.map((way) => {
                          switch (way.exchangeWayType) {
                              case 'BUY':
                                  return way.inPaywayCode
                              case 'SELL':
                                  return way.outPaywayCode
                              default:
                                  return null
                          }
                      })
                  )
              )
            : null

    payway =
        payways && payways.length && !urlParamsObj.payway
            ? payways.includes('CARD')
                ? 'CARD'
                : payways.includes('PERFECT_MONEY')
                ? 'PERFECT_MONEY'
                : payways[0]
            : payway

    inCurrency =
        inCurrency && exchangeWayItem && exchangeWayItem.length
            ? exchangeWayItem[0].inCurrency
            : inCurrency
            ? preRequestExchagneWays &&
              (preRequestExchagneWays.find(
                  (way) => way.inCurrency.code === inCurrency
              )?.inCurrency || { code: 'UAH', type: 'FIAT', symbol: 'UAH' })
            : { code: 'UAH', type: 'FIAT', symbol: 'UAH' }
    outCurrency =
        outCurrency && exchangeWayItem && exchangeWayItem.length
            ? exchangeWayItem[0].outCurrency
            : outCurrency
            ? preRequestExchagneWays &&
              (preRequestExchagneWays.find(
                  (way) => way.outCurrency.code === outCurrency
              )?.outCurrency || {
                  code: 'BTC',
                  type: 'CRYPTO',
                  symbol: 'BTC',
                  name: 'Bitcoin'
              })
            : { code: 'BTC', type: 'CRYPTO', symbol: 'BTC', name: 'Bitcoin' }

    payway = payway
        ? payway.indexOf('VISA_MC') !== -1
            ? 'CARD'
            : payway
        : inCurrency?.type === 'FIAT' || outCurrency?.type === 'FIAT'
        ? 'CARD'
        : null

    const allActiveExchangeWays = await getActualWays(
        inCurrency,
        outCurrency,
        payway,
        cashbackToken
    )

    const limits = await getMinMaxLimitsObj(
        allActiveExchangeWays,
        inCurrency,
        outCurrency,
        payway
    )

    const exchangeWay =
        inCurrency.type === 'FIAT' || outCurrency.type === 'FIAT'
            ? 'BUY/SELL'
            : 'EXCHANGE'

    // set locale
    const locale =
        typeof urlParamsObj.locale !== 'undefined'
            ? ['en', 'ru', 'uk'].includes(urlParamsObj.locale)
                ? urlParamsObj.locale
                : 'en'
            : localStorage.getItem('locale') || 'en'
    i18n.changeLanguage(locale)

    amount =
        amount && (inCurrency.type === 'FIAT' || outCurrency.type === 'FIAT')
            ? UtilsService.cutNumber(amount, 2)
            : amount

    dispatch({
        type: INIT,
        inCurrency,
        outCurrency,
        userToken: RandomString.getString(10),
        payway,
        exchangeWay,
        locale,
        cashbackToken,
        allActiveExchangeWays,
        limits,
        preRequestExchagneWays,
        amount,
        amountSide: amountSide || 'IN',
        outDestination,
        editOutCurrency,
        email,
        editAmount: typeof editAmount !== 'undefined' ? editAmount : true,
        editEmail,
        editOutDestination,
        exchangeWayId,
        externalId,
        callbackUrl,
        redirectUrl,
        widgetMode,
        saveUkraine,
        loading: false
    })
}
