import styled from 'styled-components'

const CardPhotoComponent = styled.div`
    padding: 24px 0;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: calc(100vw - 32px);
        margin-left: 16px;
    }

    @media screen and (max-width: 480px) {
        .btns-items {
            width: calc(100% - 48px);
        }
    }

    .container-info {
        display: flex;
        flex-direction: column;
        align-items: center;

        text-align: center;

        h3 {
            margin: 0;

            font-family: 'Montserrat SemiBold';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;

            display: flex;
            align-items: center;
            text-align: center;

            color: #404040;
        }
    }

    .container-description {
        font-family: Fira Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 25px;

        text-align: center;

        color: #5c5c5c;

        margin: 8px 0 24px 0;
    }

    .card-verification-status {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        font-family: Fira Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 25px;

        text-align: center;

        color: #5c5c5c;

        img {
            width: 50px;
        }
    }
`

export default CardPhotoComponent
