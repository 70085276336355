import React, { useRef } from 'react'

import HeaderDiv from './style.css'

import { useDetectOutsideClick } from './useDetected'
import { getThemeModeStyles } from '../../theme/useDarkMode'

import logoFull from '../../images/icon/logoExchFull.svg'
import logo from '../../images/icon/logoExch.svg'

import i18n from '../../i18n'
import Icon from '../../components/Icon/Icon'

const Header = () => {
    const dropdownRef = useRef(null)
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false)
    const onClick = () => setIsActive(!isActive)

    const selectLang = (value) => {
        i18n.changeLanguage(value)
        localStorage.setItem('locale', value)
        onClick()
    }

    const theme = getThemeModeStyles()

    return (
        <HeaderDiv
            bg={theme.header.bg}
            selectorBorder={theme.header.selectorBorder}
            selectorColor={theme.header.selectorColor}
        >
            <header className='header'>
                <nav className='nav'>
                    <div className='logo'>
                        <a
                            href='/'
                            // target='_blank'
                            rel='noopener noreferrer'
                        >
                            <div className='logo-desktop'>
                                <img src={logoFull} alt='logo' />
                            </div>
                            <div className='logo-mobile'>
                                <img src={logo} alt='logo' />
                            </div>
                        </a>
                    </div>
                    <div className='lang'>
                        <div className='lang-selector' onClick={onClick}>
                            <Icon icon='LANG' size={22} color='#404040' />
                            {localStorage.getItem('locale')
                                ? localStorage.getItem('locale').toUpperCase()
                                : 'EN'}
                            <Icon icon='ARROW_DOWN' size={24} color='#404040' />
                        </div>
                        <nav
                            ref={dropdownRef}
                            className={`menu ${
                                isActive ? 'active' : 'inactive'
                            }`}
                        >
                            <ul>
                                <li key='uk' onClick={() => selectLang('uk')}>
                                    UK
                                </li>
                                <li key='ru' onClick={() => selectLang('ru')}>
                                    RU
                                </li>
                                <li key='en' onClick={() => selectLang('en')}>
                                    EN
                                </li>
                            </ul>
                        </nav>
                    </div>
                </nav>
            </header>
        </HeaderDiv>
    )
}

export default Header
