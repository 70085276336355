import styled from 'styled-components'

const SelectorDiv = styled.div`
    /* width: 100%; */

    .selector-container {
        position: relative;

        width: 100%;
    }

    .selector-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        background: #dadada;
        border-radius: 13px;
        margin: 1px;

        padding: 0 10px;

        height: 48px;
        width: 118px;

        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 0.94em;
        line-height: 120%;
        color: #404040;

        cursor: pointer;

        &.widget {
            background: #d4d4dc;
            width: 94px;
        }
    }

    .coin-icon {
        position: relative;
    }

    .token-icon {
        position: absolute;
        bottom: -5px;
        right: -7px;

        svg {
            width: 16px;
            height: 16px;
        }
    }

    .selector {
        position: absolute;
        top: 60px;
        right: 0;
        min-width: 260px;
        max-height: 436px;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-20);
        transition: opacity 0.2s ease, transform 0.2s ease, visibility 0.2s;
        -webkit-tap-highlight-color: transparent;
        outline: none;

        z-index: 200;

        overflow: auto;

        background: #e8e8e8;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        border-radius: 14px;

        cursor: pointer;

        &.widget {
            background: #e0e0ea;
        }

        &.inactive {
            width: 0;
        }

        &.active {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);

            max-width: 508px;
            width: 100%;
            max-height: 436px;
            /* padding-bottom: 20px; */
            overflow: hidden;

            z-index: 300;
        }
    }

    .selector-header {
        width: calc(100% - 40px);
        height: 100%;
        padding: 20px 0;

        position: relative;
        margin: 0 auto;
    }

    .selector-header-input {
        position: fixed;
        width: calc(100% - 32px);
    }

    .selector-body {
        margin-top: 50px;
        padding-bottom: 10px;

        max-height: 346px;

        display: flex;
        flex-direction: column;

        overflow: scroll;
    }

    .icon-wrapper {
        min-width: 44px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .currency-type {
        margin: 13px 0 13px 40px;

        font-family: 'Montserrat Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 12px;

        letter-spacing: 1.5px;
        text-transform: uppercase;

        color: #5c5c5c;
    }

    .selector-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        text-decoration: none;
        color: #333333;
        padding: 10px 20px;

        &.select {
            background: rgba(64, 64, 64, 0.2);
        }
    }

    .selector-item-container {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .selector-item:hover {
        background: rgba(64, 64, 64, 0.2);
    }

    .selector-item-text {
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 1em;
        line-height: 120%;

        color: #404040;

        margin-left: 16px;

        &.notActive {
            color: #a1a1a1;
        }
    }

    .selector-item-code {
        font-family: 'Montserrat Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 0.82em;
        line-height: 120%;

        letter-spacing: 0.058em;
        text-transform: uppercase;

        color: #404040;

        &.notActive {
            color: #a1a1a1;
        }
    }

    .selector-loading {
        display: flex;
        justify-content: center;

        padding: 10px;
    }

    .selector-line {
        height: 1px;
        background-color: #999;
        width: 80%;
        margin: 0 auto;
    }

    @media screen and (max-width: 640px) {
        .selector-header {
            width: calc(100% - 32px);
            padding: 16px;
        }

        .currency-type {
            margin-left: 36px;
        }
    }
`

export default SelectorDiv
