import React from 'react'
import { strings } from '../../../../../i18n'
import PhotoExampleDiv from './style.css'

import Card from '../../../../../images/card.png'
import Button from '../../../../../components/Button/Button'
import Icon from '../../../../../components/Icon/Icon'

const PhotoExample = (props) => {
    const {
        fileInputHandler,
        condition,
        uploadAction,
        fileData,
        fileName,
        deleteFileAction,
        modal
    } = props

    let currentData = new Date()
    currentData = `${
        currentData.getDate().toString().length === 1
            ? '0' + currentData.getDate()
            : currentData.getDate()
    }.${
        (currentData.getMonth() + 1).toString().length === 1
            ? '0' + (currentData.getMonth() + 1)
            : currentData.getMonth() + 1
    }.${currentData.getFullYear()}`

    return (
        <PhotoExampleDiv>
            <div className='photo-example-header'>
                {strings('exchangeForm.photoRequirements')}
            </div>
            <div className='photo-example-description'>
                {strings('exchangeForm.photoRequirementsDescription', {
                    data: currentData
                })}
            </div>
            {!modal && (
                <div className='btns-container'>
                    <div className={`btns-items ${!modal && 'up'}`}>
                        <div className='photo-upload-btn'>
                            <label
                                htmlFor='upload_photo'
                                className={`photo-example-label ${
                                    fileData && 'notActive'
                                } `}
                            >
                                {strings('exchangeForm.chooseFile')}
                                {fileData ? (
                                    <div className='upload-check'>
                                        <Icon icon='CHECK' color='#E8E8E8' />
                                    </div>
                                ) : null}
                            </label>
                            <input
                                id='upload_photo'
                                type='file'
                                disabled={fileData}
                                onChange={fileInputHandler}
                                onClick={(e) => (e.target.value = '')}
                            />
                        </div>
                        {fileData ? (
                            <div className='photo-loaded-file'>
                                <div className='photo-file-name'>
                                    {fileName}
                                    <div
                                        className='photo-file-delete'
                                        onClick={deleteFileAction}
                                    >
                                        <Icon icon='DELETE' />
                                    </div>
                                </div>
                                <div className='border-file' />
                            </div>
                        ) : null}
                        <div className='photo-send-btn'>
                            <Button
                                condition={condition}
                                title={strings('exchangeForm.upload')}
                                onPress={uploadAction}
                                containerStyle={{ boxShadow: 'none' }}
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className='photo-example-item'>
                {strings('exchangeForm.example')}
            </div>
            <div className='btns-container'>
                <div className='example-content'>
                    <img src={Card} alt='card example' />
                </div>
                <div className={`btns-items ${!modal && 'down'}`}>
                    <div className='photo-upload-btn'>
                        <label
                            htmlFor='upload_photo'
                            className={`photo-example-label ${
                                fileData && 'notActive'
                            } `}
                        >
                            {strings('exchangeForm.chooseFile')}
                            {fileData ? (
                                <div className='upload-check'>
                                    <Icon icon='CHECK' color='#E8E8E8' />
                                </div>
                            ) : null}
                        </label>
                        <input
                            id='upload_photo'
                            type='file'
                            disabled={fileData}
                            onChange={fileInputHandler}
                            onClick={(e) => (e.target.value = '')}
                        />
                    </div>
                    {fileData ? (
                        <div className='photo-loaded-file'>
                            <div className='photo-file-name'>
                                {fileName}
                                <div
                                    className='photo-file-delete'
                                    onClick={deleteFileAction}
                                >
                                    <Icon icon='DELETE' />
                                </div>
                            </div>
                            <div className='border-file' />
                        </div>
                    ) : null}
                    <div className='photo-send-btn'>
                        <Button
                            condition={condition}
                            title={strings('exchangeForm.upload')}
                            onPress={uploadAction}
                            containerStyle={{ boxShadow: 'none' }}
                        />
                    </div>
                </div>
            </div>
        </PhotoExampleDiv>
    )
}

export default PhotoExample
