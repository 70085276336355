import React from 'react'
import ExchnageFormComponent from '../ExchangeForm/ExchangeForm'

const WidgetComponent = () => {
    return (
        <div
            style={{
                background: '#E0E0EA',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <ExchnageFormComponent mainPage={true} />
        </div>
    )
}

export default WidgetComponent
