class RandomString {
    CHR = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9
    ]

    getString = (length) => {
        let string = ''

        let index
        for (let i = 0; i < length; i++) {
            index = this.getRandomInt(0, this.CHR.length - 1)
            string += this.CHR[index]
        }

        return string
    }

    getRandomInt = (min, max) =>
        Math.floor(Math.random() * (max - min + 1)) + min
}

export default RandomString = new RandomString()
