import React from 'react'
import Button from '../../../../components/Button/Button'
import { strings } from '../../../../i18n'
import WalletDiv from './style.css'

import walletBlock from '../../../../images/walletBlock2_desktop.svg'
import phoneBlock_desktop from '../../../../images/phoneBlock2_desktop.png'
import phoneBlock_tablet from '../../../../images/phoneBlock2_tablet.png'
import phoneBlock_mobile from '../../../../images/phoneBlock2_mobile.png'
import { handleDownloadLink } from '../../services/handleDownloadLink'

const WalletComponent = (props) => {
    const { mainPage, text } = props

    return (
        <WalletDiv mainPage={mainPage}>
            <div className='wallet-block-container'>
                <img src={walletBlock} alt='wallet-block' />
            </div>
            <div className='wallet-container'>
                {mainPage && (
                    <h2 className='wallet-container-title trustee-title'>
                        Trustee Wallet
                    </h2>
                )}
                <h2 className='wallet-container-title trustee'>
                    {mainPage
                        ? strings('exchanger.recommendedWallet').toLowerCase()
                        : strings('exchanger.recommendedWallet')}
                </h2>
                <div className='wallet-container-description'>
                    {text || strings('exchanger.recommendedWalletDescription')}
                </div>
                <div className='wallet-container-btn'>
                    <Button
                        condition={true}
                        title={strings('exchanger.download')}
                        containerStyle={{ marginTop: 40 }}
                        onPress={handleDownloadLink}
                    />
                </div>
            </div>
            <div className='phone-block-container'>
                <picture>
                    <source
                        srcSet={phoneBlock_mobile}
                        media='(max-width: 640px)'
                    />
                    <source
                        srcSet={phoneBlock_tablet}
                        media='(min-width: 640px, max-width: 1280px)'
                    />
                    <img src={phoneBlock_desktop} alt='phone-block' />
                </picture>
            </div>
        </WalletDiv>
    )
}

export default WalletComponent
