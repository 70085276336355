import styled from 'styled-components'

const OrderInfoContainer = styled.div`

    width: 100%;

    padding: 30px 0 60px 0;

    background: no-repeat ${(p) => `url(${p.bgImageDesktop})`};
    background-position: left top;

    max-width: 2010px;

    margin: 0 auto;

    .order-wrap {
        width: 100%;
        max-width: 2010px;

        margin: 0 auto;

        display: flex;
        justify-content: space-between;
    }

    .order-tracking {
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 3.18em;
        line-height: 120%;

        display: flex;
        flex-direction: column;
        margin-top: 30px;

        width: 50%;
        max-width: 45vw;

        color: #404040;
    }

    .createvies-one {
        display: flex;
        max-width: 1024px;
    }

    .createvies-two {
        display: none;
    }

    .message-two {
        display: none;
    }

    @media screen and (max-width: 640px) {
        background: no-repeat ${(p) => `url(${p.bgImageMobile})`};

        padding: 0px 0 30px 0;

        .order-wrap {
            width: 100%;
            padding: 24px 0 30px 0;
            flex-wrap: wrap;
        }

        .order-tracking {
            margin-top: 0;
            width: 100%;
            max-width: 100%;

            font-size: 20px;
            line-height: 130%;
            margin-left: 16px;
            width: calc(100% - 32px);
            margin-bottom: 16px;
        }

        .createvies-one {
            display: none;
        }

        .createvies-two {
            display: flex;
            justify-content: center;
        }
        
        .message-one {
            display: none;
        }

        .message-two {
            display: flex;
            justify-content: center;
        }
    }
    }

    @media screen and (min-width: 640px) and (max-width: 1280px) {
        background: no-repeat ${(p) => `url(${p.bgImageTablet})`};

        .order-wrap {
            width: calc(100% - 64px);
            margin-left: 32px;
        }

        .order-tracking {
            font-size: 40px;
            line-height: 120%;
        }
    }

    @media screen and (min-width: 1280px) and (max-width: 1920px) {
        .order-wrap {
            max-width: 1266px;
            margin: 0 auto;
        }
    }

`

export default OrderInfoContainer
