import styled from 'styled-components'

const HowExchangeDiv = styled.div`
    display: flex;
    justify-content: center;

    width: 100%;
    padding-top: 70px;

    max-width: 2010px;

    margin: 0 auto;

    h2 {
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 54px;
        line-height: 120%;
        text-align: center;

        color: #404040;

        margin: 30px 0;
    }

    .description {
        font-family: Fira Sans, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 150%;

        text-align: center;

        color: #5c5c5c;

        width: 60%;
        margin: 0 auto;
    }

    .content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        width: 100%;

        padding: 30px 0;

        margin: 0 auto;
    }

    .exchange-wrapper {
        /* display:flex;
        justify-content:space-between; */
        margin-top: 58px;
    }
    .exchange-steps {
        display: flex;
        justify-content: space-between;
        padding:0 100px;

        &.right {
            flex-direction: row-reverse;
        }
    }
    .exchange-item {
        display: flex;
        /* border:3px solid orange; */
        width: 40%;
        flex-wrap: wrap;
    }
    .exchange-empty {
        display: flex;
        /* border:3px solid orange; */
        width: 40%;
        flex-wrap: wrap;
    }
    .exchange-item img {
        width: 144px;
    }
    .exchange-title {
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 130%;
        min-width: 194px;
        padding: 5px;
        color: #5c5c5c;
        flex: 0 0;
    }
    .exchange-text {
        margin-top: 20px;
        font-family: 'Fira Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        color: #5c5c5c;
        padding-left: 10px;
        line-height: 150%;
        &.text-right {
            padding: 0 15px;
            text-align:right;
            width:100%;
            justify-content:flex-end;
        }
    }

    .content-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        position: relative;
        margin: 0 auto;
        padding: 0 15px;

        max-width: 15%;
        width: 100%;
        min-height: 196px;
    }
    .line-wrapper {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        position: relative;
        justify-content: center;
    }
    .line-item {
        border-left: 1px solid #999999;

        &.last {
            border: none;
        }
    }

    .revers-title {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .content-item-circle {
        width: 120px;
        height: 120px;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;

        background: #e8e8e8;

        font-family: 'Montserrat Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        line-height: 35px;

        text-align: center;

        color: #404040;

        z-index: 100;
    }
    .exchange-content-right {
        display: flex;
        flex-direction: row-reverse;
        padding-right: 15px;
        text-align: right;
        width: 100%;
    }

    /* .content-line {
        width: 236px;
        border: 1px dashed #999999;
        margin-top:56px;
        /* position: absolute;
        top: 64%; */
    /* transform: rotate(0.25turn); */
    /* &.left {
            width: 50%;
            left: 0;
        }

        &.right {
            width: 50%;
            right: 0;
        } */
    /* }  */
    /* .right {
        justify-content: flex-end;
    } */

    /* @media screen and (max-width: 946px) {
        .content {
            justify-content: center;
        }
    } */

    @media screen and (max-width: 640px) {
        padding-top: 70px;

        h2 {
            margin: 0;
            margin-bottom: 8px;

            font-size: 36px;
            line-height: 120%;
        }

        .description {
            font-size: 16px;
            line-height: 150%;
        }

        .content-item-circle {
            height: 60px;
            width: 60px;
        }
        .exchange-steps {
            &.right {
                flex-direction: row;
            }
        }

        .content-item-title {
            font-size: 16px;
            line-height: 130%;
        }

        .content-item {
            min-height: 118px;
            width: 120px;
        }
        .exchange-empty {
            display: none;
        }
        .exchange-title {
            text-align: left;
        }
        .exchange-text {
            margin-top: 16px;

            &.text-right {
                padding: 0;
                text-align:left;
            }
        }
        .exchange-steps{
            padding:0;
        }

        .circle-right {
            flex-direction: row-reverse;
        }
        .exchange-item {
            padding-left: 15px;
            flex-direction: column;
            flex: 1;
        }
        .exchange-item img {
            padding-top: 40px;
        }

        .revers-title {
            margin-top: 20px;
            display: flex;
            flex-direction: column-reverse;
        }
        .right {
            flex-direction: row;
        }
        .exchange-content-right {
            flex-direction: column;
        }
    }

    @media screen and (min-width: 640px) and (max-width: 1280px) {
        h2 {
            margin: 0;
            margin-bottom: 40px;

            font-size: 40px;
            line-height: 120%;
        }

        .content-item {
            min-height: 118px;
            width: 150px;

            padding: 16px 0;
        }

        .content-item-circle {
            width: 80px;
            height: 80px;
        }

        .content-item-title {
            font-size: 16px;
            line-height: 130%;

            padding-top: 16px;
        }
        .content-item {
            padding: 0;
        }
        .exchange-item img {
            width: 108px;
        }
        .exchange-title {
            font-size: 22px;
            line-height: 130%;
        }
        .exchange-steps{
            padding:0;
        }
    }

    @media screen and (min-width: 1280px) and (max-width: 1920px) {
        max-width: 1266px;
        margin: 0 auto;
    }
`

export default HowExchangeDiv
