import React from 'react'
import QRCode from 'qrcode.react'
import { CryptoCurrencyIcon, FiatCurrencyIcon } from 'trustee-swap-assets'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { strings } from '../../../i18n'
import OrderBlockDiv from './style.css'

import Logo from '../../../images/icon/favicon.png'
import Loader from '../../../images/loader.svg'
import LoaderBlack from '../../../images/loaderBlack.svg'
import prettyStatus from '../service/getOrderStatus'
import Icon from '../../../components/Icon/Icon'
import Button from '../../../components/Button/Button'

const getLoader = (status) => {
    switch (status?.toUpperCase()) {
        case 'CANCELED_PAYIN':
        case 'ERROR_ORDER':
            return (
                <div className='canceled-order'>
                    <Icon icon='CLOSE' size={18} color='#F7F7F7' />
                </div>
            )
        case 'DONE_PAYOUT':
            return (
                <div className='done-order'>
                    <Icon icon='CHECK' size={28} color='#F7F7F7' />
                </div>
            )
        default:
            return <img src={Loader} alt='Loader' />
    }
}

const OrderBlock = (props) => {

    const { data } = props

    return (
        <OrderBlockDiv data={data}>
            <div className='order-container'>
                {data ? (
                    <>
                        <div className='order-data-item'>
                            <div className='order-data-item-label'>
                                {strings('exchangeForm.youGive')}
                            </div>
                            <div className='order-data-item-value amount'>
                                {data?.inAmount + ' ' + data?.inCurrencySymbol}
                            </div>
                        </div>
                        {data ? (
                            data?.orderStatus === 'PENDING_PAYIN' ? (
                                <div className='order-data-deposit-container'>
                                    <div className='order-data-item-label address'>
                                        {strings('orderInfo.takePay')}
                                    </div>
                                    <div className='order-data-deposit'>
                                        {data?.depositAddress &&
                                        !data?.payinUrl ? (
                                            <div className='order-data-adress-qr'>
                                                <QRCode
                                                    value={data?.valueQR || ''}
                                                    size={126}
                                                    level='Q'
                                                    bgColor='transparent'
                                                    imageSettings={{
                                                        src: Logo,
                                                        width: 20,
                                                        height: 20
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <Button
                                                condition={true}
                                                title={strings('orderInfo.pay')}
                                                onPress={() =>
                                                    window.open(
                                                        data?.payinUrl,
                                                        '_blank'
                                                    )
                                                }
                                                containerStyle={{
                                                    marginTop: 20
                                                }}
                                            />
                                        )}
                                        {data?.depositAddress &&
                                        !data?.payinUrl ? (
                                            <CopyToClipboard
                                                text={data?.depositAddress}
                                                // onCopy={() => enqueueSnackbar(strings('orderInfo.successCopy'))}
                                            >
                                                <div className='order-data-deposit-address'>
                                                    <p>
                                                        {data?.depositAddress}
                                                    </p>
                                                    <div className='order-data-icon-copy'>
                                                        <Icon
                                                            icon='COPY'
                                                            size={28}
                                                        />
                                                    </div>
                                                </div>
                                            </CopyToClipboard>
                                        ) : null}
                                    </div>
                                </div>
                            ) : null
                        ) : null}
                        <div className='order-data-item'>
                            <div className='order-data-item-label'>
                                {strings('exchangeForm.youGet')}
                            </div>
                            <div className='order-data-item-value'>
                                {data?.expectedAmount +
                                    ' ' +
                                    data?.outCurrencySymbol}
                            </div>
                        </div>
                        <div className='order-data-item'>
                            <div className='order-data-item-label'>
                                {strings('exchangeForm.outDestination')}
                            </div>
                            <div className='order-data-item-value'>
                                {data?.outDestination}
                            </div>
                        </div>
                        {data?.outDestinationTag ||
                        data?.depositDestinationTag ? (
                            <div className='order-data-item'>
                                <div className='order-data-item-label'>
                                    {strings('exchangeForm.outDestinationTag')}
                                </div>
                                <div className='order-data-item-value'>
                                    {data?.outDestinationTag ||
                                        data?.depositDestinationTag}
                                </div>
                            </div>
                        ) : null}
                    </>
                ) : (
                    <div className='data-loading'>
                        <img src={LoaderBlack} alt='Loader' />
                    </div>
                )}
            </div>
            <div
                className='order-container-coin'
                style={{ display: data ? 'flex' : 'none' }}
            >
                <div className='order-currency-icon'>
                    {data?.inCurrencyType === 'CRYPTO' ? (
                        <CryptoCurrencyIcon
                            currencyCode={data?.inCurrencyCode || ''}
                            setBackground
                            iconStyle={{ fontSize: 28 }}
                        />
                    ) : data?.inCurrencyType === 'FIAT' ? (
                        <FiatCurrencyIcon
                            currencyCode={data.inCurrencyCode || ''}
                            setBackground
                            iconStyle={{ fontSize: 28 }}
                        />
                    ) : null}
                </div>
                <div className='order-currency'>
                    <div className='order-currency-name'>{`${data?.inCurrencySymbol} - ${data?.outCurrencySymbol}`}</div>
                    <div className='order-hash'>
                        {strings('orderInfo.orderHash') + ' ' + data?.orderHash}
                    </div>
                    <div className='order-status-wrapper'>
                        <div className='order-status-label'>
                            {strings('orderInfo.status')}
                        </div>
                        <div
                            className={`order-status-value ${
                                (data?.orderStatus === 'CANCELED_PAYIN' ||
                                    data?.orderStatus === 'ERROR_ORDER') &&
                                'canceled'
                            } `}
                        >
                            {prettyStatus(data?.orderStatus || '')}
                            {getLoader(data?.orderStatus)}
                        </div>
                    </div>
                </div>
                <div className='order-currency-icon'>
                    {data?.outCurrencyType === 'CRYPTO' ? (
                        <CryptoCurrencyIcon
                            currencyCode={data?.outCurrencyCode || ''}
                            setBackground
                            iconStyle={{ fontSize: 28 }}
                        />
                    ) : data?.outCurrencyType === 'FIAT' ? (
                        <FiatCurrencyIcon
                            currencyCode={data?.outCurrencyCode || ''}
                            setBackground
                            iconStyle={{ fontSize: 28 }}
                        />
                    ) : null}
                </div>
            </div>
        </OrderBlockDiv>
    )
}

export default OrderBlock
