import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import './modal.css'
import Icon from '../../../../components/Icon/Icon'

function ModalWindow(props) {
    const modalRef = useRef()
    const closeButtonRef = useRef()

    useEffect(() => {
        props.show
            ? document.addEventListener('mousedown', handleClick)
            : document.removeEventListener('mousedown', handleClick)

        return () => document.removeEventListener('mousedown', handleClick)
    }, [props])

    const handleClick = (event) => {
        if (
            closeButtonRef.current === event.target ||
            modalRef.current === event.target
        ) {
            props.close()
        }
    }

    return (
        <>
            {props.show ? (
                <div className='modalWindow' ref={modalRef}>
                    <div className='modalWindow__content'>
                        <span
                            className='modalWindow__close_button'
                            ref={closeButtonRef}
                            onClick={props.close}
                        >
                            <Icon icon='CLOSE' color='#BBBBBB' size={26} />
                        </span>
                        <div className='modalWindow__body'>
                            {props.children}
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    )
}

ModalWindow.propTypes = {
    close: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
}

export default ModalWindow
