import store from '../../../redux'
import { setPaywayListAction } from '../../../redux/stores/exchangeStore/action'

const { dispatch } = store

export function getPaywayList() {
    const state = store.getState().exchangeStore

    if (!state.preRequestExchagneWays) {
        return
    }

    let exchangeWays = state.preRequestExchagneWays

    exchangeWays =
        exchangeWays && exchangeWays.length
            ? exchangeWays.map((way) =>
                  way.exchangeWayType === 'BUY' &&
                  way.inPaywayCode.indexOf('VISA_MC') !== -1
                      ? { ...way, inPaywayCode: 'CARD' }
                      : way.exchangeWayType === 'SELL' &&
                        way.outPaywayCode.indexOf('VISA_MC') !== -1
                      ? { ...way, outPaywayCode: 'CARD' }
                      : way
              )
            : []

    exchangeWays = state.inCurrency.code
        ? exchangeWays.filter(
              (way) => way.inCurrency.code === state.inCurrency.code
          )
        : exchangeWays
    exchangeWays = state.outCurrency.code
        ? exchangeWays.filter(
              (way) => way.outCurrency.code === state.outCurrency.code
          )
        : exchangeWays

    let payway = Array.from(
        new Set(
            exchangeWays.map((way) => {
                switch (way.exchangeWayType) {
                    case 'BUY':
                        return way.inPaywayCode
                    case 'SELL':
                        return way.outPaywayCode
                    default:
                        return null
                }
            })
        )
    )

    dispatch(setPaywayListAction(payway))
}
