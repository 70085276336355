export const INIT = 'INIT'
export const EXCHANGE_WAY = 'EXCHANGE_WAY'
export const CURRENCY_COIN_ACTION = 'CURRENCY_COIN_ACTION'
export const PAYWAY_LIST_ACTION = 'PAYWAY_LIST_ACTION'

export const SWAP_COIN = 'SWAP_COIN'
export const IN_CURRENCY_SELECT = 'IN_CURRENCY_SELECT'
export const OUT_CURRENCY_SELECT = 'OUT_CURRENCY_SELECT'
export const PAYWAY_ACTION = 'PAYWAY_ACTION'

export const IN_CURRENCY_SEARCH = 'IN_CURRENCY_SEARCH'
export const OUT_CURRENCY_SEARCH = 'OUT_CURRENCY_SEARCH'
export const PAYWAY_SEARCH = 'PAYWAY_SEARCH'

export const OUT_DESTINATION_ACTION = 'OUT_DESTINATION_ACTION'
export const EMAIL_ACTION = 'EMAIL_ACTION'
export const PAYMENT_DATA_ACTION = 'PAYMENT_DATA_ACTION'
export const UNIQUE_PARAMS_VALID = 'UNIQUE_PARAMS_VALID'
export const OUT_DESTINATION_TAG_ACTION = 'OUT_DESTINATION_TAG_ACTION'

export const AMOUNT_ACTION = 'AMOUNT_ACTION'

export const ERROR_ACTION = 'ERROR_ACTION'
export const EXCHANGE_WAYS_ACTION = 'EXCHANGE_WAYS_ACTION'

export const FILE_INPUT_ACTION = 'FILE_INPUT_ACTION'
export const UPLOAD_CARD_PHOTO_ACTION = 'UPLOAD_CARD_PHOTO_ACTION'
export const CARD_PHOTO_ACTION = 'CARD_PHOTO_ACTION'
export const CARD_VERIFY_ACTION = 'CARD_VERIFY_ACTION'

export const LOADER_ACTION = 'LOADER_ACTION'

export const WIDGET_MODE = 'WIDGET_MODE'
export const RESET_DATA = 'RESET_DATA'

export const EXTERNAL_ID_ACTION = 'EXTERNAL_ID_ACTION'

export const SAVE_UKRAINE_ACTION = 'SAVE_UKRAINE_ACTION'
