import styled from 'styled-components'

const WhatComponentDiv = styled.div`
    .what-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-column-gap:30px;
        grid-template-areas:
            'title photo'
            'description photo';

    }
    h2{
        padding:0;
        margin:0;
    }
    .what-title {
        grid-area: title;
        padding-top: 20px;
        font-family: 'Montserrat SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 54px;
        line-height: 120%;
        text-align: left;
        display:flex;
        align-items: center;
    }

    .what-text {
        grid-area: description;
        padding-right: 20px;
        font-family: 'Fira Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 150%;
        color: #5c5c5c;
    }

    .what-img {
        grid-area: photo;
        display: flex;
        justify-content: flex-start;
    }
    .isMobile {
        display: none;
    }

    @media screen and (max-width: 640px) {
        .what-container {
            grid-template-areas:
                'title'
                'photo'
                'description';
            padding:0 16px;
        }

        .isMobile {
            display: flex;
            flex-direction: column;
        }

        .what-title {
            grid-area: title;
            font-size: 36px;
            text-align: left;
            margin-bottom: 24px;
            padding:0;
        }

        .what-text {
            grid-area: description;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .what-img {
            width: 344px;
            height: 276px;
            margin:0 auto;

        }
        .what-small-content {
            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 150%;
            color: #5c5c5c;
            margin-top: 25px;
        }
        .what-description {
            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 150%;
            color: #5c5c5c;
        }

        .isDesktop {
            display: none;
        }

        .button-more {
            font-family: 'Montserrat Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 140%;
            padding-top: 20px;
            color: #864dd9;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
        .button-more img {
            width: 7px;
            margin-left: 9px;
        }
    }

    @media screen and (min-width: 640px) and (max-width: 1280px) {
        .what-container {
            grid-template-rows: 75px;
            grid-template-areas:
                'title'
                'photo'
                'description';
        }

        .what-title {
            grid-area: title;
            margin: 0 auto;
            font-size: 40px;
        }

        .what-text {
            grid-area: description;
            margin:0 32px;
        }

        .what-img {
            margin: 0 auto;
            padding-top: 25px;
        }
    }

    @media screen and (min-width: 1280px) and (max-width: 1920px) {
        max-width: 1266px;
        margin: 0 auto;
    }
`

export default WhatComponentDiv
